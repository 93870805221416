/* eslint-disable @typescript-eslint/naming-convention */
import { isMobile } from 'react-device-detect'
import ReactGA from 'react-ga4'
import { ItemDetail } from 'types/joebarn'

import {
  AddRemoveShoppingCartSource,
  convertItemDetailAndMakerAskToAnalyticsItem
} from './shoppingCart'

export const initializeAnalytics = () => {
  const GOOGLE_ANALYTICS_ID: string | undefined =
    process.env.REACT_APP_GA_MEASUREMENT_ID
  if (typeof GOOGLE_ANALYTICS_ID === 'string' && GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID)
    ReactGA.set({
      customBrowserType: !isMobile
        ? 'desktop'
        : 'web3' in window || 'ethereum' in window
        ? 'mobileWeb3'
        : 'mobileRegular'
    })
    ReactGA.send('pageview')
  } else {
    ReactGA.initialize('test', { testMode: true })
  }
}

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_to_cart
export const trackAddToCart = (
  item: ItemDetail,
  source: AddRemoveShoppingCartSource
): void =>
  ReactGA.event('add_to_cart', {
    items: [convertItemDetailAndMakerAskToAnalyticsItem(item)],
    source
  })

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events#remove_from_cart
export const trackRemoveToCart = (
  item: ItemDetail,
  source: AddRemoveShoppingCartSource
): void =>
  ReactGA.event('remove_from_cart', {
    items: [convertItemDetailAndMakerAskToAnalyticsItem(item)],
    source
  })

export const trackReportFakeCollection = (): void =>
  ReactGA.event('report_fake_collection', {
    event_category: 'user_report'
  })

export const trackTransferNftFromItemDetailPage = (
  collectionName: string
): void =>
  ReactGA.event('transfer_nft_from_item_detail_page', {
    event_category: 'item_detail_page',
    event_label: collectionName
  })

export const trackSweepTool = (collectionName: string): void =>
  ReactGA.event('sweep_tool', {
    event_category: 'collection_detail_page',
    event_label: collectionName
  })

export const trackPurchase = (items: ItemDetail[]): void =>
  ReactGA.event('purchase', {
    items: items.map(convertItemDetailAndMakerAskToAnalyticsItem)
  })

export const trackListForFixedPrice = (): void =>
  ReactGA.event('list_for_fixed_price')

export const trackListForAuction = (): void => ReactGA.event('list_for_auction')

export const trackPlaceAuctionBid = (): void =>
  ReactGA.event('place_auction_bid')

export const trackMakeItemOffer = (): void => ReactGA.event('make_item_offer')

export const trackMint = (id: string, quantity: number): void =>
  ReactGA.event('mint', { event_category: id, quantity })

export const trackHideItems = (): void => ReactGA.event('hide_nft')
