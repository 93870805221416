import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import {
  getOptimizedImageUrl,
  isVideoUrl,
  OptimizedImageType
} from 'utils/imageOptimizer'

export enum ContentType {
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}

const useOptimizedImageUrl = (
  type: OptimizedImageType,
  rawUrl?: string
): {
  contentType: ContentType
  imageUrl: string | undefined
  onImageLoadFailed: () => void
  onVideoLoadFailed: () => void
  showPlaceholder: boolean
} => {
  const defaultContentType = rawUrl
    ? isVideoUrl(rawUrl)
      ? ContentType.VIDEO
      : ContentType.IMAGE
    : ContentType.IMAGE
  const [contentType, setContentType] =
    useState<ContentType>(defaultContentType)
  const isVideo = contentType === ContentType.VIDEO
  const isAudio = contentType === ContentType.AUDIO
  const optimizedImageUrl = getOptimizedImageUrl(rawUrl, type)
  const [imageUrl, setImageUrl] = useState<string | undefined>(
    isVideo || isAudio ? rawUrl : optimizedImageUrl
  )
  const [showPlaceholder, setShowPlaceholder] = useState(false)

  useEffect(() => {
    if (!rawUrl) {
      return
    }
    axios
      .head(rawUrl)
      .then((head) => {
        const headersContentType = head.headers['content-type']
        if (headersContentType?.startsWith('audio')) {
          setContentType(ContentType.AUDIO)
        } else if (headersContentType?.startsWith('video')) {
          setContentType(ContentType.VIDEO)
        }
      })
      .catch((err) => console.error(err))
  }, [rawUrl])

  useEffect(() => {
    setShowPlaceholder(false)
    setImageUrl(isVideo || isAudio ? rawUrl : optimizedImageUrl)
  }, [rawUrl, isVideo, isAudio, optimizedImageUrl])

  const onVideoLoadFailed = useCallback(() => {
    setShowPlaceholder(true)
  }, [])

  const onImageLoadFailed = useCallback(() => {
    if (imageUrl === optimizedImageUrl) {
      // try without CDN : cloudflare CDN has issues resizing large GIFs
      setImageUrl(rawUrl)
    } else {
      setShowPlaceholder(true)
    }
  }, [rawUrl, imageUrl, optimizedImageUrl])

  return {
    contentType,
    imageUrl,
    onImageLoadFailed,
    onVideoLoadFailed,
    showPlaceholder
  }
}

export default useOptimizedImageUrl
