import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

import { fontStyle } from './fonts'

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const lg = defineStyle({
  borderRadius: '16px',
  fontSize: '16px',
  h: { base: '48px', md: '54px' }
})

const md = defineStyle({
  borderRadius: '16px',
  fontSize: '16px',
  h: { base: '44px', md: '48px' }
})

const sizes = {
  lg: definePartsStyle({
    addon: lg,
    field: lg
  }),
  md: definePartsStyle({
    addon: md,
    field: md
  })
}

export const baseStyle = definePartsStyle({
  field: {
    border: '1px solid',
    fontFamily: fontStyle
  }
})

export const filled = definePartsStyle({
  addon: {
    bg: 'bgPrimary',
    border: '1px solid',
    borderColor: 'border'
  },
  field: {
    _focusVisible: {
      borderColor: 'accent.500'
    },
    _hover: {
      bg: 'hover'
    },
    bg: 'bgSecondary',
    border: '1px solid',
    borderColor: 'border'
  }
})

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: { variant: 'filled' },
  sizes,
  variants: { filled }
})
