import {
  avalanche as wagmiAvalanche,
  avalancheFuji as wagmiAvalancheFuji
} from '@wagmi/core/chains'
import { Chain } from 'wagmi'

import AvalancheIcon from '../assets/images/avalanche.png'

export const isTestnet = process.env.REACT_APP_TESTNET_MODE === '1'

export interface JoeChain extends Chain {
  iconUrl: string
  slug: string
}

export const avalanche: JoeChain = {
  ...wagmiAvalanche,
  iconUrl: AvalancheIcon,
  slug: 'avalanche'
}

export const avalancheFuji: JoeChain = {
  ...wagmiAvalancheFuji,
  iconUrl: AvalancheIcon,
  slug: 'avalanche'
}

export const supportedChains: JoeChain[] = isTestnet
  ? [avalancheFuji]
  : [avalanche]
