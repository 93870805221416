import {
  Center,
  Flex,
  Icon,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react'
import { JoeText } from 'components/JoeText'
import { formatDistanceToNow } from 'date-fns'
import React, { useMemo } from 'react'
import { Bell, ExternalLink as ExternalLinkIcon } from 'react-feather'
import {
  isTransactionRecent,
  useAllTransactions
} from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import { getExplorerLink } from 'utils'

const TransactionsPopper = (): JSX.Element => {
  const allTransactions = useAllTransactions()

  const newTransactionsFirst = (a: TransactionDetails, b: TransactionDetails) =>
    b.addedTime - a.addedTime
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  return (
    <Popover trigger="hover" placement="bottom-end">
      <PopoverTrigger>
        <Center
          border={{ base: 0, md: '1px solid' }}
          borderColor={{ base: 'transparent', md: 'border' }}
          bg={{ base: 'transparent', md: 'bgPrimary' }}
          mr={{ base: '0.5rem', md: 0 }}
          boxSize="2.5rem"
          color="textPrimary"
          borderRadius="full"
          cursor="pointer"
          transition="150ms all ease-in-out"
          _hover={{
            bg: { base: 'transparent', md: 'hover' }
          }}
        >
          <Icon as={Bell} boxSize="1.25rem" />
        </Center>
      </PopoverTrigger>
      <PopoverContent
        display="flex"
        flexDir="column"
        maxH="300px"
        overflow="scroll"
      >
        {sortedRecentTransactions.length > 0 ? (
          sortedRecentTransactions.map((transaction) => (
            <Link
              isExternal
              key={transaction.hash}
              href={getExplorerLink(
                transaction.hash,
                'transaction',
                transaction.chainId
              )}
              w="full"
              _hover={{ textDecoration: 'none' }}
            >
              <Flex
                align="center"
                justify="space-between"
                gap="1.25rem"
                p="1rem"
                w="full"
                cursor="pointer"
                transition="150ms background-color ease-in-out"
                _hover={{ bg: 'hover' }}
              >
                <Flex flexDir="column">
                  <JoeText
                    variant="primary"
                    width="10rem"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {transaction.summary}
                  </JoeText>
                  <JoeText color="textSecondary">
                    {formatDistanceToNow(transaction.addedTime)}
                  </JoeText>
                </Flex>
                <ExternalLinkIcon size={20} strokeWidth={1} />
              </Flex>
            </Link>
          ))
        ) : (
          <Center p="2rem">
            <JoeText variant="body">Your transactions will appear here</JoeText>
          </Center>
        )}
      </PopoverContent>
    </Popover>
  )
}

export default TransactionsPopper
