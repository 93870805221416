import { createReducer } from '@reduxjs/toolkit'
import { AuthTokens } from 'types/joebarn'

import { clearTokens, updateTokens } from './actions'

export interface AuthenticationState {
  readonly tokens?: AuthTokens
}

const initialState: AuthenticationState = {
  tokens: undefined
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateTokens, (state, { payload }) => {
      state.tokens = payload.tokens
    })
    .addCase(clearTokens, (state) => {
      state.tokens = undefined
    })
)
