import { ChainId } from '@traderjoe-xyz/joepegs-sdk'
import { WNATIVE } from '@traderjoe-xyz/sdk-core'
import { isTestnet } from 'constants/chains'
import { getAddress } from 'viem'
import { useAccount, useBalance } from 'wagmi'

const useNativeBalance = (chainId?: ChainId) => {
  const { address: account } = useAccount()
  const result = useBalance({ address: account, chainId, enabled: !!chainId })
  return {
    ...result,
    balance: result.data?.value
  }
}

const useWrappedNativeBalance = (chainId?: ChainId) => {
  const { address: account } = useAccount()
  const token = chainId ? getAddress(WNATIVE[chainId].address) : undefined
  const result = useBalance({
    address: account,
    chainId,
    enabled: !!token,
    token
  })
  return {
    ...result,
    balance: result.data?.value
  }
}

const useBalanceAVAX = () => {
  const chainId = isTestnet ? ChainId.FUJI : ChainId.AVALANCHE
  return useNativeBalance(chainId)
}

const useBalanceWAVAX = () => {
  const chainId = isTestnet ? ChainId.FUJI : ChainId.AVALANCHE
  return useWrappedNativeBalance(chainId)
}

export {
  useBalanceAVAX,
  useBalanceWAVAX,
  useNativeBalance,
  useWrappedNativeBalance
}
