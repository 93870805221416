import { Button, Hide, Icon, Image, Show } from '@chakra-ui/react'
import { ReactComponent as WalletIcon } from 'assets/illustrations/svg/wallet.svg'
import Identicon from 'components/Identicon'
import { useProfile } from 'hooks/useJoebarn'
import React, { useEffect } from 'react'
import { useWalletModalToggle } from 'state/application/hooks'
import { useUpdateUserPfpUrl } from 'state/user/hooks'
import { getOptimizedImageUrl } from 'utils/imageOptimizer'
import { useAccount } from 'wagmi'

const Web3Status = (): JSX.Element => {
  const { address: account, isConnected } = useAccount()
  const toggleWalletModal = useWalletModalToggle()
  const { updatePfpUrl, userPfpUrl } = useUpdateUserPfpUrl()
  const { data: profile } = useProfile(account)

  useEffect(() => {
    // if the user is disconnected, or if the user is connected and the
    // useProfile hook does not return a pfpUrl, we want to clear the redux
    // state. if the user is connected and the profile hook returns a pfpUrl
    // (this should occur if a user switches wallets primarily), then we want
    // to update the redux state
    updatePfpUrl(profile?.pfpUrl ?? null)
  }, [profile, updatePfpUrl])

  return isConnected && userPfpUrl ? (
    <Image
      src={getOptimizedImageUrl(userPfpUrl, 'logo-sm')}
      crossOrigin=""
      referrerPolicy="origin"
      alt="Profile picture"
      boxSize="2.5rem"
      borderRadius="full"
      objectFit="contain"
    />
  ) : isConnected ? (
    <Identicon />
  ) : (
    <>
      <Hide below="sm">
        <Button
          variant="outline"
          h="40px"
          borderRadius="12px"
          borderWidth="1px"
          fontWeight="regular"
          onClick={toggleWalletModal}
        >
          Connect Wallet
        </Button>
      </Hide>
      <Show below="sm">
        <Button
          variant="unstyled"
          display="flex"
          alignItems="center"
          justifyContent="center"
          boxSize="2.75rem"
          border="2px solid"
          borderColor="border"
          borderRadius="full"
          bg="transparent"
          onClick={toggleWalletModal}
        >
          <Icon as={WalletIcon} stroke="textPrimary" />
        </Button>
      </Show>
    </>
  )
}

export default Web3Status
