import { Button, Flex, HStack, Icon, Skeleton, VStack } from '@chakra-ui/react'
import { ChainId } from '@traderjoe-xyz/joepegs-sdk'
import { ReactComponent as WalletLogo } from 'assets/images/wallet-icon.svg'
import CurrencyIcon from 'components/CurrencyIcon'
import { JoeText } from 'components/JoeText'
import { isTestnet } from 'constants/chains'
import { useBalanceAVAX, useBalanceWAVAX } from 'hooks/useTokenBalance'
import React from 'react'
import { useWrapCurrencyModalToggle } from 'state/application/hooks'
import { formatBalance } from 'utils'
import { useAccount } from 'wagmi'

interface CurrencyBalanceRowProps {
  icon: JSX.Element
  name: string
  balance?: string
}

const CurrencyBalanceRow = ({
  balance,
  icon,
  name
}: CurrencyBalanceRowProps) => (
  <Flex w="full" justify="space-between">
    <HStack>
      {icon}
      <JoeText fontWeight="semibold" fontSize={14}>
        {name}
      </JoeText>
    </HStack>
    {balance ? (
      <JoeText fontWeight="semibold" fontSize={14} lineHeight="24px">
        {balance}
      </JoeText>
    ) : (
      <Skeleton w="50%" h="1.5rem" />
    )}
  </Flex>
)

interface CurrencyBalanceSectionProps {
  chainName: string
  nativeBalanceRowProps: CurrencyBalanceRowProps
  onWrapClick: () => void
  wrappedBalanceRowProps: CurrencyBalanceRowProps
}

const CurrencyBalanceSection = ({
  chainName,
  nativeBalanceRowProps,
  onWrapClick,
  wrappedBalanceRowProps
}: CurrencyBalanceSectionProps) => (
  <VStack align="flex-start" w="full" spacing={3}>
    <Flex w="full" align="center" justify="space-between">
      <JoeText variant="sm-header">{chainName}</JoeText>
      <Button
        size="xs"
        variant="ghost"
        colorScheme="accent"
        mr={-2}
        onClick={onWrapClick}
      >
        {`Swap ${nativeBalanceRowProps.name} / ${wrappedBalanceRowProps.name}`}
      </Button>
    </Flex>
    <CurrencyBalanceRow {...nativeBalanceRowProps} />
    <CurrencyBalanceRow {...wrappedBalanceRowProps} />
  </VStack>
)

interface WalletBalanceProps {
  onMobileSwapAvaxClick?: () => void
}

const WalletBalance = ({
  onMobileSwapAvaxClick
}: WalletBalanceProps): JSX.Element | null => {
  const { address: account } = useAccount()
  const toggleWrapCurrencyModal = useWrapCurrencyModalToggle()

  const { balance: avaxBalance } = useBalanceAVAX()
  const { balance: wavaxBalance } = useBalanceWAVAX()
  const fmtAvaxBalance =
    avaxBalance !== undefined ? formatBalance(avaxBalance, 18, 2) : undefined
  const fmtWavaxBalance =
    wavaxBalance !== undefined ? formatBalance(wavaxBalance, 18, 2) : undefined

  if (!account) {
    return null
  }

  return (
    <Flex flexDir="column" px={{ base: 0, sm: '1rem' }} gap={6}>
      <HStack spacing={4} mb={-2}>
        <Icon as={WalletLogo} fill="textSecondary" boxSize="24px" />
        <JoeText fontSize="16px" fontWeight="semibold">
          Wallet
        </JoeText>
      </HStack>
      <CurrencyBalanceSection
        chainName="Avalanche C-Chain"
        nativeBalanceRowProps={{
          balance: fmtAvaxBalance,
          icon: <CurrencyIcon symbol="AVAX" boxSize="32px" />,
          name: 'AVAX'
        }}
        wrappedBalanceRowProps={{
          balance: fmtWavaxBalance,
          icon: <CurrencyIcon symbol="WAVAX" boxSize="32px" />,
          name: 'Wrapped AVAX'
        }}
        onWrapClick={() => {
          toggleWrapCurrencyModal({
            chainId: isTestnet ? ChainId.FUJI : ChainId.AVALANCHE
          })
          onMobileSwapAvaxClick?.()
        }}
      />
    </Flex>
  )
}

export default WalletBalance
