import { ChainId } from '@traderjoe-xyz/joepegs-sdk'

export enum CollectionVerificationStatus {
  Blocklisted = 'blocklisted',
  Trusted = 'verified_trusted',
  Unverified = 'unverified',
  Verified = 'verified'
}

export enum CollectionsFilterType {
  ALL = 'total',
  DAILY = '1d',
  MONTHLY = '30d',
  WEEKLY = '7d'
}

export enum CollectionsSortType {
  NUM_SALES = 'num_sales',
  VOLUME = 'volume'
}

export enum CollectionType {
  ERC721 = 'erc721',
  ERC1155 = 'erc1155'
}

export interface CollectionAttributeValue {
  count: number
  value: string
}

export interface CollectionAttribute {
  traitType: string
  values: CollectionAttributeValue[]
}

export interface Collection {
  address: string
  chain: Chain
  chainId: ChainId
  createdAt: number
  floor: bigint
  id: string
  name: string
  numAsks: number

  numItems: number
  numOwners: number
  numSales: number
  symbol: string
  type: CollectionType
  volume: bigint
  volumeTotal: bigint
  attributes?: CollectionAttribute[]
  bannerUrl?: string

  description?: string
  discordUrl?: string

  externalVolume?: bigint
  pctFloorPriceChanged?: number
  pctVolumeChanged?: number
  pfpUrl?: string
  slugName?: string
  twitterUrl?: string
  verified?: CollectionVerificationStatus
  websiteUrl?: string
}

export interface ItemOwnership {
  ownerId: string
  quantity: number
  name?: string | null
  pfpUrl?: string | null
}

export interface ItemAttribute {
  count: number | null
  countPercentage: number | null
  displayType: string | null
  rarityScore: number | null
  traitType: string
  value: string | number
}

export interface ItemMetadata {
  animationUrl: string | null
  attributes: ItemAttribute[]
  description: string | null
  externalUrl: string | null
  image: string | null
  name: string | null
}

export enum ItemsFilterType {
  BUY_NOW = 'buy_now',
  HAS_OFFERS = 'has_offers',
  HIDDEN = 'hidden',
  ON_AUCTION = 'on_auction',
  UNLISTED = 'unlisted'
}

export enum ItemsSortType {
  PRICE_ASC = 'price_asc',
  PRICE_DESC = 'price_desc',
  RARITY_ASC = 'rarity_asc',
  RARITY_DESC = 'rarity_desc',
  RECENT_ACTIVITY = 'recent_activity',
  RECENT_LISTING = 'recent_listing',
  RECENT_SALE = 'recent_sale'
}

export enum CollectionCreationType {
  NON_TRANSFERABLE = 'non_transferable',
  TRANSFERABLE = 'transferable'
}

export interface Item {
  chain: Chain
  chainId: ChainId
  collection: string
  collectionDescription: string | null
  collectionName: string | null
  collectionPfpUrl: string | null
  collectionSlug: string | null
  collectionSymbol: string | null
  creationType: CollectionCreationType
  id: string
  isHidden: boolean
  metadata: ItemMetadata | null
  numOwners: number | null
  numReactions: number | null
  owner: ItemOwnership | null
  rarityRanking: number | null
  rarityScore: number | null
  tokenId: string
  verified: CollectionVerificationStatus
}

export enum MakerOrderStatus {
  CANCELLED = 'CANCELLED',
  EXECUTED = 'EXECUTED',
  EXPIRED = 'EXPIRED',
  TRANSFERRED = 'TRANSFERRED',
  VALID = 'VALID'
}

export enum MakerOrderSortType {
  PRICE_ASC = 'price_asc',
  PRICE_DESC = 'price_desc'
}

export interface GetMakerOrdersParams {
  chain?: Chain
  collection?: string
  isOrderAsk?: boolean
  orderBy?: MakerOrderSortType
  signer?: string
  tokenId?: string
}

export interface MakerOrder {
  amount: bigint
  chain: Chain
  chainId: ChainId
  collection: `0x${string}`
  currency: `0x${string}`
  endTime: bigint
  isOrderAsk: boolean
  minPercentageToAsk: bigint
  nonce: bigint
  params: `0x${string}`
  price: bigint
  signer: `0x${string}`
  startTime: bigint
  strategy: `0x${string}`
  tokenId: bigint
  id?: number
  status?: MakerOrderStatus
}

export interface SignedMakerOrder extends MakerOrder {
  r: `0x${string}`
  s: `0x${string}`
  v: number
}

export interface TakerOrder {
  isOrderAsk: boolean
  minPercentageToAsk: bigint
  params: `0x${string}`
  price: bigint
  taker: `0x${string}`
  tokenId: bigint
}

export interface ItemDetail extends Item {
  bestBid: SignedMakerOrder | null
  collectionType: CollectionType | null | undefined
  currentAsk: SignedMakerOrder | null
  auction?: Auction
  avaxUsdPrice?: number
  collectionNumItems?: number
  connectedUserNumListed?: number
  floorPrice?: bigint
  lastSalePrice?: bigint
  nativeUsdPrice?: number
  protocolFee?: number
  royaltyFee?: number
}

export interface ItemInfoAndTransferTime {
  collection: string
  collectionName: string | null
  id: string
  metadata: ItemMetadata | null
  tokenId: string
  transferredAt: Date
}

export enum ActivityType {
  BID = 'bid',
  COLLECTION_BID = 'collection_bid',
  ENGLISH_AUCTION_BID = 'english_auction_bid',
  ENGLISH_AUCTION_SETTLE = 'english_auction_settle',
  ENGLISH_AUCTION_START = 'english_auction_start',
  LISTING = 'listing',
  MINT = 'mint',
  SALE = 'sale',
  TRANSFER = 'transfer'
}

export interface Activity {
  activityType: ActivityType
  chain: Chain
  chainId: ChainId
  collection: string
  collectionName: string | null
  collectionSymbol: string | null
  fromAddress: string | null
  image: string | null
  quantity: number
  timestamp: number
  toAddress: string | null
  tokenId: string
  transactionHash: string | null

  // Sales / Bids / Listings
  currency?: string
  isTakerAsk?: boolean
  orderHash?: string
  orderNonce?: string
  price?: bigint
  status?: MakerOrderStatus
  strategy?: string
}

export interface Profile {
  address: string
  name: string | null
  numAsks: number
  numBids: number
  numItems: number
  pfpUrl: string | null
}

interface BidMadeCollection {
  name: string
  pfpUrl: string
}

export interface BidMade {
  bidMade: SignedMakerOrder
  collection?: BidMadeCollection
  item?: Item
}

export interface UserBidsReceived {
  bidsReceived: SignedMakerOrder[]
  item: Item
}

export interface SearchResults {
  collections: Collection[]
  items: ItemDetail[]
  profiles: Profile[]
}

export interface UserCollection {
  address: string
  chain: Chain
  dailyVolume: bigint
  floorPrice: bigint
  name: string | null
  numItems: number
  numOwners: number
  pfpUrl: string | null
  verified: CollectionVerificationStatus
}

export interface FeaturedCollection {
  caption: string
  chain: Chain
  chainId: ChainId
  id: string
  imageUrl: string
  priority: number
  title: string
  pfpUrl?: string
}

export interface CollectionListEntryItem {
  imageUrl: string
  tokenId: string
}

export interface CollectionListEntry {
  chain: Chain
  chainId: ChainId
  collectionId: string
  collectionName: string
  collectionPfpUrl: string
  entryItems: CollectionListEntryItem[]
  floorPrice: bigint
  id: string
  imageUrl: string
  numItems: number
  priority: number
  volume7d: bigint
}

export interface CollectionList {
  description: string
  entries: CollectionListEntry[]
  id: number
  priority: number
  title: string
}

export enum LaunchStatus {
  DRAFT = 'draft',
  ENDED = 'ended',
  LIVE = 'live',
  UPCOMING = 'upcoming'
}

export interface TeamMember {
  description: string
  imageUrl: string | null
  linkedinUrl: string | null
  name: string
  role: string
  twitterUrl: string | null
}

export interface Team {
  members: TeamMember[]
  name: string
}

export interface LaunchContentBlockData {
  assetsUrl: string[] | null
  body: string | null
  template:
    | 'video'
    | 'image'
    | 'one_column_text'
    | 'two_columns_text'
    | 'full_width_banner'
    | 'bullet'
    | 'carousel'
  title: string | null
}

export interface LaunchContentBlock {
  data: LaunchContentBlockData
  order: number
}

export interface Launch {
  bannerUrl: string | null
  chain: Chain
  chainId: ChainId
  collection: Collection | null
  collectionAddress: string | null
  contentBlocks: LaunchContentBlock[] | null
  description: string | null
  discordUrl: string | null
  id: number
  launchpeg: Launchpeg | null
  mintCardAssetUrl: string | null
  mintDetailAssetUrl: string
  name: string
  slugName: string
  startTime: number | null
  status: LaunchStatus
  team: Team | null
  twitterUrl: string | null
  websiteUrl: string | null
}

export interface Launchpeg {
  allowlistPrice: bigint | null
  allowlistStartTime: number | null
  auctionPrice: bigint | null
  auctionSaleStartTime: number | null
  collectionName: string
  collectionSize: number
  collectionSymbol: string
  collectionTotalSupply: number
  id: string
  lastMintPrice: bigint | null
  launchpegType: 'FlatLaunchpeg' | 'Launchpeg' | 'Unknown'
  publicSaleEndTime: number | null
  publicSalePrice: bigint | null
  publicSaleStartTime: number | null
}

export type GetMakerOrderByIdsResponse = { [id: string]: SignedMakerOrder }

export interface AuthMessageResponse {
  message: string
}

export interface AuthTokens {
  accessToken: string
  refreshToken?: string
}

export interface EditProfileResponse {
  address: string
  discordHandle: string | null
  name: string | null
  numAsks: number
  numBids: number
  numItems: number
  pfpUrl: string | null
  twitterUrl: string | null
}

export interface EditProfileArgs {
  discordHandle?: string | null
  name?: string | null
  pfpItemChain?: Chain
  pfpItemId?: string | null
  twitterUrl?: string | null
}

export enum AnalyticsFilterType {
  DAILY = '1d',
  MONTHLY = '30d',
  TOTAL = 'total',
  WEEKLY = '7d'
}

export interface AnalyticsDataPoint {
  floorPrice: number
  timestamp: number
  volume: string
}

export enum AnalyticsListingDepthsPriceIncrement {
  FIVE = '5000000000000000000',
  ONE = '1000000000000000000',
  ONE_TENTH = '100000000000000000'
}

export interface AnalyticsListingDepthDataPoint {
  highPrice: number
  lowPrice: number
  numListings: number
}

export enum AuctionStatus {
  CANCELLED = 'cancelled',
  SCHEDULED = 'scheduled',
  SETTLED = 'settled',
  VALID = 'valid'
}

export enum AuctionType {
  DUTCH = 'dutch',
  ENGLISH = 'english'
}

export interface AuctionBid {
  auctionId: string
  bidAmount: bigint
  bidder: string
  chain: Chain
  chainId: ChainId
  timestamp: number
  transactionHash: string
  bidderName?: string | null
  bidderPfpUrl?: string | null
}

export interface Auction {
  chain: Chain
  chainId: ChainId
  creator: string
  endTime: number
  englishAuctionMinBidPrice: bigint
  englishAuctionRefreshTime: number
  id: string
  startTime: number
  status: AuctionStatus
  type: AuctionType
  englishAuctionSameBidderMinBidPrice?: bigint
  englishAuctionTopBid?: AuctionBid
}

export enum AuctionBidMadeStatus {
  HIGHEST_BID = 'highest',
  OUTBID = 'outbid',
  WON = 'won'
}

export interface AuctionBidMade {
  auctionBidMade: AuctionBid
  auctionCreator: string
  bidStatus: AuctionBidMadeStatus
  item?: ItemDetail | null
}

export enum MakerOrderStrategy {
  ANY_ITEM_FROM_COLLECTION_FOR_FIXED_PRICE = 'any_item_from_collection_for_fixed_price',
  STANDARD_SALE_FOR_FIXED_PRICE = 'standard_sale_for_fixed_price'
}

export interface MakerOrderPayloadBody {
  amount: number
  collection: string
  duration: number
  isOrderAsk: boolean
  price: string
  signer: string
  strategy: MakerOrderStrategy
  tokenId: string
}

export interface MakerOrderPayloadResponse {
  message: MakerOrder
}

export interface ToggleHiddenBatchRequest {
  collectionAddresses: string[]
  filters: ItemsFilterType[]
  isHidden: boolean
  itemIds: [Chain, string][]
  search: string | null
}

export interface ToggleHiddenBatchResponse {
  isHidden: boolean
  itemIds: string[]
  responseMessage: string[]
}

interface DraftMintpegCollectionBase {
  admins: string[] | null
  collectionAvatarUrl: string | null
  collectionBannerUrl: string | null
  description: string | null
  discordUrl: string | null
  id: number
  name: string | null
  royaltyFee: number | null
  royaltyFeeRecipient: string | null
  slug: string | null
  symbol: string | null
  twitterUrl: string | null
  websiteUrl: string | null
}

export interface MintpegCollection {
  admins: string[] | null
  chain: Chain
  collectionAvatarUrl: string | null
  collectionBannerUrl: string | null
  collectionName: string
  collectionSlug: string
  collectionSymbol: string
  creator: string
  description: string | null
  discordUrl: string | null
  id: string
  royaltyFee: number | null
  royaltyReceiver: string | null
  twitterUrl: string | null
  websiteUrl: string | null
}

export interface DraftMintpegItem {
  description: string | null
  id: number
  imageUrl: string | null
  ipfsUrl: string | null
  name: string | null
}

export interface DraftMintpegNFT {
  description: string
  image_url: string | null
  ipfs_url: string | null
  name: string
}

export interface CreateDraftMintpegCollection {
  admins: string[] | null
  chain: string | null | undefined
  chainId: ChainId | null
  collection_avatar_url: string | null
  collection_banner_url: string | null
  description: string | null
  discord_url: string | null
  draft_items: DraftMintpegNFT[]
  name: string | null
  royalty_fee: number | null
  royalty_fee_recipient: string | null
  slug: string | null
  symbol: string | null
  twitter_url: string | null
  website_url: string | null
  id?: number | null
}

export type UpdateDraftMintpegCollection = CreateDraftMintpegCollection

export interface DraftMintpegCollection extends DraftMintpegCollectionBase {
  draftItems: DraftMintpegItem[]
}

export interface PaginatedDraftMintpegCollection
  extends DraftMintpegCollectionBase {
  numDraftItems: number
}

export type MintpegDraftImageType = 'avatar' | 'banner' | 'nft'

export interface MintpegNFTImageUpload {
  contentType: string
  filename: string
  metadata: {
    description: string
    name: string
  }
  url: string
}

export interface AddressAndNumItems {
  numItems: number
  user: {
    address: string
    name: string
    pfpUrl: string
  }
}

export interface CollectionAnalytics {
  averageBoughtBelowFloorPrice: number
  averageHoldingDays: number
  averageNeverTransferSinceMint: number
  numTotalOwners: number
}

export interface PublishDraftMintpegCollection {
  address: string
  draftMintpegCollection: UpdateDraftMintpegCollection
}

export interface UpdateMintpegCollection {
  collectionAvatarUrl: string | null
  collectionBannerUrl: string | null
  collectionName: string | null
  collectionSlug: string | null
  description: string | null
  discordUrl: string | null
  royaltyFee: number | null | string
  royaltyReceiver: string | null
  twitterUrl: string | null
  websiteUrl: string | null
}

export type Chain = 'avalanche'

export enum ChainQueryParam {
  ALL = 'all',
  AVALANCHE = 'avalanche'
}

export enum DataKeyEntities {
  // composer
  DraftMintpegQuery = 'DraftMintpegQuery',
  DraftMintpegsQuery = 'DraftMintpegsQuery',
  MintpegsQuery = 'MintpegsQuery'
}

export enum UserRoleType {
  ADMIN = 'admin',
  OWNER = 'owner'
}

export interface TraitAnalytics {
  traitType: string
  values: TraitAnalyticsValue[]
}

export interface TraitAnalyticsValue {
  floorPrice: string | null
  numItems: number
  numListings: number
  numOwners: number
  value: string
}

export interface CollectionActivity {
  activityType: ActivityType
  bestBid: null | string
  chain: Chain
  chainId: number
  collectionId: string
  collectionName: string
  collectionPfpUrl: string
  collectionSlug: string
  collectionSymbol: string
  fromAddress: string | null
  image: string
  itemId: string
  name: string
  price: string
  timestamp: number
  toAddress: string | null
  tokenId: string
  verified: CollectionVerificationStatus
}

export interface UpdateCollection {
  bannerUrl?: string
  description?: string
  discordUrl?: string
  pfpUrl?: string
  slugName?: string
  twitterUrl?: string
}
