import { useColorMode } from '@chakra-ui/react'
import { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { AppDispatch, AppState } from '../index'
import { updateUserPfpUrl } from './actions'

export const useIsDarkMode = (): boolean => {
  const { colorMode } = useColorMode()
  return colorMode === 'dark'
}

export const useDarkModeManager = (): [boolean, () => void] => {
  const darkMode = useIsDarkMode()
  const { toggleColorMode } = useColorMode()

  return [darkMode, toggleColorMode]
}

export const useUserPfpUrl = (): string | null => {
  const { userPfpUrl } = useSelector<AppState, { userPfpUrl: string | null }>(
    ({ user: { userPfpUrl } }) => ({
      userPfpUrl
    }),
    shallowEqual
  )
  return userPfpUrl ?? null
}

interface UseUpdateUserPfpUrlReturnProps {
  updatePfpUrl: (userPfpUrl: string | null) => void
  userPfpUrl: string | null
}

export const useUpdateUserPfpUrl = (): UseUpdateUserPfpUrlReturnProps => {
  const dispatch = useDispatch<AppDispatch>()
  const userPfpUrl = useUserPfpUrl()
  const updatePfpUrl = useCallback(
    (userPfpUrl: string | null) => {
      dispatch(updateUserPfpUrl({ userPfpUrl }))
    },
    [dispatch]
  )

  return { updatePfpUrl, userPfpUrl }
}
