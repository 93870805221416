import { Center, IconButton } from '@chakra-ui/react'
import React from 'react'
import { Moon, ShoppingCart, Sun, X } from 'react-feather'
import { useShoppingCartItems } from 'state/shoppingCart/hooks'

interface DarkModeButtonProps {
  isActive: boolean
  onClick: () => void
}

export const DarkModeButton = ({
  isActive,
  onClick
}: DarkModeButtonProps): JSX.Element => (
  <IconButton
    aria-label="toggle dark mode"
    variant="outlineRoundedFull"
    icon={isActive ? <Sun size={20} /> : <Moon size={20} />}
    onClick={onClick}
  />
)

interface CloseButtonProps {
  onClick: () => void
}

export const CloseButton = ({ onClick }: CloseButtonProps): JSX.Element => (
  <IconButton
    aria-label="close"
    variant="outlineRoundedFull"
    flexShrink={0}
    icon={<X size={20} />}
    onClick={onClick}
  />
)

export const ShoppingCartBadge = (): JSX.Element | null => {
  const items = useShoppingCartItems()
  const itemsCount = items.length
  return itemsCount > 0 ? (
    <Center
      pos="absolute"
      top={0}
      right={itemsCount > 99 ? -2 : 0}
      fontSize="13px"
      fontWeight="semibold"
      h="18px"
      minW="18px"
      w="fit-content"
      borderRadius="full"
      color="black"
      border="1px solid"
      borderColor="bgPrimary"
      bg="green.300"
    >
      {itemsCount > 99 ? '99+' : itemsCount}
    </Center>
  ) : null
}

interface ShoppingCartButtonProps {
  onClick: () => void
}

export const ShoppingCartButton = ({
  onClick
}: ShoppingCartButtonProps): JSX.Element => (
  <IconButton
    aria-label="open shopping cart"
    variant="outlineRoundedFull"
    pos="relative"
    icon={
      <>
        <ShoppingCart size={18} />
        <ShoppingCartBadge />
      </>
    }
    onClick={onClick}
  />
)
