import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Link
} from '@chakra-ui/react'
import { CloseButton, DarkModeButton } from 'components/HeaderButtons'
import { JoeText } from 'components/JoeText'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useDarkModeManager } from 'state/user/hooks'

interface MobileAppNavMenuProps {
  isOpen: boolean
  onClose: () => void
}

const MobileAppNavMenu = ({ isOpen, onClose }: MobileAppNavMenuProps) => {
  const [darkMode, toggleDarkMode] = useDarkModeManager()

  return (
    <Drawer size="full" isOpen={isOpen} onClose={onClose}>
      <DrawerContent bg="bgSecondary">
        <DrawerHeader
          display="flex"
          justifyContent="space-between"
          border="none"
        >
          <DarkModeButton isActive={darkMode} onClick={toggleDarkMode} />
          <CloseButton onClick={onClose} />
        </DrawerHeader>
        <DrawerBody
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
          gap="0.5rem"
          mt="-120px"
        >
          <Link
            as={RouterLink}
            to={'/recent'}
            display="flex"
            justifyContent="center"
            alignItems="center"
            h="48px"
            w="full"
            onClick={onClose}
          >
            <JoeText variant="panel-header">Recent</JoeText>
          </Link>
          <Link
            as={RouterLink}
            to={'/collections'}
            display="flex"
            justifyContent="center"
            alignItems="center"
            h="48px"
            w="full"
            onClick={onClose}
          >
            <JoeText variant="panel-header">Collections</JoeText>
          </Link>
          <Link
            as={RouterLink}
            to={'/mint'}
            display="flex"
            justifyContent="center"
            alignItems="center"
            h="48px"
            w="full"
            onClick={onClose}
          >
            <JoeText variant="panel-header">Mint</JoeText>
          </Link>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default MobileAppNavMenu
