import { ChainId } from '@traderjoe-xyz/joepegs-sdk'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'state'
import { useAddErrorPopup } from 'state/application/hooks'
import { GetMakerOrderByIdsResponse, ItemDetail } from 'types/joebarn'
import { trackAddToCart, trackRemoveToCart } from 'utils/analytics'
import { AddRemoveShoppingCartSource } from 'utils/analytics/shoppingCart'

import {
  addItemsToShoppingCart,
  clearShoppingCart,
  removeItemfromShoppingCart,
  setShoppingCartItems,
  updateMakerAsks
} from './actions'
import { fromSerializableItemDetail } from './utils'

// get shopping cart items
export const useShoppingCartItems = (): ItemDetail[] =>
  useSelector<AppState, AppState['shoppingCart']>((state) => state.shoppingCart)
    .items.map(fromSerializableItemDetail)
    .filter((item) => !!item) as ItemDetail[]

// we only support items from the same chain at the same time
const useShoppingCartItemsChainId = (): ChainId | undefined => {
  const items = useShoppingCartItems()
  return items.length > 0 ? items[0].chainId : undefined
}

export const useAddItemtoShoppingCartDisabledReason = (item?: ItemDetail) => {
  const shoppingCartChainId = useShoppingCartItemsChainId()
  return useMemo((): string | undefined => {
    if (!shoppingCartChainId || !item) return undefined
    return item.chainId !== shoppingCartChainId
      ? "Items from different chains can't be purchased together."
      : undefined
  }, [item, shoppingCartChainId])
}

// adds items to cart
export const useAddItemsToShoppingCart = (): ((
  items: ItemDetail[]
) => void) => {
  const cartItems = useShoppingCartItems()
  const addErrorPopup = useAddErrorPopup()
  const dispatch = useDispatch()
  return useCallback(
    (items: ItemDetail[]) => {
      // the amount of items you can buy at the same time is capped to 200
      // to prevent the contract from running out of gas
      if (cartItems.length >= 200) {
        addErrorPopup('You have reached the limit of items in your cart (200).')
        return
      }
      dispatch(addItemsToShoppingCart({ items }))
    },
    [dispatch, addErrorPopup, cartItems]
  )
}

// set items in cart
export const useSetShoppingCartItems = (): ((items: ItemDetail[]) => void) => {
  const dispatch = useDispatch()
  return useCallback(
    (items: ItemDetail[]) => {
      dispatch(setShoppingCartItems({ items }))
    },
    [dispatch]
  )
}

// remove an item from cart
export const useRemoveItemFromShoppingCart = (): ((
  item: ItemDetail
) => void) => {
  const dispatch = useDispatch()
  return useCallback(
    (item: ItemDetail) => {
      dispatch(removeItemfromShoppingCart({ item }))
    },
    [dispatch]
  )
}

// returns true when the item is in the shopping cart, else false
export const useIsItemInShoppingCart = (): ((item?: ItemDetail) => boolean) => {
  const items = useShoppingCartItems()
  return useCallback(
    (item?: ItemDetail) => {
      if (!item) {
        return false
      }
      return (
        items.find(
          (it) =>
            item.collection.toLowerCase() === it.collection.toLowerCase() &&
            item.tokenId === it.tokenId &&
            item.currentAsk?.id === it.currentAsk?.id
        ) !== undefined
      )
    },
    [items]
  )
}

// add or remove the item to/from the shopping cart
export const useToggleItemInShoppingCart = (
  source: AddRemoveShoppingCartSource
): ((item?: ItemDetail) => void) => {
  const add = useAddItemsToShoppingCart()
  const remove = useRemoveItemFromShoppingCart()
  const isInShoppingCart = useIsItemInShoppingCart()
  const shoppingCartChainId = useShoppingCartItemsChainId()
  return useCallback(
    (item?: ItemDetail) => {
      if (!item) {
        return
      }
      if (shoppingCartChainId && item.chainId !== shoppingCartChainId) {
        return
      }
      if (isInShoppingCart(item)) {
        remove(item)
        trackRemoveToCart(item, source)
      } else if (!!item.currentAsk) {
        add([item])
        trackAddToCart(item, source)
      }
    },
    [isInShoppingCart, add, remove, source, shoppingCartChainId]
  )
}

// add or remove an item to/from the cart
// if the item is on the same chain as the current items in the cart
export const useMobileToggleItemInShoppingCart = (
  source: AddRemoveShoppingCartSource
) => {
  const shoppingCartChainId = useShoppingCartItemsChainId()
  const toggleItemInShoppingCart = useToggleItemInShoppingCart(source)
  const addErrorPopup = useAddErrorPopup()
  return useCallback(
    (item?: ItemDetail) => {
      if (!item) return
      if (shoppingCartChainId && shoppingCartChainId !== item.chainId) {
        addErrorPopup(
          "Items from different chains can't be purchased together."
        )
        return
      }
      toggleItemInShoppingCart(item)
    },
    [toggleItemInShoppingCart, shoppingCartChainId, addErrorPopup]
  )
}

// remove all items from shopping cart
export const useClearShoppingCart = (): (() => void) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearShoppingCart())
  }, [dispatch])
}

// update the maker ask for each item
export const useUpdateShoppingCartItems = (): ((
  currentAsks: GetMakerOrderByIdsResponse
) => void) => {
  const dispatch = useDispatch()
  return useCallback(
    (currentAsks: GetMakerOrderByIdsResponse) => {
      dispatch(updateMakerAsks({ currentAsks }))
    },
    [dispatch]
  )
}
