import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Hide,
  Icon,
  Link,
  Show,
  useDisclosure
} from '@chakra-ui/react'
import { ReactComponent as JoepegsLogo } from 'assets/illustrations/svg/joepegs-logo.svg'
import { ReactComponent as MenuIcon } from 'assets/illustrations/svg/menu.svg'
import {
  DarkModeButton,
  ShoppingCartBadge,
  ShoppingCartButton
} from 'components/HeaderButtons'
import MobileAppNavMenu from 'components/MobileAppNavMenu'
import TransactionsPopper from 'components/TransactionsPopper'
import WalletMenu from 'components/WalletMenu'
import WalletMobileMenu from 'components/WalletMobileMenu'
import Web3Status from 'components/Web3Status'
import React from 'react'
import { Search, ShoppingCart } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { useToggleShoppingCartModal } from 'state/application/hooks'
import { useDarkModeManager } from 'state/user/hooks'
import { useAccount } from 'wagmi'

import HeaderSearchPanel from './HeaderSearchPanel'
import MobileSearchPanel from './MobileSearchPanel'

const MobileButton = (props: ButtonProps) => (
  <Button
    variant="unstyled"
    pos="relative"
    display="flex"
    justifyContent="center"
    alignItems="center"
    border={0}
    background="transparent"
    w="2.75rem"
    h="2.75rem"
    {...props}
  />
)

const Header = (): JSX.Element => {
  const { address: account, isConnected } = useAccount()
  const [darkMode, toggleDarkMode] = useDarkModeManager()
  const toggleShoppingCartModal = useToggleShoppingCartModal()
  const {
    isOpen: isAppNavMenuOpen,
    onClose: onAppNavMenuClose,
    onOpen: onAppNavMenuOpen
  } = useDisclosure()
  const {
    isOpen: isMobileSearchOpen,
    onClose: onMobileSearchClose,
    onOpen: onMobileSearchOpen
  } = useDisclosure()
  const {
    isOpen: isWalletMobileMenuOpen,
    onClose: onWalletMobileMenuClose,
    onOpen: onWalletMobileMenuOpen
  } = useDisclosure()
  return (
    <>
      <MobileAppNavMenu isOpen={isAppNavMenuOpen} onClose={onAppNavMenuClose} />
      <Flex
        zIndex={5}
        position="fixed"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        top={0}
        background="bgPrimary"
        padding={{
          base: '1rem 0.25rem 1rem 1rem',
          md: '1rem 2rem',
          xs: '1rem'
        }}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          maxWidth={1616}
          margin="0 auto"
        >
          <Flex
            alignItems="center"
            gap={{ base: '1rem', xs: '2.5rem' }}
            zIndex={5}
            h="40px"
          >
            <Link
              as={NavLink}
              aria-label="joepegs-logo"
              id="home-nav-link"
              to="/"
            >
              <Icon
                as={JoepegsLogo}
                display="block"
                margin="auto"
                fill="textPrimary"
                w="100px"
                h="full"
                objectFit="cover"
                alignSelf="center"
                _hover={{ fill: 'textSecondary' }}
              />
            </Link>
            <Box display={{ base: 'none', md: 'flex' }}>
              <Link
                as={NavLink}
                to={'/recent'}
                fontWeight="semibold"
                color="textSecondary"
                _hover={{ color: 'textPrimary' }}
                _activeLink={{ color: 'textPrimary' }}
              >
                Recent
              </Link>
            </Box>
            <Box display={{ base: 'none', md: 'flex' }}>
              <Link
                as={NavLink}
                to={'/collections'}
                fontWeight="semibold"
                color="textSecondary"
                _hover={{ color: 'textPrimary' }}
                _activeLink={{ color: 'textPrimary' }}
              >
                Collections
              </Link>
            </Box>
            <Box display={{ base: 'none', md: 'flex' }}>
              <Link
                as={NavLink}
                to={'/mint'}
                fontWeight="semibold"
                color="textSecondary"
                _hover={{ color: 'textPrimary' }}
                _activeLink={{ color: 'textPrimary' }}
              >
                Mint
              </Link>
            </Box>
          </Flex>
          <Box
            display={{ base: 'none', sm: 'block' }}
            width="100%"
            padding="0 2rem"
          >
            <HeaderSearchPanel />
          </Box>
          <Flex
            flexDir="row"
            alignItems="center"
            justifySelf="flex-end"
            zIndex={5}
          >
            <Flex
              alignItems="center"
              gap="0.5em"
              width={{ base: '100%', md: 'auto' }}
              justifyContent={{ base: 'space-between', md: 'flex-start' }}
            >
              <Box display={{ base: 'none', md: 'flex' }}>
                <DarkModeButton isActive={darkMode} onClick={toggleDarkMode} />
              </Box>
              <Box display={{ base: 'flex', sm: 'none' }}>
                <MobileButton
                  aria-label="search button"
                  onClick={onMobileSearchOpen}
                >
                  <Icon as={Search} color="textPrimary" boxSize="20px" />
                </MobileButton>
              </Box>
              <Box display={{ base: 'flex', md: 'none' }}>
                <MobileButton
                  aria-label="shopping cart button"
                  onClick={toggleShoppingCartModal}
                >
                  <ShoppingCartBadge />
                  <Icon as={ShoppingCart} color="textPrimary" boxSize="20px" />
                </MobileButton>
              </Box>
              <Box display={{ base: 'none', md: 'flex' }}>
                <ShoppingCartButton
                  aria-label="shopping cart button"
                  onClick={toggleShoppingCartModal}
                />
              </Box>
              {account ? <TransactionsPopper /> : null}
              <Hide below="sm">
                <WalletMenu />
              </Hide>
              <Show below="sm">
                <Box
                  onClick={() => {
                    if (isConnected) onWalletMobileMenuOpen()
                  }}
                >
                  <Web3Status />
                </Box>
              </Show>
              <Box display={{ base: 'flex', md: 'none' }}>
                <MobileButton>
                  <MenuIcon
                    onClick={onAppNavMenuOpen}
                    width={16}
                    height={16}
                    stroke={darkMode ? 'white' : 'black'}
                  />
                </MobileButton>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <WalletMobileMenu
          isOpen={isWalletMobileMenuOpen}
          onClose={onWalletMobileMenuClose}
        />
        <MobileSearchPanel
          isOpen={isMobileSearchOpen}
          onClose={onMobileSearchClose}
        />
      </Flex>
    </>
  )
}

export default Header
