import { Box, Button, Flex, HStack, Icon } from '@chakra-ui/react'
import { JoeText } from 'components/JoeText'
import LoaderFullScreen from 'components/LoaderFullScreen'
import PageHelmet from 'components/PageHelmet'
import { PAGE_HELMET_COLLECTION_DESCRIPTION } from 'constants/index'
import { useIsOwnable } from 'hooks/useIsOwnable'
import { useCollection, useGetCollectionAdmins } from 'hooks/useJoebarn'
import React from 'react'
import { ArrowLeft } from 'react-feather'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Chain } from 'types/joebarn'
import { convertChainToChainId } from 'utils/chain'
import { useAccount } from 'wagmi'

import CollectionEditForm from './CollectionEditForm'

const CollectionEdit = () => {
  const { address: connectedUserAddress } = useAccount()
  const { addressOrSlug, chain } = useParams() as {
    addressOrSlug: string
    chain: Chain
  }

  const navigate = useNavigate()

  const { data: collection, isLoading: isLoadingCollection } = useCollection(
    addressOrSlug,
    chain
  )

  const { data: adminAddresses } = useGetCollectionAdmins({
    chain,
    collectionAddress: addressOrSlug
  })

  const { isOwner } = useIsOwnable({
    address: addressOrSlug,
    chainId: convertChainToChainId(chain)
  })

  const canEdit =
    isOwner ||
    (adminAddresses &&
      connectedUserAddress &&
      adminAddresses.includes(connectedUserAddress.toLowerCase()))

  if (!collection) {
    return isLoadingCollection ? <LoaderFullScreen /> : null
  }

  if (!canEdit) {
    return (
      <Navigate to={`/collections/${chain}/${collection.address}`} replace />
    )
  }

  return (
    <Box
      width="100%"
      maxWidth="1616px"
      margin="0 auto"
      paddingBottom={{ base: '2rem', xs: '0' }}
    >
      <PageHelmet
        title={`Joepegs | Leading NFT Marketplace | ${collection?.name || ''}`}
        description={PAGE_HELMET_COLLECTION_DESCRIPTION}
        url={location.pathname}
      />
      <Flex
        w="full"
        flexDir="column"
        p={{ '3xl': '0.5rem 0', base: '0.5rem 1rem', md: '0.5rem 2rem' }}
        mt={{ base: 0, md: '2rem' }}
        mx="auto"
        maxW="800px"
      >
        <HStack gap={4} mb={8}>
          <Button
            variant="unstyled"
            display="flex"
            alignItems="center"
            justifyContent="center"
            border="1px solid"
            borderColor="border"
            bg="bgPrimary"
            w={{ base: '2.5rem', md: '3rem' }}
            h={{ base: '2.5rem', md: '3rem' }}
            borderRadius="12px"
            transition="150ms background-color ease-in-out;"
            _hover={{ bg: 'hover' }}
            onClick={() =>
              navigate(`/collections/${collection.chain}/${collection.address}`)
            }
          >
            <Icon as={ArrowLeft} boxSize="18px" color="textPrimary" />
          </Button>
          <JoeText variant="h1">Manage Page</JoeText>
        </HStack>
        <CollectionEditForm collection={collection} />
      </Flex>
    </Box>
  )
}

export default CollectionEdit
