import { Icon, IconProps } from '@chakra-ui/react'
import { ReactComponent as AvaxIcon } from 'assets/illustrations/svg/avax-rounded.svg'
import React from 'react'
import { Chain } from 'types/joebarn'

interface ChainIconProps {
  chain?: Chain
}

const ChainIcon = ({ chain, ...props }: ChainIconProps & IconProps) => {
  switch (chain) {
    case 'avalanche':
      return <Icon as={AvaxIcon} fill="avaxRed" {...props} />
    case undefined:
      return null
  }
}

export default ChainIcon
