import {
  extendTheme,
  SystemStyleObject,
  ThemeComponents,
  ThemeConfig,
  withDefaultVariant
} from '@chakra-ui/react'
import { fontStyle } from 'chakra/fonts'

import { buttonTheme } from './button'
import { checkboxTheme } from './checkbox'
import { drawerTheme } from './drawer'
import { inputTheme } from './input'
import { menuTheme } from './menu'
import { modalTheme } from './modal'
import { popoverTheme } from './popover'
import { switchTheme } from './switch'
import { tabsTheme } from './tabs'
import { tooltipTheme } from './tooltip'

const config: ThemeConfig = {
  initialColorMode: 'dark'
}

const styles: { global: { [key: string]: SystemStyleObject } } = {
  global: {
    '#chakra-toast-manager-top-right': {
      top: 'calc(env(safe-area-inset-top, 0px) + 66px) !important'
    },

    '*': {
      boxSizing: 'border-box'
    },

    // Chakra default override
    '*, *::before, &::after': {
      wordWrap: 'none'
    },
    // Chakra default override
    body: {
      bg: 'bgPrimary',
      lineHeight: 'inherit'
    },
    button: {
      bg: 'transparent',
      border: 0,
      cursor: 'pointer',
      userSelect: 'none'
    },
    html: {
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      fontFeatureSettings: "'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on",
      fontSize: '16px',
      fontVariant: 'none'
    },
    'html, body': {
      margin: 0,
      padding: 0
    }
  }
}

export const MEDIA_WIDTHS = {
  '2xl': 1440,
  '3xl': 1616,
  '4xl': 1920,
  lg: 1280,
  md: 960,
  sm: 720,
  xl: 1366,
  // 500+1 so that breakpoint is hit before Chrome min-width; without
  // this, the UI is typically left in a weird state at exactly 500px
  xs: 501
}

const breakpoints = {
  '2xl': `${MEDIA_WIDTHS['2xl']}px`,
  '3xl': `${MEDIA_WIDTHS['3xl']}px`,
  '4xl': `${MEDIA_WIDTHS['4xl']}px`,
  lg: `${MEDIA_WIDTHS.lg}px`,
  md: `${MEDIA_WIDTHS.md}px`,
  sm: `${MEDIA_WIDTHS.sm}px`,
  xl: `${MEDIA_WIDTHS.xl}px`,
  xs: `${MEDIA_WIDTHS.xs}px`
}

const colors = {
  accent: {
    100: '#ebe9fe',
    200: '#ebe9fe',
    300: '#ebe9fe',
    400: '#c6bffb',
    500: '#8473ff',
    600: '#6754ef',
    700: '#4c3ad2',
    800: '#4c3ad2',
    900: '#4c3ad2'
  },
  avaxRed: '#e84142',
  badgeBlue: '#60a5fa',
  badgeGray: '#6b7280',
  badgePurple: '#8473ff',
  cozyRed: '#f24430',
  green: {
    100: '#d5f5c9',
    200: '#93ee73',
    300: '#6fd24c',
    400: '#5eb53f',
    500: '#54903e'
  },
  greenBill: '#00d37f',
  joeDark: {
    300: '#9295bf',
    400: '#474a66',
    500: '#2f3146',
    600: '#1f202e',
    700: '#181823'
  },
  joeLight: {
    300: '#fbfbff',
    400: '#f4f4ff',
    500: '#ececfe',
    600: '#d5d5fb',
    700: '#8c8cb1'
  },
  wavaxBlue: '#7e84ff',
  yellowBar: '#fad65e'
}

const components: ThemeComponents = {
  Button: buttonTheme,
  Checkbox: checkboxTheme,
  Divider: {
    baseStyle: { bg: 'border', borderColor: 'border' }
  },
  Drawer: drawerTheme,
  Heading: {
    baseStyle: {
      fontWeight: 'semibold'
    }
  },
  Input: inputTheme,
  Menu: menuTheme,
  Modal: modalTheme,
  Popover: popoverTheme,
  Progress: {
    baseStyle: {
      filledTrack: {
        bg: 'accent.500'
      }
    }
  },
  Skeleton: {
    baseStyle: { opacity: 0.3 }
  },
  Spinner: {
    baseStyle: { color: 'textSecondary' }
  },
  Switch: switchTheme,
  Tabs: tabsTheme,
  Text: {
    baseStyle: {
      color: 'textPrimary',
      fontWeight: 400,
      marginY: 0
    }
  },
  Textarea: {
    baseStyle: {
      _hover: {
        bg: 'hover'
      },
      fontFamily: fontStyle
    }
  },
  Tooltip: tooltipTheme
}

const semanticTokens = {
  colors: {
    bgCard: {
      _dark: 'joeDark.600',
      _light: 'white'
    },
    bgPrimary: {
      _dark: 'joeDark.700',
      _light: 'joeLight.300'
    },
    bgSecondary: {
      _dark: 'joeDark.600',
      _light: 'joeLight.400'
    },
    bgTertiary: {
      _dark: 'joeDark.500',
      _light: 'joeLight.500'
    },
    bgTransparent: {
      _dark: 'rgba(22, 22, 27, 0.85)',
      _light: 'rgba(250, 250, 255, 0.85)'
    },
    border: {
      _dark: '#2d2d3a',
      _light: '#ebebf5'
    },
    hover: {
      _dark: '#2d2d3a',
      _light: '#f2f2ff'
    },
    textPrimary: {
      _dark: 'white',
      _light: '#190037'
    },
    textSecondary: {
      _dark: '#9295bf',
      _light: '#8c8cb1'
    }
  }
}

const fonts = {
  body: fontStyle,
  heading: fontStyle,
  html: fontStyle
}

export const chakraTheme = extendTheme(
  withDefaultVariant({
    components: ['Button'],
    variant: 'primary'
  }),
  {
    breakpoints,
    colors,
    components,
    config,
    fonts,
    semanticTokens,
    styles
  }
)
