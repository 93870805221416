import { createReducer } from '@reduxjs/toolkit'
import { MakeOfferModalsProps } from 'components/MakeOfferModals'
import { WrapCurrencyModalProps } from 'components/WrapCurrencyModal'

import {
  ApplicationModal,
  closeModal,
  PopupContent,
  setMakeOfferModalArgs,
  setOpenModal,
  setWrapCurrencyModalArgs,
  updateBlockNumber
} from './actions'

type PopupList = Array<{
  content: PopupContent
  key: string
  removeAfterMs: number | null
  show: boolean
}>

export interface ApplicationState {
  readonly blockNumber: { readonly [chainId: number]: number }
  readonly openModals: ApplicationModal[]
  readonly popupList: PopupList
  readonly makeOfferModalProps?: MakeOfferModalsProps
  readonly wrapCurrencyModalProps?: WrapCurrencyModalProps
}

const initialState: ApplicationState = {
  blockNumber: {},
  openModals: [],
  popupList: []
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateBlockNumber, (state, action) => {
      const { blockNumber, chainId } = action.payload
      if (typeof state.blockNumber[chainId] !== 'number') {
        state.blockNumber[chainId] = blockNumber
      } else {
        state.blockNumber[chainId] = Math.max(
          blockNumber,
          state.blockNumber[chainId]
        )
      }
    })
    .addCase(setOpenModal, (state, action) => {
      state.openModals = !state.openModals.includes(action.payload)
        ? [...state.openModals, action.payload]
        : state.openModals.filter((modal) => modal !== action.payload)
    })
    .addCase(closeModal, (state, action) => {
      state.openModals = state.openModals.filter(
        (modal) => modal !== action.payload
      )
    })
    .addCase(setMakeOfferModalArgs, (state, action) => {
      state.makeOfferModalProps = action.payload
    })
    .addCase(setWrapCurrencyModalArgs, (state, action) => {
      state.wrapCurrencyModalProps = action.payload
    })
)
