import { ChainId } from '@traderjoe-xyz/joepegs-sdk'
import { supportedChains } from 'constants/chains'
import { intervalToDuration } from 'date-fns'
import Numeral from 'numeral'
import { Chain, ChainQueryParam } from 'types/joebarn'
import { formatUnits, getAddress, isAddress, parseUnits } from 'viem'

export const MAX_UINT_128 = BigInt('0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF')
export const MAX_UINT_256 = BigInt(2) ** BigInt(256) - BigInt(1)

const toFixed = (value: string, decimals: number): `${number}` => {
  return parseFloat(value).toFixed(decimals) as `${number}`
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export const shortenAddress = (address: string, chars = 4): string => {
  const parsed = isAddress(address) ? getAddress(address) : undefined
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

export const shortenTransactionHash = (hash: string, chars = 4): string => {
  return `${hash.substring(0, chars + 2)}...${hash.substring(
    hash.length - chars
  )}`
}

export const getExplorerLink = (
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block',
  chainId: ChainId
): string | undefined => {
  const explorerBaseUrl = supportedChains.find((chain) => chain.id === chainId)
    ?.blockExplorers?.default.url
  switch (type) {
    case 'transaction': {
      return `${explorerBaseUrl}/tx/${data}`
    }
    case 'token': {
      return `${explorerBaseUrl}/token/${data}`
    }
    case 'block': {
      return `${explorerBaseUrl}/blocks/${data}`
    }
    case 'address':
    default: {
      return `${explorerBaseUrl}/address/${data}`
    }
  }
}

export const escapeRegExp = (string: string): string =>
  string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string

export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1)

// Vision Formatting
const toK = (num: string): string => {
  return Numeral(num).format('0.[00]a')
}

// using a currency library here in case we want to add more in future
const priceFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  minimumFractionDigits: 2,
  style: 'currency'
})

export function formattedPercent(percent: number, places = 5): string {
  if (!percent || percent === 0) {
    return '0%'
  }
  if (percent < 0.0001 && percent > 0) {
    return '< 0.01%'
  }
  if (percent < 0 && percent > -0.0001) {
    return '< 0.01%'
  }
  const fixedPercent = percent.toFixed(places).replace(/\.00$/, '')
  if (fixedPercent === '0.00' && percent > 0) {
    return '< 0.01%'
  }
  if (fixedPercent === '0.00') {
    return '0%'
  }
  if (Number(fixedPercent) > 0) {
    if (Number(fixedPercent) > 100) {
      return `${percent?.toFixed(0).toLocaleString()}%`
    } else {
      return `${fixedPercent}%`
    }
  } else {
    return `${fixedPercent}%`
  }
}

export const formattedNum = ({
  allowSmallDecimals = false,
  chain,
  number,
  places = 5,
  usd = false
}: {
  number: number | string | undefined | null
  allowSmallDecimals?: boolean
  chain?: Chain
  places?: number
  usd?: boolean
}): string => {
  if (chain === ChainQueryParam.AVALANCHE) {
    places = 2
  }

  if (
    (typeof number === 'number' && isNaN(number)) ||
    number === '' ||
    number === undefined ||
    number === null
  ) {
    return usd ? '$0.00' : '0'
  }
  const num = typeof number === 'string' ? parseFloat(number) : number

  if (num > 500000000) {
    return (usd ? '$' : '') + toK(num.toFixed(0))
  }

  if (num === 0) {
    return usd ? '$0' : '0'
  }

  if (num < 0.0001 && num > 0) {
    if (allowSmallDecimals) {
      return parseFloat(String(num)).toFixed(places)
    } else {
      return usd ? '< $0.01' : places === 0 ? '< 0.01' : '< 0.0001'
    }
  }

  if (num < 0.01 && num > 0 && !usd) {
    if (allowSmallDecimals) {
      return parseFloat(String(num)).toFixed(places)
    } else {
      return places === 0 ? '< 1' : '< 0.01'
    }
  }

  if (num < 0.01 && num > 0 && !usd) {
    return places === 0 ? '< 1' : '< 0.01'
  }

  if (num > 1000) {
    return usd
      ? '$' + Number(parseFloat(String(num)).toFixed(0)).toLocaleString()
      : '' + Number(parseFloat(String(num)).toFixed(0)).toLocaleString()
  }

  if (usd) {
    if (num < 0.1) {
      return '$' + Number(parseFloat(String(num)).toFixed(4))
    } else {
      const usdString = priceFormatter.format(num)
      return '$' + usdString.slice(1, usdString.length)
    }
  }

  return parseFloat(String(num)).toFixed(places)
}

export const formatFromBalance = (
  value: bigint | undefined,
  decimals = 18
): string => {
  if (value) {
    return formatUnits(value, decimals)
  } else {
    return ''
  }
}

export const formatToBalance = (
  value: string | undefined,
  decimals = 18
): {
  decimals: number
  value: bigint
} => {
  if (value) {
    return {
      decimals: decimals,
      value: parseUnits(toFixed(value, decimals), decimals)
    }
  } else {
    return { decimals: decimals, value: BigInt(0) }
  }
}

export const formatBalance = (
  value: bigint,
  decimals = 18,
  maxFraction = 0,
  chain?: Chain
): string => {
  if (chain === ChainQueryParam.AVALANCHE) {
    maxFraction = 2
  }
  const formatted = formatUnits(value, decimals)
  if (maxFraction > 0) {
    const split = formatted.split('.')
    if (split.length > 1) {
      return split[0] + '.' + split[1].substr(0, maxFraction)
    }
  }
  return formatted
}

export const getDisplayDateFromTimestamp = (
  timestamp: number | string
): string => {
  return new Date(Number(timestamp) * 1000).toLocaleDateString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  })
}

interface FormattedPrice {
  native: string
  usd?: string
}

export const formatPrice = (
  nativeAmount: bigint,
  nativeUsdPrice?: number,
  nativeMaxFractions = 5
): FormattedPrice => {
  const normalizedPriceNative = formatFromBalance(nativeAmount, 18)
  const fmtPriceNative = formatBalance(nativeAmount, 18, nativeMaxFractions)
  const fmtPriceUsd = nativeUsdPrice
    ? formattedNum({
        number: nativeUsdPrice * parseFloat(normalizedPriceNative),
        usd: true
      })
    : undefined
  return { native: fmtPriceNative, usd: fmtPriceUsd }
}

export const getCountdownFromTimestamp = (
  timestamp: number,
  includesSeconds = true
): string => {
  const duration = intervalToDuration({
    end: timestamp * 1000,
    start: new Date()
  })
  let fmtDuration = ''
  if (duration.years) {
    fmtDuration += `${duration.years}y `
  }
  if (duration.months) {
    fmtDuration += `${duration.months}months `
  }
  if (duration.days) {
    fmtDuration += `${duration.days}d `
  }
  if (duration.hours) {
    fmtDuration += `${duration.hours}h `
  }
  if (duration.minutes) {
    fmtDuration += `${duration.minutes}m `
  }
  if (duration.seconds && includesSeconds) {
    fmtDuration += `${duration.seconds}s`
  }
  return fmtDuration
}
