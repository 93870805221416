import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader
} from '@chakra-ui/react'
import { CloseButton } from 'components/HeaderButtons'
import WalletBalance from 'components/WalletBalance'
import WalletMenuHeader from 'components/WalletMenu/WalletMenuHeader'
import WalletMenuLinks from 'components/WalletMenu/WalletMenuLinks'
import React from 'react'

interface WalletMobileMenuProps {
  isOpen: boolean
  onClose: () => void
}

const WalletMobileMenu = ({
  isOpen,
  onClose
}: WalletMobileMenuProps): JSX.Element => (
  <Drawer isOpen={isOpen} onClose={onClose} size="full">
    <DrawerContent bg="bgSecondary">
      <DrawerHeader
        display="flex"
        justifyContent="flex-end"
        border="none"
        pb={2}
      >
        <CloseButton onClick={onClose} />
      </DrawerHeader>
      <DrawerBody pt={0}>
        <WalletMenuHeader onDisconnect={onClose} />
        <Divider my="1.5rem" />
        <WalletMenuLinks onClose={onClose} />
        <Divider my="1.5rem" />
        <WalletBalance onMobileSwapAvaxClick={onClose} />
      </DrawerBody>
    </DrawerContent>
  </Drawer>
)

export default WalletMobileMenu
