import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
  braveWallet,
  coinbaseWallet,
  coreWallet,
  injectedWallet,
  metaMaskWallet,
  rabbyWallet,
  walletConnectWallet
} from '@rainbow-me/rainbowkit/wallets'
import { ChainId } from '@traderjoe-xyz/joepegs-sdk'
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc'
import { publicProvider } from '@wagmi/core/providers/public'
import { supportedChains } from 'constants/chains'
import { createConfig } from 'wagmi'
import { configureChains } from 'wagmi'

const poktPortalId = process.env.REACT_APP_POKT_PORTAL_ID ?? ''
const walletConnectProjectId =
  process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? ''

const { publicClient } = configureChains(supportedChains, [
  jsonRpcProvider({
    rpc: (chain) => {
      switch (chain.id) {
        case ChainId.AVALANCHE:
          return {
            http: `https://avax-mainnet.rpc.grove.city/v1/${poktPortalId}`
          }
        default:
          return null
      }
    }
  }),
  publicProvider()
])

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet({
        chains: supportedChains,
        projectId: walletConnectProjectId
      }),
      coreWallet({
        chains: supportedChains,
        projectId: walletConnectProjectId
      }),
      coinbaseWallet({ appName: 'Joepegs', chains: supportedChains }),
      walletConnectWallet({
        chains: supportedChains,
        projectId: walletConnectProjectId
      }),
      rabbyWallet({ chains: supportedChains }),
      braveWallet({ chains: supportedChains }),
      injectedWallet({ chains: supportedChains })
    ]
  }
])

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})
