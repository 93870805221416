import { useEffect } from 'react'
import { useQueryClient } from 'wagmi'

const CACHE_VERSION = 2

const useMigrateWagmiQueryClientIfNeeded = () => {
  const wagmiQueryClient = useQueryClient()

  useEffect(() => {
    const version = Number(localStorage.getItem('cache.version') ?? '0')
    if (version < CACHE_VERSION) {
      wagmiQueryClient.getQueryCache().clear()
    }
  }, [wagmiQueryClient])
}

export default useMigrateWagmiQueryClientIfNeeded
