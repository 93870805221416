import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
  control: {
    _checked: {
      _hover: {
        bg: 'accent.600',
        borderColor: 'accent.600'
      },
      bg: 'accent.500',
      borderColor: 'accent.500'
    }
  }
})

export const checkboxTheme = defineStyleConfig({ baseStyle })
