import { Center, Spinner } from '@chakra-ui/react'
import React from 'react'

const LoaderFullScreen = (): JSX.Element => (
  <Center h="calc(100vh - 6rem)" w="100vw">
    <Spinner size="xl" />
  </Center>
)

export default LoaderFullScreen
