import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  Input
} from '@chakra-ui/react'
import { CloseButton } from 'components/HeaderButtons'
import { SEARCH_QUERY_MIN_LENGTH } from 'constants/index'
import { useSearch } from 'hooks/useJoebarn'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecentSearches } from 'state/search/hooks'
import { SearchableCollection } from 'state/search/reducer'
import { ItemDetail, Profile } from 'types/joebarn'

import SearchResults from './SearchResults'

interface MobileSearchPanelProps {
  isOpen: boolean
  onClose: () => void
}

const MobileSearchPanel = ({
  isOpen,
  onClose
}: MobileSearchPanelProps): JSX.Element => {
  const navigate = useNavigate()
  const { addRecentSearch, clearRecentSearch, recentCollections } =
    useRecentSearches()
  const [query, setQuery] = useState('')
  const { data: results, isLoading: loading } = useSearch(query)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const onCollectionClick = (collection: SearchableCollection) => {
    onClose()
    addRecentSearch(collection)
    navigate(
      `/collections/${collection.chain}/${
        collection.slugName ?? collection.address
      }`
    )
  }

  const onItemClick = (itemDetail: ItemDetail) => {
    onClose()
    navigate(
      `/item/${itemDetail.chain}/${itemDetail.collection}/${itemDetail.tokenId}`
    )
  }

  const onProfileClick = (profile: Profile) => {
    onClose()
    navigate(`/profile/${profile.address}`)
  }

  useEffect(() => {
    searchInputRef.current?.focus()
  }, [])

  // clear search results when closing drawer
  useEffect(() => {
    if (!isOpen) {
      setQuery('')
    }
  }, [isOpen])

  return (
    <Drawer variant="unstyled" isOpen={isOpen} onClose={onClose} size="full">
      <DrawerContent p={0} bg="bgPrimary">
        <DrawerHeader p={0}>
          <Flex
            justify="space-between"
            align="center"
            px="1rem"
            gap="1rem"
            h="4.5rem"
            bg="bgSecondary"
            borderBottom="1px solid"
            borderBottomColor="border"
          >
            <Input
              variant="unstyled"
              h="full"
              minH="40px"
              w="full"
              background="transparent"
              border={0}
              borderRadius={0}
              fontSize="16px"
              fontWeight="semibold"
              color="textPrimary"
              _focus={{ outline: 'none' }}
              ref={searchInputRef}
              placeholder="Search by collections"
              onChange={(e) => setQuery(e.target.value)}
            />
            <CloseButton onClick={onClose} />
          </Flex>
        </DrawerHeader>
        <DrawerBody p={0}>
          {query.length >= SEARCH_QUERY_MIN_LENGTH ||
          recentCollections.length > 0 ? (
            <SearchResults
              recentCollections={recentCollections}
              collections={results?.collections || []}
              items={results?.items || []}
              loading={loading}
              onCollectionClick={onCollectionClick}
              onItemClick={onItemClick}
              profiles={results?.profiles || []}
              onProfileClick={onProfileClick}
              onClearRecentCollectionClick={clearRecentSearch}
              query={query}
            />
          ) : null}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default MobileSearchPanel
