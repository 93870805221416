import { configureStore } from '@reduxjs/toolkit'
import { load, save } from 'redux-localstorage-simple'

import application from './application/reducer'
import authentication from './authentication/reducer'
import { updateVersion } from './global/actions'
import search from './search/reducer'
import shoppingCart from './shoppingCart/reducer'
import transactions from './transactions/reducer'
import user from './user/reducer'

const PERSISTED_KEYS: string[] = [
  'user',
  'transactions',
  'lists',
  'shoppingCart',
  'authentication',
  'search'
]

const store = configureStore({
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false, thunk: false }),
    save({ states: PERSISTED_KEYS })
  ],
  preloadedState: load({ states: PERSISTED_KEYS }),
  reducer: {
    application,
    authentication,
    search,
    shoppingCart,
    transactions,
    user
  }
})

store.dispatch(updateVersion())

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
