import { ChainId } from '@traderjoe-xyz/joepegs-sdk'
import { isTestnet, supportedChains } from 'constants/chains'
import { Chain } from 'types/joebarn'

export const convertStringToChain = (chain: string): Chain | undefined => {
  if (['avalanche'].includes(chain)) {
    return chain as Chain
  }
  return undefined
}

export const getChainName = (chain: Chain): string => {
  switch (chain) {
    case 'avalanche':
      return isTestnet ? 'Avalanche Fuji' : 'Avalanche'
  }
}

export const convertChainToChainId = (chain: Chain): ChainId => {
  switch (chain) {
    case 'avalanche':
      return isTestnet ? ChainId.FUJI : ChainId.AVALANCHE
  }
}

export const convertChainIdToChain = (chainId: ChainId): Chain => {
  switch (chainId) {
    case ChainId.AVALANCHE:
    case ChainId.FUJI:
      return 'avalanche'
    default:
      return 'avalanche'
  }
}

export const getChainSlug = (chainId: ChainId): string | undefined => {
  return supportedChains.find((chain) => chain.id === chainId)?.slug
}
