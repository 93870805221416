import { Box, useOutsideClick } from '@chakra-ui/react'
import { JoeText } from 'components/JoeText'
import { SearchPanel } from 'components/SearchPanel'
import { SEARCH_QUERY_MIN_LENGTH } from 'constants/index'
import { useSearch } from 'hooks/useJoebarn'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { isMacOs, isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { useRecentSearches } from 'state/search/hooks'
import { SearchableCollection } from 'state/search/reducer'
import { ItemDetail, Profile } from 'types/joebarn'

import SearchResults from './SearchResults'

const HeaderSearchPanel = (): JSX.Element => {
  const navigate = useNavigate()
  const { addRecentSearch, clearRecentSearch, recentCollections } =
    useRecentSearches()
  const [query, setQuery] = useState('')
  const [focused, setFocused] = useState(false)
  const { data: results, isLoading: loading } = useSearch(query)
  const { collections, items, profiles } = results || {
    collections: [],
    items: [],
    profiles: []
  }
  const [cursor, setCursor] = useState<number>(0)
  const hotkeyText = !focused ? (isMacOs ? '⌘ K' : 'Ctrl + K') : 'Esc'
  const isSearchResultsTwoColumn = window.innerWidth > 1255
  const isResultsEmpty =
    collections.length === 0 &&
    items.length === 0 &&
    profiles.length == 0 &&
    recentCollections.length === 0

  const reset = useCallback(() => {
    setFocused(false)
    setQuery('')
    setCursor(0)
  }, [])

  const searchInputRef = useRef<HTMLInputElement>(null)
  const searchResultContainerRef = useRef<HTMLDivElement>(null)
  const searchPanelRef = useRef<HTMLDivElement>(null)
  useOutsideClick({
    handler: reset,
    ref: searchPanelRef
  })

  const onCollectionClick = useCallback(
    (collection: SearchableCollection) => {
      reset()
      addRecentSearch(collection)
      navigate(
        `/collections/${collection.chain}/${
          collection.slugName ?? collection.address
        }`
      )
    },
    [navigate, reset, addRecentSearch]
  )

  const onItemClick = useCallback(
    (itemDetail: ItemDetail) => {
      reset()
      navigate(
        `/item/${itemDetail.chain}/${itemDetail.collection}/${itemDetail.tokenId}`
      )
    },
    [navigate, reset]
  )

  const onProfileClick = useCallback(
    (profile: Profile) => {
      reset()
      navigate(`/profile/${profile.address}`)
    },
    [navigate, reset]
  )

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const showingRecentSearches =
        recentCollections.length > 0 && query.length < SEARCH_QUERY_MIN_LENGTH

      const numResults = showingRecentSearches
        ? recentCollections.length
        : collections.length + items.length + profiles.length

      // CMD/CTRL + K pressed
      if ((event.metaKey || (event.ctrlKey && !isMacOs)) && event.key === 'k') {
        if (!focused) {
          event.preventDefault()
          searchInputRef.current?.focus()
          setFocused(true)
        }
      } else if (event.key === 'Escape') {
        if (focused) {
          event.preventDefault()
          reset()
          searchInputRef.current?.blur()
        }
      } else if (event.key === 'ArrowDown' && focused && !isResultsEmpty) {
        event.preventDefault()
        if (cursor < numResults - 1) {
          if (isSearchResultsTwoColumn && cursor < numResults - 2) {
            setCursor(cursor + 2)
          } else {
            setCursor(cursor + 1)
          }
        }
      } else if (event.key === 'ArrowUp' && focused && !isResultsEmpty) {
        event.preventDefault()
        if (cursor > 0) {
          if (isSearchResultsTwoColumn && cursor > 1) {
            setCursor(cursor - 2)
          } else {
            setCursor(cursor - 1)
          }
        }
      } else if (event.key === 'ArrowRight' && focused && !isResultsEmpty) {
        event.preventDefault()
        if (isSearchResultsTwoColumn && cursor < numResults - 1) {
          setCursor(cursor + 1)
        }
      } else if (event.key === 'ArrowLeft' && focused && !isResultsEmpty) {
        event.preventDefault()
        if (isSearchResultsTwoColumn && cursor > 0) {
          setCursor(cursor - 1)
        }
      } else if (event.key === 'Tab' && focused && !isResultsEmpty) {
        event.preventDefault()
        if (collections.length > 0 && items.length > 0 && profiles.length > 0) {
          if (cursor < collections.length) {
            setCursor(collections.length)
          } else if (
            cursor >= collections.length &&
            cursor < collections.length + items.length
          ) {
            setCursor(collections.length + items.length)
          } else {
            setCursor(0)
          }
        }
      } else if (
        event.key === 'Enter' &&
        focused &&
        !isMobile &&
        !isResultsEmpty
      ) {
        event.preventDefault()
        if (showingRecentSearches && cursor < recentCollections.length) {
          onCollectionClick(recentCollections[cursor])
          searchInputRef.current?.blur()
        } else if (cursor < collections.length) {
          onCollectionClick(collections[cursor])
          searchInputRef.current?.blur()
        } else if (
          items.length > 0 &&
          cursor >= collections.length &&
          cursor < collections.length + items.length
        ) {
          onItemClick(items[cursor - collections.length])
          searchInputRef.current?.blur()
        } else if (
          profiles.length > 0 &&
          cursor >= collections.length + items.length
        ) {
          onProfileClick(profiles[cursor - collections.length - items.length])
          searchInputRef.current?.blur()
        }
      }
    },
    [
      focused,
      reset,
      isResultsEmpty,
      collections,
      items,
      cursor,
      onCollectionClick,
      onItemClick,
      isSearchResultsTwoColumn,
      profiles,
      onProfileClick,
      query.length,
      recentCollections
    ]
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [onKeyDown])

  return (
    <SearchPanel
      placeholder="Search by collections"
      hasRecentSearches={recentCollections.length > 0}
      query={query}
      setQuery={setQuery}
      focused={focused}
      setFocused={setFocused}
      queryMinLength={SEARCH_QUERY_MIN_LENGTH}
      searchResults={
        <SearchResults
          recentCollections={recentCollections}
          collections={collections}
          items={items}
          loading={loading}
          onCollectionClick={onCollectionClick}
          onItemClick={onItemClick}
          cursor={cursor}
          profiles={profiles}
          onProfileClick={onProfileClick}
          query={query}
          onClearRecentCollectionClick={clearRecentSearch}
        />
      }
      extraElement={
        !isMobile ? (
          <Box
            pos="absolute"
            right="1rem"
            top="50%"
            transform="translateY(-50%)"
          >
            <JoeText color="textSecondary" fontSize={14}>
              {hotkeyText}
            </JoeText>
          </Box>
        ) : undefined
      }
      searchPanelRef={searchPanelRef}
      searchResultContainerRef={searchResultContainerRef}
      searchInputRef={searchInputRef}
    />
  )
}

export default HeaderSearchPanel
