import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  body: {
    py: { base: '1rem', sm: '2rem' }
  },
  closeButton: {
    color: 'textPrimary',
    top: '10px'
  },
  dialog: {
    bg: 'bgPrimary',
    borderBottomRadius: { base: 0, sm: '2xl' },
    borderRadius: '2xl',
    mb: { base: 0, sm: '16px' },
    mt: { base: 0, sm: '16px' }
  },
  dialogContainer: {
    '@supports(height: -webkit-fill-available)': {},
    alignItems: { base: 'flex-end', sm: 'center' },
    bottom: { base: 0, sm: 'unset' },
    left: 0,
    right: { base: 0, sm: 'unset' },
    top: { base: 'auto', sm: 0 }
  },
  footer: {
    pb: { base: '1rem', sm: '2rem' }
  },
  header: {
    borderBottom: '1px solid',
    borderBottomColor: 'border',
    fontColor: 'textPrimary',
    fontSize: '16px',
    fontWeight: 'semibold'
  },
  overlay: {
    backdropFilter: 'blur(8px)'
  }
})

export const modalTheme = defineMultiStyleConfig({
  baseStyle
})
