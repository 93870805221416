import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'state'

import { addRecentCollection, removeRecentCollection } from './actions'
import { SearchableCollection } from './reducer'

export const useRecentSearches = () => {
  const dispatch = useDispatch()
  const searchState = useSelector<AppState, AppState['search']>(
    (state) => state.search
  )

  const addRecentSearch = useCallback(
    (collection: SearchableCollection) => {
      dispatch(addRecentCollection({ collection }))
    },
    [dispatch]
  )

  const clearRecentSearch = useCallback(
    (collection: SearchableCollection) => {
      dispatch(removeRecentCollection({ collection }))
    },
    [dispatch]
  )

  return {
    addRecentSearch,
    clearRecentSearch,
    recentCollections: searchState.recentCollections
  }
}
