import { useAuthMessage, useLogin, useRefreshToken } from 'hooks/useJoebarn'
import jwtDecode from 'jwt-decode'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'state'
import { useAddErrorPopup } from 'state/application/hooks'
import { AuthTokens } from 'types/joebarn'
import { useAccount, useSignMessage } from 'wagmi'

import { clearTokens, updateTokens } from './actions'

// get auth tokens
export const useGetAuthTokens = (): AuthTokens | undefined =>
  useSelector((state: AppState) => state.authentication.tokens)

// update auth tokens
export const useUpdateAuthTokens = (): ((tokens: AuthTokens) => void) => {
  const dispatch = useDispatch()
  return useCallback(
    (tokens: AuthTokens) => {
      dispatch(updateTokens({ tokens }))
    },
    [dispatch]
  )
}

// clear auth tokens
export const useClearAuthTokens = (): (() => void) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearTokens())
  }, [dispatch])
}

// returns true if the auth tokens are still valid
const getIsAuthTokenValid = (tokens: AuthTokens, account: string): boolean => {
  const decodedAccessToken = jwtDecode<{ exp: number; sub: string }>(
    tokens.accessToken
  )
  const now = new Date()
  const expiresDate = new Date(decodedAccessToken.exp * 1000)
  return (
    decodedAccessToken.sub.toLowerCase() === account.toLowerCase() &&
    now < expiresDate
  )
}

// verify that the access token is still valid and try to refresh it if it expired
// returns true if the local access token is valid, else false
const useRefreshTokenIfNeeded = () => {
  const updateTokens = useUpdateAuthTokens()
  const refreshAccessToken = useRefreshToken()
  const clearTokens = useClearAuthTokens()

  return useCallback(
    async (
      address: string,
      tokens: AuthTokens
    ): Promise<AuthTokens | undefined> => {
      const decodedAccessToken = jwtDecode<{ exp: number; sub: string }>(
        tokens.accessToken
      )
      const now = new Date()
      const expiresDate = new Date(decodedAccessToken.exp * 1000)

      // the token we have is not for the currently connected wallet
      if (decodedAccessToken.sub.toLowerCase() !== address.toLowerCase()) {
        clearTokens()
        return undefined
      }

      // access token has expired
      if (now >= expiresDate) {
        if (!tokens.refreshToken) {
          return undefined
        }
        const decodedRefreshToken = jwtDecode<{ exp: number; sub: string }>(
          tokens.refreshToken
        )
        const refreshTokenExpiresDate = new Date(decodedRefreshToken.exp * 1000)
        if (now >= refreshTokenExpiresDate) {
          // refresh token has expired
          return undefined
        }
        try {
          const newTokens = await refreshAccessToken(tokens.refreshToken)
          updateTokens(newTokens)
          return newTokens
        } catch (err) {
          // refresh token didn't work, we'll ask for a new signature
          console.error(err)
          return undefined
        }
      }

      // token still valid
      return tokens
    },
    [clearTokens, refreshAccessToken, updateTokens]
  )
}

// login the user when we don't have a valid access token already
export const useLoginIfNeeded = (): {
  isLoggedIn: boolean
  loginIfNeeded: (address: string) => Promise<AuthTokens | undefined>
} => {
  const { signMessageAsync } = useSignMessage()
  const addErrorPopup = useAddErrorPopup()

  const { address: account } = useAccount()
  const login = useLogin()
  const getAuthMessage = useAuthMessage()
  const refreshAccessTokenIfNeeded = useRefreshTokenIfNeeded()

  const tokens = useGetAuthTokens()
  const updateTokens = useUpdateAuthTokens()

  const isLoggedIn = useMemo((): boolean => {
    if (!tokens || !account) return false
    return getIsAuthTokenValid(tokens, account)
  }, [tokens, account])

  const loginIfNeeded = useCallback(
    async (address: string): Promise<AuthTokens | undefined> => {
      if (!signMessageAsync) {
        return Promise.reject("Can't sign message")
      }
      if (tokens) {
        const authTokens = await refreshAccessTokenIfNeeded(address, tokens)
        if (authTokens) {
          return authTokens
        }
      }
      try {
        const message = (await getAuthMessage(address)).message
        const signature = await signMessageAsync({ message })
        const newTokens = await login(address, signature, message)
        updateTokens(newTokens)
        return newTokens
      } catch (err) {
        console.error(err)
        addErrorPopup('Unable to authenticate your account')
        return Promise.reject(err)
      }
    },
    [
      tokens,
      getAuthMessage,
      login,
      refreshAccessTokenIfNeeded,
      updateTokens,
      addErrorPopup,
      signMessageAsync
    ]
  )

  return { isLoggedIn, loginIfNeeded }
}
