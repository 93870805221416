import { Flex, Link } from '@chakra-ui/react'
import { JoeText } from 'components/JoeText'
import { supportedChains } from 'constants/chains'
import React from 'react'
import { PopupContent } from 'state/application/actions'
import { getExplorerLink } from 'utils'

const Popup = ({
  chainId,
  hash,
  state,
  summary
}: PopupContent): JSX.Element => {
  const blockExplorer = supportedChains.find((chain) => chain.id === chainId)
    ?.blockExplorers?.default
  return (
    <Flex w="full" justify="space-between" gap="0.25rem" flexWrap="wrap">
      {summary ? (
        state !== 'loading' ? (
          <JoeText
            color="white"
            fontWeight="regular"
            fontSize={14}
            overflow="hidden"
          >
            {summary}
          </JoeText>
        ) : (
          <JoeText variant="body" overflow="hidden">
            {summary}
          </JoeText>
        )
      ) : null}
      {hash && chainId ? (
        <Link isExternal href={getExplorerLink(hash, 'transaction', chainId)}>
          {state !== 'loading' ? (
            <JoeText color="white" fontSize={14} textDecoration="underline">
              {`View on ${blockExplorer?.name}`}
            </JoeText>
          ) : (
            <JoeText variant="body" textDecoration="underline">
              {`View on ${blockExplorer?.name}`}
            </JoeText>
          )}
        </Link>
      ) : null}
    </Flex>
  )
}

export default Popup
