import { Flex, Icon, IconButton, Image } from '@chakra-ui/react'
import { ReactComponent as LogoutLogo } from 'assets/images/logout-icon.svg'
import Identicon from 'components/Identicon'
import { JoeText } from 'components/JoeText'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { useProfile } from 'hooks/useJoebarn'
import React from 'react'
import { shortenAddress } from 'utils'
import { getOptimizedImageUrl } from 'utils/imageOptimizer'
import { useAccount, useDisconnect } from 'wagmi'

interface WalletMenuHeaderProps {
  onDisconnect: () => void
}

const WalletMenuHeader = ({
  onDisconnect
}: WalletMenuHeaderProps): JSX.Element | null => {
  const { address: account } = useAccount()
  const { disconnect } = useDisconnect()
  const { data: profile } = useProfile(account)

  const [, setCopied] = useCopyClipboard()

  return account ? (
    <Flex px={{ base: 0, md: 4 }} align="center" gap={4}>
      {profile?.pfpUrl ? (
        <Image
          src={getOptimizedImageUrl(profile.pfpUrl, 'logo-sm')}
          crossOrigin=""
          referrerPolicy="origin"
          alt="Profile picture"
          boxSize={12}
          borderRadius="full"
          objectFit="contain"
        />
      ) : (
        <Identicon boxSize={12} />
      )}
      <Flex justify="space-between" align="center" w="full">
        <Flex flexDir="column" gap={1}>
          <JoeText color="textSecondary" fontSize="12px">
            Connected Address
          </JoeText>
          <Flex w="full" align="center" justify="space-between">
            <TouchFriendlyTooltip label="Copy">
              <JoeText
                fontWeight="semibold"
                fontSize="20px"
                lineHeight="24px"
                cursor="pointer"
                onClick={() => setCopied(account)}
              >
                {shortenAddress(account)}
              </JoeText>
            </TouchFriendlyTooltip>
          </Flex>
        </Flex>
        <IconButton
          variant="ghost"
          aria-label="logout"
          boxSize={12}
          mr={-2}
          icon={<Icon as={LogoutLogo} fill="red.500" />}
          onClick={() => {
            disconnect()
            onDisconnect()
          }}
        />
      </Flex>
    </Flex>
  ) : null
}

export default WalletMenuHeader
