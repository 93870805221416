import { Icon, IconProps } from '@chakra-ui/react'
import { ReactComponent as TRUSTED_LOGO } from 'assets/illustrations/svg/trusted.svg'
import { ReactComponent as VERIFIED_LOGO } from 'assets/illustrations/svg/verified.svg'
import React from 'react'
import { CollectionVerificationStatus } from 'types/joebarn'

interface VerifiedBadgeInterface {
  verificationStatus?: CollectionVerificationStatus
}

const VerifiedBadge = ({
  verificationStatus,
  ...props
}: VerifiedBadgeInterface & IconProps): JSX.Element | null =>
  verificationStatus === CollectionVerificationStatus.Verified ? (
    <Icon
      as={VERIFIED_LOGO}
      boxSize={{ base: '16px', sm: '24px' }}
      {...props}
    />
  ) : verificationStatus === CollectionVerificationStatus.Trusted ? (
    <Icon as={TRUSTED_LOGO} boxSize={{ base: '16px', sm: '24px' }} {...props} />
  ) : null

export default VerifiedBadge
