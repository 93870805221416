import { Flex, Image, Spinner } from '@chakra-ui/react'
import FileIcon from 'assets/images/file.svg'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import { JoeText } from '../JoeText'

export interface Image {
  file: File
  name: string
}

interface Props {
  maxSizeInMb: number
  onChangeImage: (image: Image) => void
  disabled?: boolean
  image?: string | null | undefined
  loading?: boolean
}

export const ImageDropzone = ({
  disabled = false,
  image,
  loading = false,
  maxSizeInMb,
  onChangeImage
}: Props): JSX.Element => {
  const onDrop = useCallback(
    (files: File[]) => {
      if (files.length !== 1) {
        return
      }

      const file = files[0]
      const reader = new FileReader()
      reader.onload = () => onChangeImage({ file, name: file.name })
      reader.readAsDataURL(file)
    },
    [onChangeImage]
  )

  const { getInputProps, getRootProps } = useDropzone({
    accept: {
      'image/gif': ['.gif'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png']
    },
    maxFiles: 1,
    maxSize: maxSizeInMb * 1000000,
    multiple: false,
    onDrop
  })

  const rootProps = disabled ? {} : getRootProps()

  return (
    <Flex
      flexDir="column"
      rowGap={4}
      justifyContent="center"
      alignItems="center"
      padding={image ? 'auto' : '5rem 4.25rem'}
      border={loading || !image ? '2px dashed' : 'none'}
      borderRadius="2rem"
      borderColor="border"
      width="100%"
      overflow="hidden"
      cursor="pointer"
      _hover={{
        background: 'bgTertiary'
      }}
      {...rootProps}
    >
      {!disabled && <input {...getInputProps()} />}
      {image &&
        (loading ? (
          <Flex alignItems="center" justifyContent="center" w="100%" h="225px">
            <Spinner />
          </Flex>
        ) : (
          <Flex w="100%" h="225px">
            <Image objectFit="cover" width="100%" height="100%" src={image} />
          </Flex>
        ))}
      {!image && (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <Flex justify="center" align="center" w="100%" maxHeight="225px">
              <Image width="32px" height="40px" src={FileIcon} alt="file" />
            </Flex>
          )}
          <Flex flexDir="column" rowGap="0.5rem">
            <JoeText variant="secondary" fontSize={12} textAlign="center">
              Supported file types:
            </JoeText>
            <JoeText
              variant="primary"
              fontSize={12}
              textAlign="center"
              fontWeight="semibold"
            >
              PNG, JPG, JPEG, GIF
            </JoeText>
          </Flex>
          <Flex flexDir="column" rowGap={1}>
            <JoeText variant="secondary" fontSize={12} textAlign="center">
              Maximum file size:
            </JoeText>
            <JoeText
              variant="primary"
              fontSize={12}
              textAlign="center"
              fontWeight="semibold"
            >
              {`${maxSizeInMb}MB`}
            </JoeText>
          </Flex>
        </>
      )}
    </Flex>
  )
}
