import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

import { fontStyle } from './fonts'

const lg = defineStyle({
  fontSize: '16px',
  h: { base: '48px', md: '54px' }
})

const md = defineStyle({
  fontSize: '16px',
  h: { base: '44px', md: '48px' }
})

const baseStyle = defineStyle({
  borderRadius: { base: '12px', md: '16px' },
  fontFamily: fontStyle
})

const primary = defineStyle({
  _active: {
    background: 'accent.600'
  },
  _hover: {
    _disabled: {
      background: 'accent.500'
    },
    background: 'accent.600'
  },
  background: 'accent.500',
  border: '0px',
  color: 'white'
})

const outline = defineStyle({
  _active: {
    background: 'black',
    borderColor: 'black',
    color: 'white'
  },
  _dark: {
    _active: {
      background: 'white',
      borderColor: 'black',
      color: 'black'
    },
    _hover: {
      _disabled: {
        background: 'initial',
        borderColor: 'border',
        color: 'textPrimary'
      },
      background: 'white',
      borderColor: 'black',
      color: 'black'
    }
  },
  _hover: {
    _disabled: {
      borderColor: 'border',
      color: 'textPrimary'
    },
    background: 'black',
    borderColor: 'black',
    color: 'white'
  },
  borderColor: 'border',
  borderWidth: '2px',
  color: 'textPrimary'
})

const link = defineStyle({ _hover: { opacity: 0.5 } })

const outlineRoundedFull = defineStyle({
  _dark: {
    color: 'white'
  },
  _hover: {
    bg: 'hover'
  },
  background: 'bgPrimary',
  border: '1px solid',
  borderColor: 'border',
  borderRadius: 'full',
  color: 'black',
  h: '40px',
  padding: 0,
  w: '40px'
})

export const buttonTheme = defineStyleConfig({
  baseStyle,
  sizes: { lg, md },
  variants: { link, outline, outlineRoundedFull, primary }
})
