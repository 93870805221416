import {
  Box,
  BoxProps,
  Center,
  Flex,
  FlexProps,
  Grid,
  GridProps,
  HStack,
  IconButton,
  Image,
  ImageProps,
  Spacer,
  Spinner
} from '@chakra-ui/react'
import COLLECTION_PLACEHOLDER_PFP_DARK from 'assets/illustrations/png/collection-placeholder-dark.png'
import COLLECTION_PLACEHOLDER_PFP_LIGHT from 'assets/illustrations/png/collection-placeholder-light.png'
import { ReactComponent as NoSearchResultsIcon } from 'assets/illustrations/svg/no-search-results.svg'
import ChainIcon from 'components/ChainIcon'
import ItemImage from 'components/ItemImage'
import { JoeText } from 'components/JoeText'
import VerifiedBadge from 'components/VerifiedBadge'
import { SEARCH_QUERY_MIN_LENGTH } from 'constants/index'
import { useJoeProfilePicturePlaceholder } from 'hooks/useImage'
import React, { PropsWithChildren, useEffect, useRef } from 'react'
import { X } from 'react-feather'
import { SearchableCollection } from 'state/search/reducer'
import { useIsDarkMode } from 'state/user/hooks'
import { Collection, ItemDetail, Profile } from 'types/joebarn'
import { formattedNum, shortenAddress } from 'utils'
import { getOptimizedImageUrl } from 'utils/imageOptimizer'

const SearchResultsGrid = React.forwardRef<HTMLDivElement, GridProps>(
  function SearchResultsGrid(props, ref) {
    return (
      <Grid
        ref={ref}
        templateColumns="repeat(auto-fill, minmax(240px, 1fr))"
        p="0.5rem 1rem 1rem 1rem"
        gap="1rem"
        {...props}
      />
    )
  }
)
const SearchResultImage = (props: ImageProps) => (
  <Image
    w="3rem"
    h="3rem"
    objectFit="contain"
    borderRadius="full"
    flexShrink={0}
    crossOrigin=""
    referrerPolicy="origin"
    {...props}
  />
)

const SearchHeaderRow = (props: BoxProps) => (
  <Box p="1rem 1.5rem 0 1.5rem" {...props} />
)

const SearchResultRow = (props: FlexProps) => (
  <Flex
    align="center"
    gap="0.5rem"
    cursor="pointer"
    borderRadius="12px"
    p="0.5rem"
    transition="150ms background-color ease-in-out"
    bg="transparent"
    _hover={{ bg: 'hover' }}
    {...props}
  />
)

const CollectionNameRow = (props: FlexProps) => (
  <Flex align="center" gap="0.25rem" {...props} />
)

const CollectionName = ({ children }: PropsWithChildren): JSX.Element => {
  return (
    <JoeText
      variant="primary"
      marginRight={1}
      overflow="hidden"
      textOverflow="ellipsis"
      display="-webkit-box"
      noOfLines={1}
    >
      {children}
    </JoeText>
  )
}

interface CollectionSectionProps {
  collections: SearchableCollection[]
  heading: string
  onCollectionClick: (collection: SearchableCollection) => void
  cursor?: number
  onClearCollectionClick?: (collection: SearchableCollection) => void
}

const CollectionSection = ({
  collections,
  cursor,
  heading,
  onClearCollectionClick,
  onCollectionClick
}: CollectionSectionProps): JSX.Element => {
  const darkMode = useIsDarkMode()
  const resultsGridRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (typeof cursor === 'number' && cursor < collections.length) {
      resultsGridRef.current?.children[cursor].scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }
  }, [cursor, collections.length])

  return (
    <>
      <SearchHeaderRow>
        <JoeText variant="panel-header">{heading}</JoeText>
      </SearchHeaderRow>
      <SearchResultsGrid ref={resultsGridRef}>
        {collections.map((collection, index) => (
          <SearchResultRow
            key={index}
            onClick={() => onCollectionClick(collection)}
            bg={cursor === index ? 'hover' : 'transparent'}
          >
            <SearchResultImage
              src={
                collection.pfpUrl ??
                (darkMode
                  ? COLLECTION_PLACEHOLDER_PFP_DARK
                  : COLLECTION_PLACEHOLDER_PFP_LIGHT)
              }
            />
            <Flex flexDir="column" gap="0.25rem" justify="flex-start">
              <CollectionNameRow>
                <CollectionName>{collection.name}</CollectionName>
                <VerifiedBadge
                  boxSize="16px"
                  verificationStatus={collection.verified}
                />
              </CollectionNameRow>
              <HStack>
                <ChainIcon chain={collection.chain} />
                <JoeText color="textSecondary" fontSize={14}>
                  {`${formattedNum({
                    number: collection.numItems,
                    places: 0,
                    usd: false
                  })} Items`}
                </JoeText>
              </HStack>
            </Flex>
            <Spacer />
            {onClearCollectionClick ? (
              <IconButton
                aria-label="clear search"
                variant="ghost"
                onClick={(event) => {
                  event.stopPropagation()
                  onClearCollectionClick(collection)
                }}
                size="sm"
                icon={<X size="16px" />}
              />
            ) : null}
          </SearchResultRow>
        ))}
      </SearchResultsGrid>
    </>
  )
}

interface ItemSectionProps {
  items: ItemDetail[]
  onItemClick: (item: ItemDetail) => void
  cursor?: number
  cursorExtend?: number
}

const ItemSection = ({
  cursor,
  cursorExtend,
  items,
  onItemClick
}: ItemSectionProps): JSX.Element => {
  const resultsGridRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      typeof cursor === 'number' &&
      typeof cursorExtend === 'number' &&
      cursor >= cursorExtend &&
      cursor < items.length + cursorExtend
    ) {
      resultsGridRef.current?.children[cursor - cursorExtend].scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }
  }, [cursor, items.length, cursorExtend])

  return (
    <>
      <SearchHeaderRow>
        <JoeText variant="panel-header">Items</JoeText>
      </SearchHeaderRow>
      <SearchResultsGrid ref={resultsGridRef}>
        {items.map((item, index) => (
          <SearchResultRow
            key={index}
            onClick={() => onItemClick(item)}
            bg={
              (cursor ?? 0) - (cursorExtend ?? 0) === index
                ? 'hover'
                : 'transparent'
            }
          >
            <ItemImage
              type="logo-sm"
              url={item.metadata?.image ?? undefined}
              size="3rem"
              borderRadius="1.5rem"
            />
            <Flex flexDir="column" gap="0.25rem" justify="flex-start">
              <CollectionNameRow>
                <CollectionName>
                  {item.metadata?.name ?? `#${item.tokenId}`}
                </CollectionName>
              </CollectionNameRow>
              <CollectionNameRow>
                <JoeText
                  color="textSecondary"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  display="-webkit-box"
                  fontSize={14}
                  noOfLines={1}
                >
                  {item.collectionName}
                </JoeText>
                <VerifiedBadge
                  boxSize="14px"
                  verificationStatus={item.verified}
                />
              </CollectionNameRow>
            </Flex>
          </SearchResultRow>
        ))}
      </SearchResultsGrid>
    </>
  )
}

interface ProfileResultImageProps {
  profile: Profile
}

const ProfileResultImage = ({
  profile
}: ProfileResultImageProps): JSX.Element => {
  const joePfpPlaceholderUrl = useJoeProfilePicturePlaceholder(profile.address)
  return (
    <SearchResultImage
      src={
        getOptimizedImageUrl(profile.pfpUrl, 'logo-sm') ?? joePfpPlaceholderUrl
      }
    />
  )
}

interface ProfileSectionProps {
  onProfileClick: (profile: Profile) => void
  profiles: Profile[]
  cursor?: number
  cursorExtend?: number
}

const ProfileSection = ({
  cursor,
  cursorExtend,
  onProfileClick,
  profiles
}: ProfileSectionProps): JSX.Element => {
  const resultsGridRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      typeof cursor === 'number' &&
      typeof cursorExtend === 'number' &&
      cursor >= cursorExtend &&
      cursor < profiles.length + cursorExtend
    ) {
      resultsGridRef.current?.children[cursor - cursorExtend].scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }
  }, [cursor, profiles.length, cursorExtend])

  return (
    <>
      <SearchHeaderRow>
        <JoeText variant="panel-header">Profiles</JoeText>
      </SearchHeaderRow>
      <SearchResultsGrid ref={resultsGridRef}>
        {profiles.map((profile, index) => (
          <SearchResultRow
            key={index}
            onClick={() => onProfileClick(profile)}
            bg={
              (cursor ?? 0) - (cursorExtend ?? 0) === index
                ? 'hover'
                : 'transparent'
            }
          >
            <ProfileResultImage profile={profile} />
            <Flex flexDir="column" gap="0.25rem" justify="flex-start">
              <CollectionNameRow>
                <CollectionName>{profile.name ?? 'User'}</CollectionName>
              </CollectionNameRow>
              <CollectionNameRow>
                <CollectionName>
                  {shortenAddress(profile.address)}
                </CollectionName>
              </CollectionNameRow>
            </Flex>
          </SearchResultRow>
        ))}
      </SearchResultsGrid>
    </>
  )
}

interface SearchResultsProps {
  collections: Collection[]
  items: ItemDetail[]
  loading: boolean
  onClearRecentCollectionClick: (collection: SearchableCollection) => void
  onCollectionClick: (collection: SearchableCollection) => void
  onItemClick: (item: ItemDetail) => void
  onProfileClick: (profile: Profile) => void
  profiles: Profile[]
  query: string
  recentCollections: SearchableCollection[]
  cursor?: number
}

const SearchResults = ({
  collections,
  cursor,
  items,
  loading,
  onClearRecentCollectionClick,
  onCollectionClick,
  onItemClick,
  onProfileClick,
  profiles,
  query,
  recentCollections
}: SearchResultsProps): JSX.Element => {
  return !loading &&
    (collections.length > 0 || items.length > 0 || profiles.length > 0) ? (
    <>
      {collections.length > 0 ? (
        <CollectionSection
          heading="Collections"
          collections={collections}
          onCollectionClick={onCollectionClick}
          cursor={cursor}
        />
      ) : null}
      {items.length > 0 ? (
        <ItemSection
          items={items}
          onItemClick={onItemClick}
          cursor={cursor}
          cursorExtend={collections.length}
        />
      ) : null}
      {profiles.length > 0 ? (
        <ProfileSection
          profiles={profiles}
          onProfileClick={onProfileClick}
          cursor={cursor}
          cursorExtend={collections.length + items.length}
        />
      ) : null}
    </>
  ) : query.length < SEARCH_QUERY_MIN_LENGTH ? (
    <CollectionSection
      heading="Recent"
      collections={recentCollections}
      onCollectionClick={onCollectionClick}
      cursor={cursor}
      onClearCollectionClick={onClearRecentCollectionClick}
    />
  ) : loading ? (
    <Center h="4rem">
      <Spinner />
    </Center>
  ) : (
    <Flex flexDir="column" align="center" justify="center" py="1.5rem">
      <NoSearchResultsIcon height={80} width={80} />
      <JoeText color="textSecondary">No results found</JoeText>
    </Flex>
  )
}

export default SearchResults
