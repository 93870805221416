import {
  Activity,
  Auction,
  AuctionBid,
  AuctionBidMade,
  BidMade,
  Chain,
  Collection,
  CollectionList,
  ItemDetail,
  Launch,
  Launchpeg,
  MakerOrder,
  Profile,
  SearchResults,
  SignedMakerOrder,
  UserBidsReceived
} from 'types/joebarn'

export class ResponseConverter {
  static toSignedMakerOrder(response: any): SignedMakerOrder | null {
    if (!response) {
      return null
    }
    return {
      ...response,
      amount: BigInt(response.amount),
      minPercentageToAsk: BigInt(response.minPercentageToAsk),
      nonce: BigInt(response.nonce),
      price: BigInt(response.price),
      tokenId: BigInt(response.tokenId)
    }
  }

  static toBidMade(response: any): BidMade | null {
    const bidMade = ResponseConverter.toSignedMakerOrder(response.bidMade)
    if (!bidMade) {
      return null
    }
    return {
      ...response,
      bidMade
    }
  }

  static toUserBidsReceived(response: any): UserBidsReceived | null {
    const bidsReceived = response.bidsReceived
      .map((bidReceived: any) =>
        ResponseConverter.toSignedMakerOrder(bidReceived)
      )
      .filter((makerOrder: MakerOrder | null) => makerOrder !== null)
    if (bidsReceived.length === 0) {
      return null
    }
    return {
      bidsReceived,
      item: response.item
    }
  }

  static toCollectionList(response: any): CollectionList | null {
    if (!response) {
      return null
    }
    const updatedCollectionListEntries = response.entries.map(
      (collection: any) => ({
        ...collection,
        floorPrice: BigInt(collection.floorPrice ?? '0'),
        volume7d: BigInt(collection.volume7d ?? '0')
      })
    )
    return { ...response, entries: updatedCollectionListEntries }
  }

  static toCollection(response: any): Collection | null {
    if (!response) {
      return null
    }
    return {
      ...response,
      externalVolume: response.externalVolume
        ? BigInt(response.externalVolume)
        : null,
      floor: response.floor ? BigInt(response.floor) : null,
      volume: response.volume ? BigInt(response.volume) : null,
      volumeTotal: response.volumeTotal ? BigInt(response.volumeTotal) : null
    }
  }

  static toItemDetail(response: any): ItemDetail | null {
    if (!response) {
      return null
    }

    // set nativeUsdPrice based on item chain
    let nativeUsdPrice: number | undefined
    switch (response.chain as Chain) {
      case 'avalanche':
        nativeUsdPrice = response.avaxUsdPrice
        break
    }

    return {
      ...response,
      auction: response.auction
        ? ResponseConverter.toAuction(response.auction)
        : undefined,
      bestBid: ResponseConverter.toSignedMakerOrder(response.bestBid),
      currentAsk: ResponseConverter.toSignedMakerOrder(response.currentAsk),
      floorPrice: response.floorPrice ? BigInt(response.floorPrice) : undefined,
      lastSalePrice: response.lastSalePrice
        ? BigInt(response.lastSalePrice)
        : undefined,
      nativeUsdPrice
    }
  }

  static toSearchResults(response: any): SearchResults {
    return {
      collections:
        response?.collections?.map((collection: any) =>
          ResponseConverter.toCollection(collection)
        ) ?? [],
      items:
        response?.items?.map((item: any) =>
          ResponseConverter.toItemDetail(item)
        ) ?? [],
      profiles:
        response?.users?.map((user: any) =>
          ResponseConverter.toProfile(user)
        ) ?? []
    }
  }

  static toActivity(response: any): Activity {
    return {
      ...response,
      price: response.price ? BigInt(response.price) : undefined
    }
  }

  static toAuction(response: any): Auction {
    return {
      ...response,
      englishAuctionMinBidPrice: BigInt(response.englishAuctionMinBidPrice),
      englishAuctionSameBidderMinBidPrice:
        response.englishAuctionSameBidderMinBidPrice
          ? BigInt(response.englishAuctionSameBidderMinBidPrice)
          : undefined,
      englishAuctionTopBid: response.englishAuctionTopBid
        ? ResponseConverter.toAuctionBid(response.englishAuctionTopBid)
        : undefined
    }
  }

  static toAuctionBid(response: any): AuctionBid {
    return {
      ...response,
      bidAmount: BigInt(response.bidAmount)
    }
  }

  static toAuctionBidMade(response: any): AuctionBidMade {
    return {
      ...response,
      auctionBidMade: ResponseConverter.toAuctionBid(response.auctionBidMade),
      item: response.item
        ? ResponseConverter.toItemDetail(response.item)
        : undefined
    }
  }

  static toLaunch(response: any): Launch {
    return {
      ...response,
      collection: response.collection
        ? ResponseConverter.toCollection(response.collection)
        : undefined,
      launchpeg: response.launchpeg
        ? ResponseConverter.toLaunchpeg(response.launchpeg)
        : undefined
    }
  }

  static toProfile(response: any): Profile | null {
    if (!response) {
      return null
    }
    return {
      ...response,
      address: response.address,
      name: response.name ? response.name : null,
      numAsks: response.numAsks,
      numBids: response.numBids,
      numItems: response.numItems,
      pfpUrl: response.pfpUrl ? response.pfpUrl : null
    }
  }

  static toLaunchpeg(response: any): Launchpeg | null {
    if (!response) {
      return null
    }

    return {
      ...response,
      allowlistPrice: response.allowlistPrice
        ? BigInt(response.allowlistPrice)
        : null,
      auctionPrice: response.auctionPrice
        ? BigInt(response.auctionPrice)
        : null,
      lastMintPrice: response.lastMintPrice
        ? BigInt(response.lastMintPrice)
        : null,
      publicSalePrice: response.publicSalePrice
        ? BigInt(response.publicSalePrice)
        : null
    }
  }
}
