import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { fontStyle } from './fonts'

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(menuAnatomy.keys)

const baseStyle = definePartsStyle({
  item: {
    _dark: { bg: 'bgSecondary' },
    _focus: {
      bg: 'hover'
    },
    _hover: {
      bg: 'hover'
    },
    bg: 'bgPrimary',
    color: 'textPrimary',
    fontFamily: fontStyle
  },
  list: {
    _dark: { bg: 'bgSecondary' },
    bg: 'bgPrimary',
    border: '1px',
    borderColor: 'border'
  }
})

export const menuTheme = defineMultiStyleConfig({ baseStyle })
