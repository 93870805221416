import { Text, TextProps } from '@chakra-ui/react'
import React from 'react'

export type JoeTextVariant =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'link'
  | 'body'
  | 'h1'
  | 'lg-header'
  | 'md-header'
  | 'sm-header'
  | 'panel-header'
  | 'table-header'

export interface JoeTextProps extends TextProps {
  variant?: JoeTextVariant
}

export const JoeText = ({
  variant,
  ...props
}: JoeTextProps): React.ReactElement => {
  switch (variant) {
    case 'primary':
      return <Text fontWeight="regular" color="textPrimary" {...props} />
    case 'secondary':
      return <Text fontWeight="regular" color="textSecondary" {...props} />
    case 'error':
      return <Text fontWeight="regular" color="red.500" {...props} />
    case 'link':
      return <Text fontWeight="regular" color="accent.500" {...props} />
    case 'body':
      return (
        <Text
          fontWeight="regular"
          color="textPrimary"
          fontSize={14}
          {...props}
        />
      )
    case 'h1':
      return (
        <Text
          fontWeight="semibold"
          fontSize={{ '4xl': 48, base: 26, md: 32 }}
          letterSpacing="-0.5px"
          {...props}
        />
      )
    case 'lg-header':
      return (
        <Text
          fontWeight="semibold"
          fontSize={{ base: 20, md: 24 }}
          letterSpacing="-0.5px"
          {...props}
        />
      )
    case 'md-header':
      return (
        <Text
          fontWeight="semibold"
          fontSize={20}
          letterSpacing="-0.5px"
          {...props}
        />
      )
    case 'sm-header':
      return (
        <Text
          fontWeight="regular"
          fontSize={14}
          color="textSecondary"
          {...props}
        />
      )
    case 'panel-header':
      return (
        <Text
          letterSpacing="-0.5px"
          fontWeight="semibold"
          fontSize={16}
          {...props}
        />
      )
    case 'table-header':
      return (
        <Text
          fontSize="10px"
          color="textSecondary"
          textTransform="uppercase"
          {...props}
        />
      )
    default:
      return <Text {...props} />
  }
}
