import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
import { DRAWER_MAX_WIDTH_REM } from 'constants/index'

const primary = defineStyle({
  dialog: {
    background: 'bgPrimary',
    maxW: `${DRAWER_MAX_WIDTH_REM}rem`
  }
})

export const drawerTheme = defineStyleConfig({ variants: { primary } })
