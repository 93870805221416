import { createReducer } from '@reduxjs/toolkit'

import { updateUserPfpUrl } from './actions'

const currentTimestamp = () => new Date().getTime()

export interface UserState {
  // the user's choice for dark mode or light mode
  matchesDarkMode: boolean
  timestamp: number
  userDarkMode: boolean // whether the dark mode media query matches
  userPfpUrl: string | null
}

export const initialState: UserState = {
  matchesDarkMode: false,
  timestamp: currentTimestamp(),
  userDarkMode: true,
  userPfpUrl: null
}

export default createReducer(initialState, (builder) =>
  builder.addCase(updateUserPfpUrl, (state, action) => {
    state.userPfpUrl = action.payload.userPfpUrl
  })
)
