import { createAction } from '@reduxjs/toolkit'
import { ChainId } from '@traderjoe-xyz/joepegs-sdk'

export interface SerializableTransactionReceipt {
  blockHash: string
  blockNumber: number
  contractAddress: string | null
  from: string
  status: 'success' | 'reverted'
  to: string | null
  transactionHash: string
  transactionIndex: number
}

export const addTransaction = createAction<{
  chainId: ChainId
  from: string
  hash: string
  approval?: { spender: string; tokenAddress: string }
  claim?: { recipient: string }
  summary?: string
}>('transactions/addTransaction')

export const clearAllTransactions = createAction<{ chainId: ChainId }>(
  'transactions/clearAllTransactions'
)

export const finalizeTransaction = createAction<{
  hash: string
  receipt: SerializableTransactionReceipt
}>('transactions/finalizeTransaction')

export const checkedTransaction = createAction<{
  blockNumber: number
  chainId: ChainId
  hash: string
}>('transactions/checkedTransaction')
