import { createReducer } from '@reduxjs/toolkit'
import { updateVersion } from 'state/global/actions'

import {
  addItemsToShoppingCart,
  clearShoppingCart,
  removeItemfromShoppingCart,
  setShoppingCartItems,
  updateMakerAsks
} from './actions'
import { SerializableItemDetail } from './types'
import { toSerializableItemDetail, toSerializableMakerOrder } from './utils'

const VERSION = 2

export interface ShoppingCartState {
  readonly items: SerializableItemDetail[]
  version: number
}

const initialState: ShoppingCartState = {
  items: [],
  version: VERSION
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addItemsToShoppingCart, (state, { payload }) => {
      state.items = [
        ...state.items,
        ...payload.items
          .filter((item) => item.currentAsk)
          .map(toSerializableItemDetail)
      ]
    })
    .addCase(removeItemfromShoppingCart, (state, { payload }) => {
      state.items = state.items.filter(
        (item) =>
          !(
            item.collection.toLowerCase() ===
              payload.item.collection.toLowerCase() &&
            item.tokenId === payload.item.tokenId &&
            item.currentAsk?.id === payload.item.currentAsk?.id
          )
      )
    })
    .addCase(setShoppingCartItems, (state, { payload }) => {
      state.items = payload.items.map(toSerializableItemDetail)
    })
    .addCase(clearShoppingCart, (state) => {
      state.items = []
    })
    .addCase(updateMakerAsks, (state, { payload }) => {
      state.items = state.items.map((item) => {
        const makerAsk = item.currentAsk?.id
          ? payload.currentAsks[item.currentAsk.id]
          : null
        item.currentAsk = makerAsk ? toSerializableMakerOrder(makerAsk) : null
        return item
      })
    })
    .addCase(updateVersion, (state) => {
      if (state.version < VERSION) {
        state.items = []
      }
      state.version = VERSION
    })
)
