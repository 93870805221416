import { Center, CenterProps } from '@chakra-ui/react'
import Jazzicon from '@metamask/jazzicon'
import React, { RefObject, useEffect, useRef } from 'react'
import { useAccount } from 'wagmi'

const Identicon = (props: CenterProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>()
  const { address: account } = useAccount()

  useEffect(() => {
    if (account && ref.current) {
      ref.current.innerHTML = ''
      ref.current.appendChild(Jazzicon(40, parseInt(account.slice(2, 10), 16)))
    }
  }, [account])

  return (
    <Center
      boxSize="40px"
      borderRadius="full"
      overflow="hidden"
      ref={ref as RefObject<HTMLDivElement>}
      {...props}
    />
  )
}
export default Identicon
