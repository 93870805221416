import {
  getUnixTime,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subWeeks
} from 'date-fns'

export function oneMonthAgo() {
  return getUnixTime(subMonths(Date.now(), 1))
}

export function oneWeekAgo() {
  return getUnixTime(subWeeks(Date.now(), 1))
}

export function oneDayAgo() {
  return getUnixTime(subDays(Date.now(), 1))
}

export function thirtyMinutesAgo() {
  return getUnixTime(subMinutes(Date.now(), 30))
}

export function twelveHourAgo() {
  return getUnixTime(subHours(Date.now(), 12))
}

export function twoHourAgo() {
  return getUnixTime(subHours(Date.now(), 2))
}

export function currentTimestamp() {
  return getUnixTime(Date.now())
}
