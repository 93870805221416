import { useEffect } from 'react'
import { useAddErrorPopup } from 'state/application/hooks'
import { getAddress, isAddress } from 'viem'
import { useAccount, useContractRead } from 'wagmi'

export const useIsOwnable = ({
  address,
  chainId
}: {
  address: string
  chainId: number
}) => {
  const { address: connectedUserAddress } = useAccount()
  const addErrorPopup = useAddErrorPopup()
  const isValidAddress = isAddress(address)

  const { data, isLoading } = useContractRead({
    abi: [
      {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
      }
    ] as const,
    address: isValidAddress ? getAddress(address) : undefined,
    args: undefined,
    chainId,
    enabled: !!address && !!chainId,
    functionName: 'owner'
  })

  useEffect(() => {
    if (!isValidAddress) {
      addErrorPopup('Invalid address')
    }
  }, [isValidAddress, addErrorPopup])

  return {
    isLoading,
    isOwner: data?.toLowerCase() === connectedUserAddress?.toLowerCase()
  }
}
