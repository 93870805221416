import axios from 'axios'

export const useS3ImageUpload = (): ((
  url: string,
  file: File,
  fields: { [key: string]: string }
) => Promise<void>) => {
  return async (url: string, file: File, fields: { [key: string]: string }) => {
    const data = new FormData()
    Object.entries(fields).forEach(([k, v]) => {
      data.append(k, v)
    })
    data.append('file', file)
    await axios({
      data,
      headers: { 'content-type': file.type },
      method: 'post',
      url
    })
  }
}
