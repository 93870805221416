import { createAction } from '@reduxjs/toolkit'
import { GetMakerOrderByIdsResponse, ItemDetail } from 'types/joebarn'

export const addItemsToShoppingCart = createAction<{ items: ItemDetail[] }>(
  'shoppingCart/addItems'
)

export const removeItemfromShoppingCart = createAction<{ item: ItemDetail }>(
  'shoppingCart/removeItem'
)

export const clearShoppingCart = createAction('shoppingCart/clearAll')

export const setShoppingCartItems = createAction<{ items: ItemDetail[] }>(
  'shoppingCart/setItems'
)

export const updateMakerAsks = createAction<{
  currentAsks: GetMakerOrderByIdsResponse
}>('shoppingCart/updateMakerAsks')
