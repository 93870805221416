import {
  Box,
  PlacementWithLogical,
  ResponsiveValue,
  Tooltip
} from '@chakra-ui/react'
import React, { useState } from 'react'

interface TouchFriendlyTooltipProps {
  children?: React.ReactNode
  label?: React.ReactNode
  maxWidth?: ResponsiveValue<string>
  placement?: PlacementWithLogical
}

const TouchFriendlyTooltip = ({
  children,
  label,
  maxWidth,
  placement
}: TouchFriendlyTooltipProps) => {
  const [isOpen, setIsTooltipOpen] = useState(false)

  return (
    <Tooltip
      isOpen={isOpen}
      label={label}
      maxWidth={maxWidth}
      placement={placement}
    >
      <Box
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
      >
        {children}
      </Box>
    </Tooltip>
  )
}

export default TouchFriendlyTooltip
