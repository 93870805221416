const REACT_APP_IMAGE_OPT = process.env.REACT_APP_IMAGE_OPT

export type OptimizedImageType =
  | 'item'
  | 'banner'
  | 'detail'
  | 'activity'
  | 'logo-sm'
  | 'hero'
  | 'logo'
  | 'pfp'
  | 'collection-entry-image'
  | 'no-resize'

export const getOptimizedImageUrl = (
  imageUrl: string | null | undefined,
  type: OptimizedImageType
): string | undefined => {
  if (!imageUrl) {
    return undefined
  }

  // don't use optimizer for base64 string
  if (imageUrl.slice(0, 5) === 'data:') {
    return imageUrl
  }

  let height: number | undefined
  let width: number | undefined
  let fit: 'cover' | undefined
  switch (type) {
    case 'item':
      height = 384
      width = 384
      break
    case 'logo-sm':
      height = 128
      width = 128
      break
    case 'logo':
      height = 256
      width = 256
      break
    case 'pfp':
      height = 64
      width = 64
      break
    case 'banner':
      height = 288
      width = 1440
      fit = 'cover'
      break
    case 'hero':
      height = 472
      width = 1616
      fit = 'cover'
      break
    case 'detail':
      height = 800
      width = 800
      break
    case 'collection-entry-image':
      height = 320
      width = 960
      break
    case 'activity':
      width = 64
      height = 64
      break
    case 'no-resize':
      break
  }

  let format: 'avif' | 'webp' | undefined
  if (imageUrl.endsWith('avif')) {
    format = 'avif'
  } else if (imageUrl.endsWith('webp')) {
    format = 'webp'
  }

  // configure cloudflare CDN
  let cloudflareOptions =
    width && height ? `width=${width},height=${height}` : ''
  if (fit) {
    cloudflareOptions += `,fit=${fit}`
  }
  if (format) {
    cloudflareOptions += `,format=${format}`
  }

  return REACT_APP_IMAGE_OPT
    ? `${REACT_APP_IMAGE_OPT}/${cloudflareOptions}/${imageUrl}`
    : imageUrl
}

export const isVideoUrl = (url: string): boolean => {
  return /\.(mp4|webm|mov)$/.test(url)
}
