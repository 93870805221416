import { Box, Center, Flex, Icon, Input, InputProps } from '@chakra-ui/react'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Search as SearchIcon, X } from 'react-feather'

interface SearchPanelProps {
  focused: boolean
  query: string
  queryMinLength: number
  searchResults: JSX.Element
  setFocused: React.Dispatch<React.SetStateAction<boolean>>
  setQuery: React.Dispatch<React.SetStateAction<string>>
  closable?: boolean
  extraElement?: JSX.Element
  hasRecentSearches?: boolean
  onCloseClick?: () => void
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  panelMaxWidth?: string
  searchInputRef?:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
  searchPanelRef?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
  searchResultContainerRef?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
}

export const SearchPanel = ({
  closable,
  extraElement,
  focused,
  hasRecentSearches,
  onCloseClick,
  onKeyDown,
  panelMaxWidth,
  query,
  queryMinLength,
  searchInputRef,
  searchPanelRef,
  searchResultContainerRef,
  searchResults,
  setFocused,
  setQuery,
  ...props
}: SearchPanelProps & InputProps) => {
  const showResultsPanel =
    focused && (query.length >= queryMinLength || hasRecentSearches)
  return (
    <Flex
      ref={searchPanelRef}
      w="full"
      maxW={panelMaxWidth ?? '760px'}
      pos="relative"
      justify="space-between"
    >
      <Center
        h={{ base: 'full', sm: '48px' }}
        w="48px"
        pos="absolute"
        left="0px"
        top="0px"
        zIndex={2}
        pointerEvents="none"
      >
        <Icon
          as={SearchIcon}
          boxSize="20px"
          strokeWidth={2}
          color="textSecondary"
        />
      </Center>
      <Input
        ref={searchInputRef}
        className={showResultsPanel ? 'show-results-panel' : undefined}
        w="full"
        bg="bgSecondary"
        h={{ base: '40px', sm: '48px' }}
        pl="2.75rem"
        pr="1rem"
        border="1px solid"
        borderColor="transparent"
        borderRadius="12px"
        fontSize="16px"
        fontWeight="semibold"
        color="textPrimary"
        transition="150ms background ease-in-out"
        outline={{}}
        _placeholder={{
          color: 'textSecondary',
          fontSize: '16px'
        }}
        _focus={{
          bg: 'bgPrimary',
          borderColor: 'accent.500',
          outline: 'none'
        }}
        sx={{
          '&.show-results-panel': {
            bg: 'bgPrimary',
            borderBottomColor: 'border',
            borderBottomRadius: 0,
            borderLeftColor: 'accent.500',
            borderRightColor: 'accent.500',
            borderTopColor: 'accent.500',
            outline: 'none'
          },
          '&:hover:not(:focus)': {
            bg: 'bgSecondary'
          }
        }}
        onChange={(e) => setQuery(e.target.value)}
        onFocus={() => setFocused(true)}
        value={query}
        onKeyDown={onKeyDown}
        {...props}
      />
      {showResultsPanel ? (
        <Box
          ref={searchResultContainerRef}
          pos="absolute"
          zIndex={5}
          top={{ base: '40px', sm: '48px' }}
          right="0px"
          left="0px"
          w="full"
          maxH="480px"
          overflowY="auto"
          borderBottomRadius="12px"
          bg="bgPrimary"
          border="1px solid"
          borderColor="accent.500"
          borderTop={0}
          boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
        >
          {searchResults}
        </Box>
      ) : null}
      {extraElement && extraElement}
      {closable && (query.length > 0 || isMobileOnly) ? (
        <Center
          pos="absolute"
          top="0px"
          right="0px"
          boxSize={{ base: '40px', sm: '48px' }}
          color="textSecondary"
          transition="150ms color ease-in-out"
          cursor="pointer"
          _hover={{ color: 'textPrimary' }}
          onClick={() => onCloseClick?.()}
        >
          <Icon as={X} boxSize="20px" />
        </Center>
      ) : null}
    </Flex>
  )
}
