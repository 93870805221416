import {
  Box,
  Divider,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure
} from '@chakra-ui/react'
import WalletBalance from 'components/WalletBalance'
import Web3Status from 'components/Web3Status'
import React from 'react'
import { useAccount } from 'wagmi'

import WalletMenuHeader from './WalletMenuHeader'
import WalletMenuLinks from './WalletMenuLinks'

const WalletMenu = (): JSX.Element => {
  const { isConnected } = useAccount()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const renderWeb3Status = () => (
    <Box cursor="pointer">
      <Web3Status />
    </Box>
  )

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      trigger="hover"
      placement="bottom-end"
    >
      {isConnected ? (
        <PopoverTrigger>{renderWeb3Status()}</PopoverTrigger>
      ) : (
        renderWeb3Status()
      )}
      <PopoverContent
        display="flex"
        flexDir="column"
        minW="390px"
        gap={2}
        pt={6}
        pb={8}
        px={4}
      >
        <WalletMenuHeader onDisconnect={onClose} />
        <WalletMenuLinks />
        <Divider mb={4} />
        <WalletBalance />
      </PopoverContent>
    </Popover>
  )
}

export default WalletMenu
