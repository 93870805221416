import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
  bg: 'bgPrimary',
  border: '1px solid',
  borderColor: 'border',
  borderRadius: 'md',
  color: 'textPrimary',
  fontSize: '16px',
  fontWeight: 'normal',
  px: '1rem',
  py: '0.5rem'
})

export const tooltipTheme = defineStyleConfig({ baseStyle })
