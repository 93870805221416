import { ItemDetail, SignedMakerOrder } from 'types/joebarn'

import { SerializableItemDetail, SerializableSignedMakerOrder } from './types'

// Function to convert MakerOrder to SerializableMakerOrder
export const toSerializableMakerOrder = (
  order: SignedMakerOrder
): SerializableSignedMakerOrder => {
  return {
    ...order,
    amount: order.amount.toString(),
    endTime: order.endTime.toString(),
    minPercentageToAsk: order.minPercentageToAsk.toString(),
    nonce: order.nonce.toString(),
    price: order.price.toString(),
    startTime: order.startTime.toString(),
    tokenId: order.tokenId.toString()
  }
}

// Function to convert SerializableMakerOrder back to MakerOrder
const fromSerializableMakerOrder = (
  order: SerializableSignedMakerOrder
): SignedMakerOrder => {
  return {
    ...order,
    amount: BigInt(order.amount),
    endTime: BigInt(order.endTime),
    minPercentageToAsk: BigInt(order.minPercentageToAsk),
    nonce: BigInt(order.nonce),
    price: BigInt(order.price),
    startTime: BigInt(order.startTime),
    tokenId: BigInt(order.tokenId)
  }
}

// Function to convert ItemDetail to SerializableItemDetail
export const toSerializableItemDetail = (
  item: ItemDetail
): SerializableItemDetail => {
  return {
    ...item,
    auction: undefined, // we don't serialize the auction
    bestBid: item.bestBid ? toSerializableMakerOrder(item.bestBid) : null,
    currentAsk: item.currentAsk
      ? toSerializableMakerOrder(item.currentAsk)
      : null,
    floorPrice: item.floorPrice?.toString(),
    lastSalePrice: item.lastSalePrice?.toString()
  }
}

// Function to convert SerializableItemDetail back to ItemDetail
export const fromSerializableItemDetail = (
  item: SerializableItemDetail
): ItemDetail => {
  return {
    ...item,
    bestBid: item.bestBid ? fromSerializableMakerOrder(item.bestBid) : null,
    currentAsk: item.currentAsk
      ? fromSerializableMakerOrder(item.currentAsk)
      : null,
    floorPrice: item.floorPrice ? BigInt(item.floorPrice) : undefined,
    lastSalePrice: item.lastSalePrice ? BigInt(item.lastSalePrice) : undefined
  }
}
