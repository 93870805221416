import { CollectionsSortType, ItemsSortType } from 'types/joebarn'

export const VALIDITY_OPTIONS = [
  { name: '1 day', value: 1 },
  { name: '3 days', value: 3 },
  { name: '7 days', value: 7 },
  { name: '14 days', value: 14 },
  { name: '30 days', value: 30 },
  { name: '90 days', value: 90 },
  { name: '180 days', value: 180 }
]

export const MAKE_OFFER_VALIDITY_OPTIONS = [
  { name: '1 day', value: 1 },
  { name: '3 days', value: 3 },
  { name: '7 days', value: 7 },
  { name: '14 days', value: 14 }
]

export const MAKER_ORDER_TYPE = [
  { name: 'isOrderAsk', type: 'bool' },
  { name: 'signer', type: 'address' },
  { name: 'collection', type: 'address' },
  { name: 'price', type: 'uint256' },
  { name: 'tokenId', type: 'uint256' },
  { name: 'amount', type: 'uint256' },
  { name: 'strategy', type: 'address' },
  { name: 'currency', type: 'address' },
  { name: 'nonce', type: 'uint256' },
  { name: 'startTime', type: 'uint256' },
  { name: 'endTime', type: 'uint256' },
  { name: 'minPercentageToAsk', type: 'uint256' },
  { name: 'params', type: 'bytes' }
]

export const ITEMS_SORT_TYPE_OPTIONS = [
  { name: 'Recent Listing', value: ItemsSortType.RECENT_LISTING },
  { name: 'Recent Sale', value: ItemsSortType.RECENT_SALE },
  { name: 'Recent Activity', value: ItemsSortType.RECENT_ACTIVITY },
  { name: 'Price: Low to High', value: ItemsSortType.PRICE_ASC },
  { name: 'Price: High to Low', value: ItemsSortType.PRICE_DESC }
]

export const COLLECTIONS_SORT_TYPE_OPTIONS = [
  { name: 'Highest Volume', value: CollectionsSortType.VOLUME },
  { name: 'Highest Sales', value: CollectionsSortType.NUM_SALES }
]

export const ITEMS_SORT_TYPE_RARITY_OPTIONS = [
  { name: 'Rarity: Rare to Common', value: ItemsSortType.RARITY_ASC },
  { name: 'Rarity: Common to Rare', value: ItemsSortType.RARITY_DESC }
]

export const SEARCH_QUERY_MIN_LENGTH = 3

export const PUBLIC_SALE_PRIOR_TO_START_SECONDS = 120

export const DRAWER_MAX_WIDTH_REM = 25

export const AUCTION_HOUSE_DOCS_URL = 'https://joepegs.dev/docs/auction-house'

export const AddressZero = '0x0000000000000000000000000000000000000000'

export const PAGE_HELMET_DESCRIPTION =
  'Dive into Joepegs, the leading Web3 platform for NFTs and digital collectibles. As a cultural hub in the Web3 space, we offer secure buying, selling, and discovery of innovative digital assets.'

export const PAGE_HELMET_RECENT_DESCRIPTION =
  'Explore Joepegs, your destination for unique NFTs and digital collectibles in the Web3 sphere. Engage in secure buying, selling, and discovery of innovative digital assets. Ignite your Web3 cultural journey.'

export const PAGE_HELMET_COLLECTION_DESCRIPTION =
  "Unearth a plethora of digital collectibles on JoePegs' collections page. Home to premium digital art and a vibrant Web3 cultural hub, it's your go-to platform for buying, selling, and discovering NFTs."

export const PAGE_HELMET_MINT_DESCRIPTION =
  "Get your hands on brand new NFTs and digital collectibles with Joepegs' launchpad. Join exciting mint events and step into the cultural pulse of the Web3 space. Trade and discover unique digital assets with ease."
