import { Box, Flex, Spinner, useToast } from '@chakra-ui/react'
import { DRAWER_MAX_WIDTH_REM } from 'constants/index'
import React, { useMemo, useState } from 'react'
import { ApplicationModal, PopupContent } from 'state/application/actions'
import { useModalOpen } from 'state/application/hooks'

import LoadingClose from './LoadingClose'
import Popup from './Popup'

interface PopupItemProps {
  content: PopupContent
  popKey: string
}

const PopupItem = ({ content, popKey }: PopupItemProps): JSX.Element => {
  const toast = useToast()
  const [isMouseOver, setIsMouseOver] = useState(false)

  const { state } = content
  const popupContent = <Popup {...content} />

  const isShoppingCartOpen = useModalOpen(ApplicationModal.SHOPPING_CART)
  const marginRight = useMemo(
    () => (isShoppingCartOpen ? `${DRAWER_MAX_WIDTH_REM}rem` : '0px'),
    [isShoppingCartOpen]
  )

  let bg: string
  switch (state) {
    case 'success':
      bg = '#71bd98'
      break
    case 'error':
      bg = '#e87b72'
      break
    case 'loading':
      bg = 'bgSecondary'
      break
  }

  return (
    <Box mr={marginRight}>
      <Flex
        justify="space-between"
        align="center"
        w="full"
        boxShadow="lg"
        pos="relative"
        borderRadius="16px"
        p="0.75rem"
        gap="0.5rem"
        overflow="hidden"
        minH="54px"
        minW={{ base: 'full', xs: '290px' }}
        bg={bg}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        {popupContent}
        {state === 'loading' ? (
          <Flex align="center" justify="center" h="30px" w="30px">
            <Spinner size="sm" />
          </Flex>
        ) : (
          <LoadingClose
            timeout={5000}
            isAnimationPaused={isMouseOver}
            onClick={() => toast.close(popKey)}
          />
        )}
      </Flex>
    </Box>
  )
}

export default PopupItem
