import { createReducer } from '@reduxjs/toolkit'
import { Chain, CollectionVerificationStatus } from 'types/joebarn'

import { addRecentCollection, removeRecentCollection } from './actions'

export type SearchableCollection = {
  address: string
  chain: Chain
  name: string
  numItems: number
  pfpUrl?: string
  slugName?: string
  verified?: CollectionVerificationStatus
}

export interface SearchState {
  recentCollections: SearchableCollection[]
}

export const initialState: SearchState = {
  recentCollections: []
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addRecentCollection, (state, action) => {
      if (
        !state.recentCollections.find(
          (c) => c.address === action.payload.collection.address
        )
      ) {
        state.recentCollections = [
          ...state.recentCollections,
          action.payload.collection
        ]
      }
    })
    .addCase(removeRecentCollection, (state, action) => {
      state.recentCollections = state.recentCollections.filter(
        (c) => c.address !== action.payload.collection.address
      )
    })
)
