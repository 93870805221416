import { useToast } from '@chakra-ui/react'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { MakeOfferModalsProps } from 'components/MakeOfferModals'
import PopupItem from 'components/Popups/PopupItem'
import { WrapCurrencyModalProps } from 'components/WrapCurrencyModal'
import { useCallback, useMemo } from 'react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOnChainErrorSummary } from 'utils/errors'

import { AppDispatch, AppState } from '../index'
import {
  ApplicationModal,
  closeModal,
  PopupContent,
  setMakeOfferModalArgs,
  setOpenModal,
  setWrapCurrencyModalArgs
} from './actions'

export const useModalOpen = (modal: ApplicationModal): boolean => {
  const openModals = useSelector(
    (state: AppState) => state.application.openModals
  )
  return openModals.includes(modal)
}

const useToggleModal = (modal: ApplicationModal): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(() => dispatch(setOpenModal(modal)), [dispatch, modal])
}

export const useCloseModal = (modal: ApplicationModal): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(() => dispatch(closeModal(modal)), [dispatch, modal])
}

export const useWalletModalToggle = (): (() => void) => {
  const { openConnectModal } = useConnectModal()
  return useCallback(() => openConnectModal?.(), [openConnectModal])
}

export const useMakeOfferModalToggle = (): ((
  args?: MakeOfferModalsProps
) => void) => {
  const toggleModal = useToggleModal(ApplicationModal.MAKE_OFFER)
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (args?: MakeOfferModalsProps) => {
      dispatch(setMakeOfferModalArgs(args))
      toggleModal()
    },
    [toggleModal, dispatch]
  )
}

export const useListItemModalToggle = (): (() => void) =>
  useToggleModal(ApplicationModal.LIST_ITEM)

export const useCancelAllModalToggle = (): (() => void) =>
  useToggleModal(ApplicationModal.CANCEL_ALL_OFFERS_AND_LISTINGS)

export const useReportCollectionModalToggle = (): (() => void) =>
  useToggleModal(ApplicationModal.REPORT_COLLECTION)

export const useListItemSuccessModalToggle = (): (() => void) =>
  useToggleModal(ApplicationModal.LIST_ITEM_SUCCESS)

export const useMakeOfferSuccessModalToggle = (): (() => void) =>
  useToggleModal(ApplicationModal.MAKER_OFFER_SUCCESS)

export const useMintSuccessModalToggle = (): (() => void) =>
  useToggleModal(ApplicationModal.MINT_SUCCESS)

export const useWrapCurrencyModalToggle = (): ((
  args?: WrapCurrencyModalProps
) => void) => {
  const toggleModal = useToggleModal(ApplicationModal.WRAP_CURRENCY)
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (args?: WrapCurrencyModalProps) => {
      dispatch(setWrapCurrencyModalArgs(args))
      toggleModal()
    },
    [toggleModal, dispatch]
  )
}

export const useCloseWrapCurrencyModal = (): (() => void) =>
  useCloseModal(ApplicationModal.WRAP_CURRENCY)

export const useCloseMakeOfferModal = (): (() => void) =>
  useCloseModal(ApplicationModal.MAKE_OFFER)

export const useCollectionOfferToggle = (): (() => void) =>
  useToggleModal(ApplicationModal.COLLECTION_OFFER)

export const useCollectionOfferSuccessModalToggle = (): (() => void) =>
  useToggleModal(ApplicationModal.COLLECTION_OFFER_SUCCESS)

export const usePlaceBidModalToggle = (): (() => void) =>
  useToggleModal(ApplicationModal.PLACE_BID)

export const useClosePlaceBidModal = (): (() => void) =>
  useCloseModal(ApplicationModal.PLACE_BID)

export const useMakeOfferModalArgs = (): MakeOfferModalsProps | undefined =>
  useSelector((state: AppState) => state.application.makeOfferModalProps)

export const useWrapCurrencyModalArgs = ():
  | WrapCurrencyModalProps
  | undefined =>
  useSelector((state: AppState) => state.application.wrapCurrencyModalProps)

export const useFullScreenItemToggle = (): (() => void) =>
  useToggleModal(ApplicationModal.FULL_SCREEN_ITEM)

export const useCloseFullScreenItemModal = (): (() => void) =>
  useCloseModal(ApplicationModal.FULL_SCREEN_ITEM)

export const useToggleShoppingCartModal = (): (() => void) =>
  useToggleModal(ApplicationModal.SHOPPING_CART)

export const useCloseShoppingCartModal = (): (() => void) =>
  useCloseModal(ApplicationModal.SHOPPING_CART)

export const useAddPopup = (): ((
  content: PopupContent,
  key?: string
) => void) => {
  const toast = useToast()
  return useCallback(
    (content: PopupContent, key?: string) => {
      if (key && toast.isActive(key)) {
        toast.update(key, {
          duration: content.state === 'loading' ? null : 5000,
          render: () => <PopupItem content={content} popKey={key} />,
          status: content.state,
          title: content.summary
        })
      } else {
        const id = key ?? content.hash ?? crypto.randomUUID()
        toast({
          containerStyle: { maxW: '800px' },
          duration: content.state === 'loading' ? null : 5000,
          id,
          position: 'top-right',
          render: () => <PopupItem content={content} popKey={id} />,
          status: content.state,
          title: content.summary
        })
      }
    },
    [toast]
  )
}

export const useAddSuccessPopup = (): ((summary: string) => void) => {
  const addPopup = useAddPopup()
  return useCallback(
    (summary: string) => {
      addPopup({ state: 'success', summary })
    },
    [addPopup]
  )
}

export const useAddErrorPopup = (): ((
  summary: string,
  key?: string
) => void) => {
  const addPopup = useAddPopup()
  return useCallback(
    (summary: string, key?: string) => {
      addPopup({ state: 'error', summary }, key)
    },
    [addPopup]
  )
}

// returns a function that allows adding an on chain error popup
export const useAddOnChainErrorPopup = (): ((error: unknown) => void) => {
  const addPopup = useAddPopup()
  return useCallback(
    (error: unknown) => {
      const summary = getOnChainErrorSummary(error)
      if (!summary) {
        return
      }
      addPopup({ state: 'error', summary })
    },
    [addPopup]
  )
}

// get the list of active popups
export const useActivePopups = (): AppState['application']['popupList'] => {
  const list = useSelector((state: AppState) => state.application.popupList)
  return useMemo(() => list.filter((item) => item.show), [list])
}
