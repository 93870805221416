import {
  AspectRatio,
  Box,
  Flex,
  Image,
  ResponsiveValue,
  Skeleton
} from '@chakra-ui/react'
import PLACEHOLDER_PFP_DARK from 'assets/illustrations/png/item-placeholder-dark.png'
import PLACEHOLDER_PFP_LIGHT from 'assets/illustrations/png/item-placeholder-light.png'
import useOptimizedImageUrl, { ContentType } from 'hooks/useOptimizedImageUrl'
import React, { useState } from 'react'
import { useIsDarkMode } from 'state/user/hooks'
import { OptimizedImageType } from 'utils/imageOptimizer'

interface VideoComponentProps {
  autoPlay?: boolean
  borderRadius?: string
  controls?: boolean
  onError?: () => void
  onLoadedData?: () => void
  src?: string
}

const VideoComponent = ({
  autoPlay = true,
  borderRadius,
  controls,
  onError,
  onLoadedData,
  src
}: VideoComponentProps) => (
  <Box
    as="video"
    objectFit="contain"
    w="full"
    h="full"
    maxH="680px"
    borderRadius={borderRadius}
    src={src}
    crossOrigin="anonymous"
    preload="metadata"
    muted={true}
    autoPlay={autoPlay}
    loop
    playsInline
    controls={controls}
    onLoadedData={onLoadedData}
    onError={onError}
  />
)

interface ItemImageProps {
  size: ResponsiveValue<string>
  type: OptimizedImageType
  alt?: string
  animationUrl?: string | null
  borderRadius?: string
  grayscale?: number
  margin?: string
  onImageClick?: () => void
  url?: string | null
  videoProps?: {
    autoPlay?: boolean
    videoControls?: boolean
  }
}

const ItemImage = ({
  alt,
  animationUrl,
  borderRadius,
  grayscale,
  margin,
  onImageClick,
  size,
  type,
  url,
  videoProps
}: ItemImageProps): JSX.Element => {
  const darkMode = useIsDarkMode()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const is3DModel = animationUrl?.endsWith('.glb') ?? false
  const {
    contentType: animationContentType,
    imageUrl: animationOptimizedUrl,
    onVideoLoadFailed: onAnimationVideoLoadFailed
  } = useOptimizedImageUrl(type, animationUrl ?? undefined)

  const {
    contentType,
    imageUrl: imageOptimizedUrl,
    onImageLoadFailed,
    onVideoLoadFailed,
    showPlaceholder
  } = useOptimizedImageUrl(type, url ?? undefined)

  const placeholderUrl = darkMode ? PLACEHOLDER_PFP_DARK : PLACEHOLDER_PFP_LIGHT

  return (
    <Box
      boxSize={size}
      m={margin}
      filter={`grayscale(${grayscale ?? 0})`}
      overflow="hidden"
      flexShrink={0}
    >
      {is3DModel && animationUrl ? (
        <AspectRatio ratio={1}>
          <Box
            as="model-viewer"
            src={animationUrl}
            camera-controls
            auto-rotate
            ar-status="not-presenting"
            w="full"
            h="full"
          />
        </AspectRatio>
      ) : animationContentType === ContentType.VIDEO &&
        animationOptimizedUrl ? (
        <Skeleton
          aspectRatio={isLoaded ? 'unset' : 1}
          w="full"
          borderRadius={borderRadius}
          isLoaded={isLoaded}
        >
          <VideoComponent
            borderRadius={borderRadius}
            src={animationOptimizedUrl}
            controls={videoProps?.videoControls}
            onLoadedData={() => setIsLoaded(true)}
            onError={() => onAnimationVideoLoadFailed()}
            autoPlay={videoProps?.autoPlay}
          />
        </Skeleton>
      ) : contentType === ContentType.VIDEO && imageOptimizedUrl ? (
        <Skeleton
          aspectRatio={isLoaded ? 'unset' : 1}
          w="full"
          borderRadius={borderRadius}
          isLoaded={isLoaded}
        >
          <VideoComponent
            borderRadius={borderRadius}
            src={imageOptimizedUrl}
            onLoadedData={() => setIsLoaded(true)}
            onError={() => onVideoLoadFailed()}
            controls={videoProps?.videoControls}
            autoPlay={videoProps?.autoPlay}
          />
        </Skeleton>
      ) : (
        <Flex flexDir="column" gap="0.5rem" w="full" h="full">
          <Skeleton
            aspectRatio={isLoaded ? 'unset' : 1}
            w="full"
            h="full"
            borderRadius={borderRadius}
            isLoaded={isLoaded}
          >
            <Image
              objectFit="contain"
              w="full"
              h="full"
              maxH="680px"
              cursor="pointer"
              borderRadius={borderRadius}
              // enable cors and use policy to send the 'origin' header
              // which is required by ipfs when security cors is enabled
              // ipfs doesn't automatically add cors response headers,
              // it is returned by cloudflare after resizing instead
              // chakra docs is not updated, referrerPolicy is merged in
              // https://github.com/chakra-ui/chakra-ui/pull/6057/files
              crossOrigin=""
              referrerPolicy="origin"
              draggable="false"
              alt={alt}
              src={
                showPlaceholder
                  ? placeholderUrl
                  : imageOptimizedUrl ?? placeholderUrl
              }
              onClick={onImageClick}
              onLoad={() => setIsLoaded(true)}
              onError={() => onImageLoadFailed()}
            />
          </Skeleton>
          {animationContentType === ContentType.AUDIO ? (
            <Box
              as="audio"
              w="full"
              controls
              preload="auto"
              crossOrigin="anonymous"
            >
              <source src={animationUrl ?? undefined} />
            </Box>
          ) : null}
        </Flex>
      )}
    </Box>
  )
}

export default ItemImage
