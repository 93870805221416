import axios from 'axios'

const BARN_URL = process.env.REACT_APP_BARN_URL
if (typeof BARN_URL === 'undefined') {
  throw new Error(`REACT_APP_BARN_URL must be a defined environment variable`)
}

const instance = axios.create({
  baseURL: BARN_URL,
  headers: {
    accept: 'application/json'
  },
  withCredentials: true
})

export default instance
