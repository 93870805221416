/* eslint-disable @typescript-eslint/naming-convention */
import { ItemDetail } from 'types/joebarn'
import { formatPrice } from 'utils'

export type AddRemoveShoppingCartSource =
  | 'item_detail'
  | 'item_card'
  | 'shopping_cart'
  | 'erc1155_listing'

interface AnalyticsItem {
  currency: string
  item_id: string
  item_name: string
  price: string
}

export const convertItemDetailAndMakerAskToAnalyticsItem = (
  item: ItemDetail
): AnalyticsItem | undefined => {
  if (!item.currentAsk) {
    return undefined
  }
  return {
    currency: item.currentAsk.currency,
    item_id: item.id,
    item_name:
      item.metadata?.name ?? `${item.collectionName ?? ''} ${item.tokenId}`,
    price: formatPrice(item.currentAsk.price).native
  }
}
