import { createReducer } from '@reduxjs/toolkit'
import { ChainId } from '@traderjoe-xyz/joepegs-sdk'
import { supportedChains } from 'constants/chains'

import {
  addTransaction,
  checkedTransaction,
  clearAllTransactions,
  finalizeTransaction,
  SerializableTransactionReceipt
} from './actions'

const now = () => new Date().getTime()

export interface TransactionDetails {
  addedTime: number
  chainId: ChainId
  from: string
  hash: string
  approval?: { spender: string; tokenAddress: string }
  claim?: { recipient: string }
  confirmedTime?: number
  lastCheckedBlockNumber?: number
  receipt?: SerializableTransactionReceipt
  summary?: string
}

export interface TransactionState {
  [chainId: number]: {
    [txHash: string]: TransactionDetails
  }
}

export const initialState: TransactionState = {}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(
      addTransaction,
      (
        transactions,
        { payload: { approval, chainId, claim, from, hash, summary } }
      ) => {
        if (transactions[chainId]?.[hash]) {
          throw Error('Attempted to add existing transaction.')
        }
        const txs = transactions[chainId] ?? {}
        txs[hash] = {
          addedTime: now(),
          approval,
          chainId,
          claim,
          from,
          hash,
          summary
        }
        transactions[chainId] = txs
      }
    )
    .addCase(clearAllTransactions, (transactions, { payload: { chainId } }) => {
      if (!transactions[chainId]) return
      transactions[chainId] = {}
    })
    .addCase(
      checkedTransaction,
      (transactions, { payload: { blockNumber, chainId, hash } }) => {
        const tx = transactions[chainId]?.[hash]
        if (!tx) {
          return
        }
        if (!tx.lastCheckedBlockNumber) {
          tx.lastCheckedBlockNumber = blockNumber
        } else {
          tx.lastCheckedBlockNumber = Math.max(
            blockNumber,
            tx.lastCheckedBlockNumber
          )
        }
      }
    )
    .addCase(
      finalizeTransaction,
      (transactions, { payload: { hash, receipt } }) => {
        const tx = supportedChains.map(
          (chain) => transactions[chain.id]?.[hash]
        )?.[0]
        if (!tx) {
          return
        }
        tx.receipt = receipt
        tx.confirmedTime = now()
      }
    )
)
