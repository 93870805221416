export const cleanFormData = (formData: Record<string, any>) => {
  const cleanedData: Record<string, any> = {}
  Object.entries(formData).forEach(([key, value]) => {
    if (value !== '' && value !== null && value !== undefined) {
      cleanedData[key] = value
    }
  })

  return cleanedData
}
