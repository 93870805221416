import {
  AspectRatio,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { ImageDropzone } from 'components/ImageDropzone'
import { JoeText } from 'components/JoeText'
import {
  useCreateMintpegUploadUrl,
  useUpdateCollection
} from 'hooks/useJoebarn'
import { useS3ImageUpload } from 'hooks/useS3ImageUpload'
import React, { useState } from 'react'
import { useAddErrorPopup } from 'state/application/hooks'
import { useLoginIfNeeded } from 'state/authentication/hooks'
import { Collection } from 'types/joebarn'
import { cleanFormData } from 'utils/form'
import { useAccount } from 'wagmi'

interface CollectionForm {
  bannerUrl: string
  description: string
  discordUrl: string
  pfpUrl: string
  slugName: string
  twitterUrl: string
}

const CollectionEditForm = ({ collection }: { collection: Collection }) => {
  const { address: account } = useAccount()
  const createUrl = useCreateMintpegUploadUrl()
  const uploadImage = useS3ImageUpload()
  const addErrorPopup = useAddErrorPopup()
  const [loadingBanner, setLoadingBanner] = useState(false)
  const [loadingPfpAvatar, setLoadingPfpAvatar] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  // storing initial slug name
  // we need to remove the slugname from payload
  // if user does not change the value
  // otherwise it will fail api validation
  const initialSlugName = collection.slugName || ''

  const [errors, setErrors] = useState({
    discordUrl: '',
    slugName: '',
    twitterUrl: ''
  })

  const { mutateAsync: updateCollection } = useUpdateCollection({
    chain: collection.chain,
    collectionAddress: collection.address
  })

  const { loginIfNeeded } = useLoginIfNeeded()

  const [formData, setFormData] = useState<CollectionForm>({
    bannerUrl: collection?.bannerUrl || '',
    description: collection?.description || '',
    discordUrl: collection?.discordUrl || '',
    pfpUrl: collection?.pfpUrl || '',
    slugName: collection.slugName || '',
    twitterUrl: collection?.twitterUrl || ''
  })

  const validateInput = (name: string, value: string) => {
    if (name === 'slugName') {
      if (value === '') {
        setErrors((prev) => ({
          ...prev,
          [name]: 'Input empty'
        }))
        return
      }
      if (!/^[a-z0-9_-]+$/.test(value)) {
        setErrors((prev) => ({
          ...prev,
          [name]:
            'Only lowercased alphanumeric characters, underscores, and dashes are allowed.'
        }))
        return
      }
    }

    if (name === 'discordUrl' || name === 'twitterUrl') {
      if (value === '') {
        setErrors((prev) => ({
          ...prev,
          [name]: 'Input empty'
        }))
        return
      }
      if (!value.startsWith('https://')) {
        setErrors((prev) => ({
          ...prev,
          [name]: 'URL must start with https://'
        }))
        return
      }

      const httpsPattern = /^https:\/\/[a-zA-Z0-9-_:/.]+$/
      if (!httpsPattern.test(value)) {
        setErrors((prev) => ({
          ...prev,
          [name]: 'Invalid characters'
        }))
        return
      }
    }

    setErrors((prev) => ({ ...prev, [name]: '' }))
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    validateInput(name, value)
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (errors.discordUrl || errors.twitterUrl || errors.slugName) {
      return
    }

    const authTokens = await loginIfNeeded(account as string)

    const formattedFormData = cleanFormData(formData)

    // remove slugname from payload if it is not changed
    if (formattedFormData.slugName === initialSlugName) {
      delete formattedFormData.slugName
    }

    try {
      setIsSubmitting(true)
      await updateCollection({ args: formattedFormData, authTokens })
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data.detail) {
        addErrorPopup(error.response?.data.detail)
      } else if (error instanceof Error) {
        addErrorPopup(error.message)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormControl display="flex" flexDir="column" rowGap="2rem">
        <Flex flexDir="column" gap={1}>
          <JoeText fontWeight="semibold">Collection Banner</JoeText>
          <JoeText mb="0.5rem" color="textSecondary" fontSize="sm">
            File types supported: PNG, JPG, JPEG, GIF. Max Size: 8MB, 1440x288
          </JoeText>
          <Flex alignContent="center" maxHeight="225px" w="100%">
            <ImageDropzone
              image={formData.bannerUrl}
              onChangeImage={async (image) => {
                try {
                  setLoadingBanner(true)
                  const { destinationUrl, fields, presignedUploadUrl } =
                    await createUrl(image.file.type, 'banner')

                  await uploadImage(presignedUploadUrl, image.file, fields)

                  setFormData((prev) => ({
                    ...prev,
                    bannerUrl: destinationUrl
                  }))

                  setLoadingBanner(false)
                } catch (err) {
                  if (err instanceof AxiosError && err.response?.data.detail) {
                    addErrorPopup(err.response?.data.detail)
                  } else if (err instanceof Error) {
                    addErrorPopup(err.message)
                  }
                  setLoadingBanner(false)
                }
              }}
              maxSizeInMb={8}
              loading={loadingBanner}
            />
          </Flex>
        </Flex>
        <Flex flexDir="column" gap={1}>
          <JoeText fontWeight="semibold">Profile Picture</JoeText>
          <JoeText mb="0.5rem" color="textSecondary" fontSize="sm">
            File types supported: PNG, JPG, JPEG, GIF. Max Size: 3MB, 256x256
          </JoeText>
          <Flex alignContent="center" maxHeight="225px">
            <AspectRatio ratio={1 / 1} w="40%">
              <ImageDropzone
                image={formData.pfpUrl}
                onChangeImage={async (image) => {
                  try {
                    setLoadingPfpAvatar(true)
                    const { destinationUrl, fields, presignedUploadUrl } =
                      await createUrl(image.file.type, 'avatar')

                    await uploadImage(presignedUploadUrl, image.file, fields)

                    setFormData((prev) => ({
                      ...prev,
                      pfpUrl: destinationUrl
                    }))

                    setLoadingPfpAvatar(false)
                  } catch (err) {
                    if (
                      err instanceof AxiosError &&
                      err.response?.data.detail
                    ) {
                      addErrorPopup(err.response?.data.detail)
                    } else if (err instanceof Error) {
                      addErrorPopup(err.message)
                    }
                    setLoadingPfpAvatar(false)
                  }
                }}
                maxSizeInMb={3}
                loading={loadingPfpAvatar}
              />
            </AspectRatio>
          </Flex>
        </Flex>
        <Flex flexDir="column" gap={1}>
          <FormLabel fontWeight="semibold">Discord URL</FormLabel>
          <Input
            maxLength={100}
            name="discordUrl"
            value={formData.discordUrl}
            onChange={handleChange}
            placeholder="https://discord.com/invite/my-collection"
            borderColor={errors.discordUrl ? 'red.500' : 'border'}
            _focus={{
              borderColor: errors.discordUrl ? 'red.500' : 'accent.500'
            }}
          />
          {errors.discordUrl && (
            <Box mt={1}>
              <JoeText fontSize={14} variant="error">
                {errors.discordUrl}
              </JoeText>
            </Box>
          )}
        </Flex>

        <Flex flexDir="column" gap={1}>
          <FormLabel fontWeight="semibold">Twitter URL</FormLabel>
          <Input
            maxLength={100}
            name="twitterUrl"
            value={formData.twitterUrl}
            onChange={handleChange}
            placeholder="https://twitter.com/my-collection"
            borderColor={errors.twitterUrl ? 'red.500' : 'border'}
            _focus={{
              borderColor: errors.twitterUrl ? 'red.500' : 'accent.500'
            }}
          />
          {errors.twitterUrl && (
            <Box mt={1}>
              <JoeText fontSize={14} variant="error">
                {errors.twitterUrl}
              </JoeText>
            </Box>
          )}
        </Flex>

        <Flex flexDir="column" gap={1}>
          <FormLabel fontWeight="semibold">Nickname</FormLabel>
          <Input
            maxLength={100}
            name="slugName"
            value={formData.slugName}
            onChange={handleChange}
            placeholder="collection nickname"
            borderColor={errors.slugName ? 'red.500' : 'border'}
            _focus={{
              borderColor: errors.slugName ? 'red.500' : 'accent.500'
            }}
          />
          {errors.slugName && (
            <Box mt={1}>
              <JoeText fontSize={14} variant="error">
                {errors.slugName}
              </JoeText>
            </Box>
          )}
        </Flex>

        <Flex flexDir="column" gap={1}>
          <FormLabel fontWeight="semibold">Description</FormLabel>
          <Textarea
            maxLength={1000}
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="A beautiful description"
            borderRadius="2xl"
            borderColor="border"
            resize="vertical"
            minH="140px"
            width="100%"
            focusBorderColor="accent.500"
            _hover={{
              borderColor: 'border'
            }}
            _dark={{
              bg: 'joeDark.600'
            }}
            _light={{
              bg: 'joeLight.300'
            }}
          />
        </Flex>
        <Button isLoading={isSubmitting} type="submit">
          Save Changes
        </Button>
      </FormControl>
    </form>
  )
}

export default CollectionEditForm
