import { Icon, IconProps } from '@chakra-ui/react'
import { ReactComponent as AvaxIcon } from 'assets/illustrations/svg/avax-rounded.svg'
import React from 'react'

interface CurrencyIconProps {
  symbol?: string
}

const CurrencyIcon = ({ symbol, ...props }: CurrencyIconProps & IconProps) => {
  switch (symbol) {
    case 'AVAX':
      return <Icon as={AvaxIcon} fill="avaxRed" {...props} />
    case 'WAVAX':
      return <Icon as={AvaxIcon} fill="wavaxBlue" {...props} />
    default:
      return null
  }
}

export default CurrencyIcon
