import { Flex, FlexProps, HStack, Icon, Link } from '@chakra-ui/react'
import { ReactComponent as ProfileIcon } from 'assets/images/profile-icon.svg'
import { ReactComponent as SettingsIcon } from 'assets/images/settings-icon.svg'
import { ReactComponent as StarIcon } from 'assets/images/star.svg'
import { JoeText } from 'components/JoeText'
import React from 'react'
import { ChevronRight } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { useAccount } from 'wagmi'

const MenuItem = (props: FlexProps) => (
  <Flex
    align="center"
    py={2}
    px={{ base: 0, md: 4 }}
    justify="space-between"
    w="full"
    borderRadius="0.5rem"
    cursor="pointer"
    transition="150ms background-color ease-in-out"
    _hover={{ bg: 'hover' }}
    {...props}
  />
)

interface WalletMenuLinksProps {
  onClose?: () => void
}

const WalletMenuLinks = ({ onClose }: WalletMenuLinksProps) => {
  const { address: account } = useAccount()
  return (
    <Flex flexDir="column" gap={2}>
      <Link
        as={NavLink}
        to={`/profile/${account}`}
        w="full"
        mt={{ base: 0, md: 3 }}
        _hover={{ textDecoration: 'none' }}
        onClick={onClose}
      >
        <MenuItem>
          <HStack spacing={4}>
            <Icon as={ProfileIcon} boxSize="24px" fill="textSecondary" />
            <JoeText fontWeight="semibold" fontSize={16}>
              My Profile
            </JoeText>
          </HStack>
          <Icon as={ChevronRight} color="textSecondary" boxSize="24px" />
        </MenuItem>
      </Link>
      <Link
        as={NavLink}
        to={`/profile/${account}/edit`}
        w="full"
        _hover={{ textDecoration: 'none' }}
        onClick={onClose}
      >
        <MenuItem>
          <HStack spacing={4}>
            <Icon as={SettingsIcon} boxSize="24px" fill="textSecondary" />
            <JoeText fontWeight="semibold" fontSize={16}>
              Settings
            </JoeText>
          </HStack>
          <Icon as={ChevronRight} color="textSecondary" boxSize="24px" />
        </MenuItem>
      </Link>
      <Link
        as={NavLink}
        to={`/composer`}
        w="full"
        _hover={{ textDecoration: 'none' }}
        onClick={onClose}
      >
        <MenuItem>
          <HStack spacing={4}>
            <Icon as={StarIcon} boxSize="24px" fill="textSecondary" />
            <JoeText fontWeight="semibold" fontSize={16}>
              Create NFT
            </JoeText>
          </HStack>
          <Icon as={ChevronRight} color="textSecondary" boxSize="24px" />
        </MenuItem>
      </Link>
    </Flex>
  )
}

export default WalletMenuLinks
