// Not lazy-loading the header just so we don't see the whole page look
// like it's re-loading once the component has been loaded in
import 'chakra/index.css'

import { Box, Flex } from '@chakra-ui/react'
import Header from 'components/Header'
import PageHelmet from 'components/PageHelmet'
import { PAGE_HELMET_DESCRIPTION } from 'constants/index'
import useMigrateWagmiQueryClientIfNeeded from 'hooks/useMigrateWagmiQueryClientIfNeeded'
import React, { Suspense } from 'react'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation
} from 'react-router-dom'

import CollectionEdit from './Collection/CollectionEdit'

const Footer = React.lazy(() => import('components/Footer'))
const ModalRoot = React.lazy(() => import('components/ModalRoot'))
const ScrollToTop = React.lazy(() => import('components/ScrollToTop'))

const Home = React.lazy(() => import('./Home'))
const Item = React.lazy(() => import('pages/Item'))
const Recent = React.lazy(() => import('pages/Recent'))
const Profile = React.lazy(() => import('pages/Profile'))
const EditProfile = React.lazy(() => import('pages/Profile/EditProfile'))
const Collections = React.lazy(() => import('pages/Collection'))
const Launchpeg = React.lazy(() => import('pages/Launchpeg/LaunchpegList'))
const LaunchpegDetail = React.lazy(() => import('./Launchpeg/LaunchpegDetail'))
const CollectionDetail = React.lazy(
  () => import('pages/Collection/CollectionDetail')
)
const Composer = React.lazy(() => import('pages/Composer'))
const BulkListing = React.lazy(() => import('pages/BulkListing'))
const BatchTransfer = React.lazy(() => import('pages/BatchTransfer'))
const Recover = React.lazy(() => import('pages/Recover'))
const Privacy = React.lazy(() => import('pages/Legal/Privacy'))
const TermsOfService = React.lazy(() => import('pages/Legal/TermsOfService'))

const App = (): JSX.Element => {
  const { pathname } = useLocation()

  useMigrateWagmiQueryClientIfNeeded()

  return (
    <Suspense fallback={null}>
      <ScrollToTop />
      <Box minH="100vh" pt="5rem" bg="bgPrimary" pb={{ base: '1rem', sm: 0 }}>
        <PageHelmet
          title={'Joepegs | Leading NFT Marketplace'}
          description={PAGE_HELMET_DESCRIPTION}
          url={location.pathname}
        />
        <Flex flexFlow="row nowrap" w="full" justifyContent="space-between">
          <Header />
        </Flex>
        <ModalRoot />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="recent" element={<Recent />} />
          <Route path="collections" element={<Collections />} />
          <Route
            path="collections/:chain/:addressOrSlug"
            element={<CollectionDetail />}
          />
          <Route
            path="collections/:chain/:addressOrSlug/edit"
            element={<CollectionEdit />}
          />
          <Route
            path="item/:chain/:collectionAddress/:tokenId"
            element={<Item />}
          />
          <Route path="profile">
            <Route path=":address" element={<Profile />} />
            <Route path=":address/edit" element={<EditProfile />} />
          </Route>
          <Route path="bulk-listing" element={<BulkListing />} />
          <Route path="batch-transfer" element={<BatchTransfer />} />
          <Route path="recover" element={<Recover />} />
          <Route path="composer" element={<Composer />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="tos" element={<TermsOfService />} />
          <Route path="mint" element={<Launchpeg />} />
          <Route path="mint/:chain/:slug" element={<LaunchpegDetail />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Box>
      {pathname.startsWith('/collections/') ||
      pathname.startsWith('/recent') ||
      pathname.startsWith('/profile/') ? null : (
        <Footer />
      )}
    </Suspense>
  )
}

const AppRouter = (): JSX.Element => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

export default AppRouter
