import { createAction } from '@reduxjs/toolkit'
import { ChainId } from '@traderjoe-xyz/joepegs-sdk'
import { MakeOfferModalsProps } from 'components/MakeOfferModals'
import { WrapCurrencyModalProps } from 'components/WrapCurrencyModal'

export type PopupContent = {
  state: 'success' | 'error' | 'loading'
  chainId?: ChainId
  hash?: string
  summary?: string
}

export enum ApplicationModal {
  MAKE_OFFER,
  MAKER_OFFER_SUCCESS,
  LIST_ITEM,
  LIST_ITEM_SUCCESS,
  MINT_SUCCESS,
  WRAP_CURRENCY,
  FULL_SCREEN_ITEM,
  SHOPPING_CART,
  CANCEL_ALL_OFFERS_AND_LISTINGS,
  REPORT_COLLECTION,
  PLACE_BID,
  COLLECTION_OFFER,
  COLLECTION_OFFER_SUCCESS
}

export const updateBlockNumber = createAction<{
  blockNumber: number
  chainId: number
}>('application/updateBlockNumber')

export const setOpenModal = createAction<ApplicationModal>(
  'application/setOpenModal'
)

export const closeModal = createAction<ApplicationModal>(
  'application/closeModal'
)

export const setMakeOfferModalArgs = createAction<
  MakeOfferModalsProps | undefined
>('application/setMakeOfferModalArgs')

export const setWrapCurrencyModalArgs = createAction<
  WrapCurrencyModalProps | undefined
>('application/setWrapCurrencyModalArgs')
