import { createAction } from '@reduxjs/toolkit'

import { SearchableCollection } from './reducer'

export const addRecentCollection = createAction<{
  collection: SearchableCollection
}>('user/addRecentCollection')

export const removeRecentCollection = createAction<{
  collection: SearchableCollection
}>('user/removeRecentCollection')
