import {
  darkTheme,
  lightTheme,
  RainbowKitProvider as RainbowKitProviderDefault,
  Theme
} from '@rainbow-me/rainbowkit'
import { fontStyle } from 'chakra/fonts'
import { supportedChains } from 'constants/chains'
import React, { useMemo } from 'react'
import { matchPath } from 'react-router-dom'
import { useIsDarkMode } from 'state/user/hooks'
import { convertChainToChainId, convertStringToChain } from 'utils/chain'

const joeDarkTheme: Theme = {
  ...darkTheme({ accentColor: '#8473ff' }),
  fonts: { body: fontStyle }
}

const joeLightTheme: Theme = {
  ...lightTheme({ accentColor: '#8473ff' }),
  fonts: { body: fontStyle }
}

interface RainbowKitProviderProps {
  children: React.ReactNode
}

const RainbowKitProvider = ({ children }: RainbowKitProviderProps) => {
  const isDarkMode = useIsDarkMode()

  const pathname = window.location.pathname
  const params = useMemo(() => {
    const patterns = [
      'collections/:chain/:addressOrSlug',
      'item/:chain/:collectionAddress/:tokenId',
      'mint/:chain/:slug'
    ]
    return patterns
      .map((pattern) => matchPath(pattern, pathname))
      .find((match) => !!match)?.params
  }, [pathname])
  const initialChain = useMemo(() => {
    const { chain: chainString } = params || {}
    if (!chainString) return undefined
    const chain = convertStringToChain(chainString)
    if (!chain) return undefined
    return convertChainToChainId(chain)
  }, [params])

  return (
    <RainbowKitProviderDefault
      chains={supportedChains}
      initialChain={initialChain}
      appInfo={{
        appName: 'Joepegs',
        learnMoreUrl:
          'https://support.traderjoexyz.com/en/collections/3691849-getting-setup#:~:text=a%20week%20ago-,Cryptocurrency%20Wallets,-Wallet%20Setup%3A%20Metamask'
      }}
      theme={isDarkMode ? joeDarkTheme : joeLightTheme}
    >
      {children}
    </RainbowKitProviderDefault>
  )
}

export default RainbowKitProvider
