import { BaseError } from 'viem'

export const decodeOnChainError = (error: unknown): BaseError | undefined => {
  if (error instanceof BaseError) {
    return error as BaseError
  }
  return undefined
}

export const getOnChainErrorSummary = (error: unknown): string | undefined => {
  const onChainError = decodeOnChainError(error)

  if (onChainError) {
    if (
      onChainError.details?.includes('User denied message signature') ||
      onChainError.details?.includes('User denied transaction signature')
    ) {
      // the user intentionally denied the request
      return undefined
    }
    return onChainError.details ?? onChainError.shortMessage
  }

  return 'Unknown error'
}
