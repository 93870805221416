import { QueryClient } from '@tanstack/react-query'

const QUERY_RETRIES = 3
const MUTATION_RETRIES = 1

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: MUTATION_RETRIES
    },
    queries: {
      refetchOnWindowFocus: false,
      retry: QUERY_RETRIES
    }
  }
})
