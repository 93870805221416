import { popoverAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle(({ colorMode }) => ({
  body: { p: '1rem' },
  content: {
    _dark: { bg: 'bgSecondary' },
    background: 'bgPrimary',
    border: 0,
    borderRadius: '16px',
    boxShadow:
      colorMode === 'light'
        ? '0px 4px 16px -4px rgba(174, 178, 230, 0.48)'
        : '0px 8px 32px rgba(0, 0, 0, 0.48)'
  }
}))

export const popoverTheme = defineMultiStyleConfig({
  baseStyle
})
