import { useEffect, useMemo, useState } from 'react'

const useImage = (assetPath: string | undefined): string | undefined => {
  const [path, setPath] = useState<string | undefined>()
  useEffect(() => {
    if (!assetPath) {
      setPath(undefined)
      return
    }
    import(`./../assets/${assetPath}`)
      .then((res) => {
        setPath(res.default)
      })
      .catch(() => setPath(undefined))
  }, [assetPath])
  return path
}

export const useJoeProfilePicturePlaceholder = (
  address: string | undefined | null
): string | undefined => {
  const assetUrl = useMemo(() => {
    if (!address) {
      return
    }
    const match = address.match(/\d+$/)
    const joeIndex = match && match.length > 0 ? match[0][0] : 0
    return `joe/joe-${joeIndex}.png`
  }, [address])
  return useImage(assetUrl)
}
