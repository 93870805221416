import '@rainbow-me/rainbowkit/styles.css'

import { ChakraProvider } from '@chakra-ui/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { chakraTheme } from 'chakra/theme'
import RainbowKitProvider from 'components/RainbowKitProvider'
import { queryClient } from 'constants/queryClient'
import { wagmiConfig } from 'constants/wagmi'
import App from 'pages/App'
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { initializeAnalytics } from 'utils/analytics'
import { WagmiConfig } from 'wagmi'

import store from './state'

// https://github.com/WalletConnect/walletconnect-monorepo/issues/748#issuecomment-1178160422
// eslint-disable-next-line @typescript-eslint/no-var-requires
window.Buffer = window.Buffer || require('buffer').Buffer

initializeAnalytics()

const container = document.getElementById('root') as HTMLElement

createRoot(container).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ChakraProvider theme={chakraTheme} resetCSS={false}>
          <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </RainbowKitProvider>
          </WagmiConfig>
        </ChakraProvider>
      </Provider>
    </QueryClientProvider>
  </StrictMode>
)
