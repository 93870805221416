import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { CategoryType } from 'components/Analytics/TopRankings'
import joebarn from 'components/AxiosInterceptor'
import { currentTimestamp, twelveHourAgo, twoHourAgo } from 'constants/date'
import { SEARCH_QUERY_MIN_LENGTH } from 'constants/index'
import { queryClient } from 'constants/queryClient'
import qs from 'qs'
import { useCallback, useMemo } from 'react'
import { useAddErrorPopup } from 'state/application/hooks'
import {
  useAddSuccessPopup,
  useWalletModalToggle
} from 'state/application/hooks'
import { useGetAuthTokens, useLoginIfNeeded } from 'state/authentication/hooks'
import {
  Activity,
  ActivityType,
  AddressAndNumItems,
  AnalyticsDataPoint,
  AnalyticsFilterType,
  AnalyticsListingDepthDataPoint,
  AnalyticsListingDepthsPriceIncrement,
  AuctionBid,
  AuctionBidMade,
  AuthMessageResponse,
  AuthTokens,
  BidMade,
  Chain,
  ChainQueryParam,
  CollectionActivity,
  CollectionAnalytics,
  CollectionAttribute,
  CollectionAttributeValue,
  CollectionList,
  CollectionListEntry,
  CollectionsFilterType,
  CollectionsSortType,
  CreateDraftMintpegCollection,
  DataKeyEntities,
  DraftMintpegCollection,
  EditProfileArgs,
  EditProfileResponse,
  FeaturedCollection,
  GetMakerOrderByIdsResponse,
  GetMakerOrdersParams,
  ItemDetail,
  ItemInfoAndTransferTime,
  ItemsFilterType,
  ItemsSortType,
  Launch,
  MakerOrder,
  MakerOrderPayloadBody,
  MakerOrderPayloadResponse,
  MakerOrderSortType,
  MintpegCollection,
  MintpegDraftImageType,
  MintpegNFTImageUpload,
  PaginatedDraftMintpegCollection,
  PublishDraftMintpegCollection,
  SignedMakerOrder,
  ToggleHiddenBatchRequest,
  ToggleHiddenBatchResponse,
  UpdateCollection,
  UpdateDraftMintpegCollection,
  UpdateMintpegCollection,
  UserBidsReceived,
  UserCollection,
  UserRoleType
} from 'types/joebarn'
import { useDebounce } from 'use-debounce'
import { formatFromBalance, formattedNum } from 'utils'
import { ResponseConverter } from 'utils/responseConverter'
import { isAddress } from 'viem'
import { useAccount } from 'wagmi'

import { AttributeFilter } from './useItemsFilters'

interface UsePaginatedEndpointProps<ParamsType, ResponseType> {
  queryKey: string
  url: string
  convert?: (response: any) => ResponseType | null
  enabled?: boolean
  headers?: { [key: string]: string }
  initialPage?: number
  pageSize?: number
  params?: ParamsType
}

const usePaginatedEndpoint = <ParamsType, ResponseType>({
  convert,
  enabled = true,
  headers,
  initialPage = 1,
  pageSize = 20,
  params,
  queryKey,
  url
}: UsePaginatedEndpointProps<ParamsType, ResponseType>) => {
  const addErrorPopup = useAddErrorPopup()

  const result = useInfiniteQuery(
    [queryKey, url, params, pageSize, initialPage],
    ({ pageParam = initialPage }) =>
      joebarn.get(url, {
        headers,
        params: {
          ...params,
          pageNum: pageParam,
          pageSize
        },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
      }),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.data.length === pageSize
          ? allPages.length + initialPage
          : undefined,
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      }
    }
  )
  return {
    ...result,
    data: (result.data?.pages
      .flatMap((page) => page.data)
      .map((val) => (convert ? convert(val) : val))
      .filter(Boolean) ?? []) as ResponseType[],
    isLoading: result.isLoading || result.isFetchingNextPage
  }
}

export const useOwnedItems = (
  address: string,
  chainFilters: ChainQueryParam[],
  collectionAddresses?: string[],
  filters?: ItemsFilterType[],
  query?: string | null
) => {
  const { address: account } = useAccount()

  // current joebarn logic is AND :
  // it returns an empty array because an item can't be listed and unlisted
  // we don't send the filters in this case so we still get items
  const apiItemFilters = useMemo(() => {
    let apiItemFilters = filters ? [...filters] : undefined
    if (
      apiItemFilters &&
      apiItemFilters.includes(ItemsFilterType.BUY_NOW) &&
      apiItemFilters.includes(ItemsFilterType.UNLISTED)
    ) {
      apiItemFilters = apiItemFilters.filter(
        (fil) =>
          fil !== ItemsFilterType.BUY_NOW && fil !== ItemsFilterType.UNLISTED
      )
    }
    return apiItemFilters
  }, [filters])

  return usePaginatedEndpoint({
    convert: ResponseConverter.toItemDetail,
    params: {
      chain: chainFilters.length > 0 ? chainFilters : ['all'],
      collectionAddresses,
      connectedUserAddress: account,
      filters: apiItemFilters,
      search: query
    },
    queryKey: 'OwnedItemsQuery',
    url: `/v3/users/${address.toLowerCase()}/items`
  })
}

export const useProfile = (address?: string | null) => {
  const addErrorPopup = useAddErrorPopup()

  const result = useQuery(
    ['ProfileQuery', address],
    () => joebarn.get(`/v3/users/${address?.toLowerCase()}`),
    {
      enabled: !!address,
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      },
      select: (response) => ResponseConverter.toProfile(response.data)
    }
  )
  return result
}

export const useUserActivity = (
  address: string,
  chain: ChainQueryParam[],
  filters?: ActivityType[]
) =>
  usePaginatedEndpoint<
    { chain: ChainQueryParam[]; filters?: ActivityType[] },
    Activity
  >({
    convert: ResponseConverter.toActivity,
    params: { chain, filters },
    queryKey: 'UserActivityQuery',
    url: `/v3/users/${address.toLowerCase()}/activity`
  })

export const useOwnedItemsOnAuction = (
  address: string,
  chain: ChainQueryParam[]
) =>
  usePaginatedEndpoint<{ chain: ChainQueryParam[] }, ItemDetail>({
    convert: ResponseConverter.toItemDetail,
    params: { chain },
    queryKey: 'OwnedItemsOnAuctionQuery',
    url: `/v3/users/${address.toLowerCase()}/auction-items`
  })

export const useUserAuctionBidsMade = (
  address: string,
  chain: ChainQueryParam[]
) => {
  const { address: account } = useAccount()
  return usePaginatedEndpoint<
    { chain: ChainQueryParam[]; connectedUserAddress?: string },
    AuctionBidMade
  >({
    convert: ResponseConverter.toAuctionBidMade,
    params: { chain, connectedUserAddress: account },
    queryKey: 'UserAuctionBidsMadeQuery',
    url: `/v3/users/${address.toLowerCase()}/auction-bids-made`
  })
}

export const useAuctionBids = (chain: Chain, auctionId?: string) =>
  usePaginatedEndpoint<Record<string, never>, AuctionBid>({
    convert: ResponseConverter.toAuctionBid,
    queryKey: 'AuctionBidsQuery',
    url: `/v3/auctions/${chain}/${auctionId}/english-bids`
  })

export const useCollections = (
  orderBy: CollectionsSortType = CollectionsSortType.VOLUME,
  filterBy: CollectionsFilterType = CollectionsFilterType.ALL,
  chain: ChainQueryParam = ChainQueryParam.ALL,
  pageSize = 20
) =>
  usePaginatedEndpoint({
    convert: ResponseConverter.toCollection,
    pageSize,
    params: { chain, filterBy, orderBy },
    queryKey: 'CollectionsQuery',
    url: '/v3/collections'
  })

export const useUserBidsReceived = (
  address: string,
  chain: ChainQueryParam[]
) => {
  const { address: account } = useAccount()
  return usePaginatedEndpoint<
    { chain: ChainQueryParam[]; connectedUserAddress?: string | null },
    UserBidsReceived
  >({
    convert: ResponseConverter.toUserBidsReceived,
    params: { chain, connectedUserAddress: account },
    queryKey: 'UserBidsReceivedQuery',
    url: `/v3/users/${address.toLowerCase()}/bids-received`
  })
}

export const useUserBidsMade = (address: string, chain: ChainQueryParam[]) => {
  const { address: account } = useAccount()
  return usePaginatedEndpoint<
    { chain: ChainQueryParam[]; connectedUserAddress?: string | null },
    BidMade
  >({
    convert: ResponseConverter.toBidMade,
    params: { chain, connectedUserAddress: account },
    queryKey: 'UserBidsMadeQuery',
    url: `/v3/users/${address.toLowerCase()}/bids-made`
  })
}

export const useUserCollections = (
  address: string,
  chain: ChainQueryParam | Chain,
  hiddenItems?: boolean
) =>
  usePaginatedEndpoint<
    { chain: ChainQueryParam | Chain; hiddenItems?: boolean },
    UserCollection
  >({
    params: { chain, hiddenItems },
    queryKey: 'UserCollectionsQuery',
    url: `/v3/users/${address.toLowerCase()}/collections`
  })

export const useCollection = (addressOrSlug: string, chain: Chain) => {
  const addErrorPopup = useAddErrorPopup()
  return useQuery(
    ['CollectionQuery', addressOrSlug, chain],
    () =>
      joebarn.get(
        isAddress(addressOrSlug)
          ? `/v3/collections/${chain}/${addressOrSlug.toLowerCase()}`
          : `/v3/collections/slug/${chain}/${addressOrSlug.toLowerCase()}`
      ),
    {
      onError: (error) => {
        if (error instanceof AxiosError && error.response?.data.detail) {
          addErrorPopup(error.response?.data.detail)
        } else if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      },
      select: (data) => ResponseConverter.toCollection(data.data)
    }
  )
}

export const useCollectionAttributes = (
  query: string,
  chain: Chain,
  address?: string
) =>
  usePaginatedEndpoint<{ query: string }, CollectionAttribute>({
    enabled: !!address && query.length > 0,
    params: { query },
    queryKey: 'CollectionAttributesQuery',
    url: `/v3/collections/${chain}/${address}/attributes`
  })

export const useCollectionAttributeValues = (
  address: string,
  traitType: string,
  chain: Chain,
  initialPage: number,
  enabled = true
) =>
  usePaginatedEndpoint<Record<string, never>, CollectionAttributeValue>({
    enabled,
    initialPage,
    queryKey: 'CollectionAttributeValuesQuery',
    url: `/v3/collections/${chain}/${address}/trait-types/${traitType}/values`
  })

interface UseItemDetailsProps {
  filters: ItemsFilterType[]
  sortType: ItemsSortType
  attributeFilters?: AttributeFilter[]
  chains?: Chain[]
  collectionAddress?: string
  enabled?: boolean
  isCollectionRequired?: boolean
  maxPrice?: string | null
  minPrice?: string | null
  query?: string | null
  verified?: boolean
}

export const useItemDetails = ({
  attributeFilters,
  chains,
  collectionAddress,
  enabled = true,
  filters,
  isCollectionRequired,
  maxPrice,
  minPrice,
  query,
  sortType,
  verified
}: UseItemDetailsProps) => {
  const { address: account } = useAccount()
  const addErrorPopup = useAddErrorPopup()

  const queryKey = [
    'ItemDetailsQuery',
    chains,
    collectionAddress,
    filters,
    sortType,
    attributeFilters,
    maxPrice,
    minPrice,
    query,
    verified
  ]
  const pageSize = 20
  const result = useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) =>
      joebarn.get('/v3/items', {
        params: {
          attributeFilters,
          chain: chains,
          collectionAddress,
          connectedUserAddress: account,
          filters,
          maxPrice,
          minPrice,
          orderBy: sortType,
          pageNum: pageParam,
          pageSize,
          query,
          verified
        },
        paramsSerializer: (params) => {
          if (params.attributeFilters) {
            params.attributeFilters = JSON.stringify(params.attributeFilters)
          }
          return qs.stringify(params, {
            arrayFormat: 'repeat',
            skipNulls: true
          })
        }
      }),
    {
      enabled:
        !(isCollectionRequired && !collectionAddress) &&
        !(isCollectionRequired && !chains) &&
        enabled,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.data.length === pageSize ? allPages.length + 1 : undefined,
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      }
    }
  )

  const data = useMemo(
    () =>
      (result.data?.pages
        .flatMap((page) => page.data)
        .map(ResponseConverter.toItemDetail)
        .filter(Boolean) as ItemDetail[] | undefined) ?? [],
    [result.data?.pages]
  )

  return {
    ...result,
    data,
    reset: () => {
      queryClient.removeQueries({ exact: true, queryKey })
    }
  }
}

export const useItemDetail = (
  collectionAddress?: string,
  tokenId?: string,
  chain?: Chain
) => {
  const { address: account } = useAccount()
  const addErrorPopup = useAddErrorPopup()
  return useQuery(
    ['ItemDetailQuery', collectionAddress, tokenId, chain, account],
    () =>
      joebarn.get(
        `/v3/collections/${chain}/${collectionAddress?.toLowerCase()}/tokens/${tokenId}`,
        { params: { connectedUserAddress: account } }
      ),
    {
      enabled: !!collectionAddress && !!tokenId && !!chain,
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      },
      select: (response) => ResponseConverter.toItemDetail(response.data)
    }
  )
}

export const useActivities = (
  collection?: string,
  tokenId?: string,
  chain?: Chain,
  filters?: ActivityType[],
  enabled = true
) =>
  usePaginatedEndpoint({
    convert: ResponseConverter.toActivity,
    enabled: !!collection && !!chain && enabled,
    params: {
      filters
    },
    queryKey: 'ActivitiesQuery',
    url: tokenId
      ? `/v3/activities/${chain}/${collection}/tokens/${tokenId}`
      : `/v3/activities/${chain}/${collection}`
  })

export const useMakerOrderPayload = (
  chain: Chain
): ((body: MakerOrderPayloadBody) => Promise<MakerOrder>) => {
  const addErrorPopup = useAddErrorPopup()

  return useCallback(
    async (body: MakerOrderPayloadBody): Promise<MakerOrder> => {
      try {
        const response = await joebarn.post<MakerOrderPayloadResponse>(
          `/v3/maker-orders/payload/${chain}`,
          body
        )
        const makerOrder = ResponseConverter.toSignedMakerOrder(
          response.data.message
        )
        if (!makerOrder) {
          return Promise.reject('Undefined maker order')
        }
        return makerOrder
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup(e.message)
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [chain, addErrorPopup]
  )
}

export const usePostMakerOrder = (
  chain: Chain
): ((makerOrder: SignedMakerOrder) => Promise<MakerOrder>) => {
  const addErrorPopup = useAddErrorPopup()

  return useCallback(
    async (makerOrder: SignedMakerOrder): Promise<MakerOrder> => {
      try {
        const response = await joebarn.post<MakerOrder>(
          `/v3/maker-orders/${chain}`,
          {
            ...makerOrder,
            amount: makerOrder.amount.toString(),
            endTime: makerOrder.endTime.toString(),
            minPercentageToAsk: makerOrder.minPercentageToAsk.toString(),
            nonce: makerOrder.nonce.toString(),
            price: makerOrder.price.toString(),
            startTime: makerOrder.startTime.toString(),
            tokenId: makerOrder.tokenId.toString()
          }
        )
        const result = ResponseConverter.toSignedMakerOrder(response.data)
        if (!result) {
          return Promise.reject('Unable to decode maker order')
        }
        return result
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup(e.message)
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [chain, addErrorPopup]
  )
}

export const useItemMakerBids = (
  collectionAddress: string,
  tokenId: string,
  chain: Chain
) =>
  usePaginatedEndpoint({
    convert: ResponseConverter.toSignedMakerOrder,
    params: {
      chain,
      collection: collectionAddress.toLowerCase(),
      includeCollectionBids: true,
      isOrderAsk: false,
      tokenId
    },
    queryKey: 'ItemMakerBidsQuery',
    url: `/v3/maker-orders`
  })

export const useRefreshMetadata = (): ((
  collection: string,
  tokenId: string,
  chain: Chain
) => Promise<void>) => {
  const addErrorPopup = useAddErrorPopup()
  return useCallback(
    async (collection: string, tokenId: string, chain: Chain) => {
      try {
        const response = await joebarn.post<void>(
          `/v3/items/refresh-metadata`,
          {
            chain,
            collection: collection.toLowerCase(),
            tokenId
          }
        )
        return response.data
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup(e.message)
        }
        console.error(e)
      }
    },
    [addErrorPopup]
  )
}

export const useSearch = (query: string) => {
  const [debouncedQuery] = useDebounce(query, 500)
  const addErrorPopup = useAddErrorPopup()
  return useQuery(
    ['SearchQuery', debouncedQuery],
    () =>
      joebarn.get(`/v3/search`, {
        params: {
          chain: ChainQueryParam.ALL,
          // limit the results to the top 10 hits
          pageSize: 10,
          query: debouncedQuery
        }
      }),
    {
      enabled: debouncedQuery.length >= SEARCH_QUERY_MIN_LENGTH,
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      },
      select: (data) => ResponseConverter.toSearchResults(data.data)
    }
  )
}

export const useFeaturedCollections = (
  chain: ChainQueryParam = ChainQueryParam.ALL
) =>
  usePaginatedEndpoint<{ chain: ChainQueryParam }, FeaturedCollection>({
    params: { chain },
    queryKey: 'FeaturedCollectionsQuery',
    url: `/v3/featured-collections`
  })

export const useCollectionLists = () =>
  usePaginatedEndpoint<Record<string, never>, CollectionList>({
    convert: ResponseConverter.toCollectionList,
    queryKey: 'CollectionListsQuery',
    url: `/v3/collection-lists`
  })

export const useLaunches = ({
  chain,
  pageSize
}: {
  chain: ChainQueryParam
  pageSize: number
}) => {
  return usePaginatedEndpoint({
    convert: ResponseConverter.toLaunch,
    pageSize,
    params: { chain },
    queryKey: 'LaunchesQuery',
    url: '/v3/launches'
  })
}

export const useLaunch = (
  slug: string,
  chain: Chain
): UseQueryResult<Launch> => {
  const addErrorPopup = useAddErrorPopup()
  return useQuery(
    ['LaunchQuery', slug, chain],
    () => joebarn.get(`/v3/launches/slug/${chain}/${slug}`),
    {
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      },
      select: (response) => ResponseConverter.toLaunch(response.data)
    }
  )
}

export const useGetValidMakerOrders = ({
  enabled = true,
  ids,
  onSuccess
}: {
  enabled: boolean
  ids: number[]
  onSuccess: (response: GetMakerOrderByIdsResponse) => void
}) => {
  const addErrorPopup = useAddErrorPopup()

  return useQuery(
    ['GetValidMakerOrders', ids],
    () =>
      joebarn.get<GetMakerOrderByIdsResponse>(`/v3/maker-orders/valid-orders`, {
        params: { ids },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'repeat' })
      }),
    {
      enabled: ids.length > 0 && enabled,
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      },
      onSuccess: (response) => onSuccess(response.data)
    }
  )
}

export const useAuthMessage = (): ((
  address: string
) => Promise<AuthMessageResponse>) => {
  const addErrorPopup = useAddErrorPopup()
  return useCallback(
    async (address: string): Promise<AuthMessageResponse> => {
      try {
        const response = await joebarn.post<AuthMessageResponse>(
          `/v1/auth/web3-message`,
          { address }
        )
        return response.data
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup(e.message)
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [addErrorPopup]
  )
}

export const useLogin = (): ((
  address: string,
  signature: string,
  message: string
) => Promise<AuthTokens>) => {
  const addErrorPopup = useAddErrorPopup()
  return useCallback(
    async (
      address: string,
      signature: string,
      message: string
    ): Promise<AuthTokens> => {
      try {
        const response = await joebarn.post<AuthTokens>(`/v1/auth/login`, {
          address,
          message,
          signature
        })
        return response.data
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup(e.message)
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [addErrorPopup]
  )
}

export const useRefreshToken = (): ((
  refreshToken: string
) => Promise<AuthTokens>) => {
  const addErrorPopup = useAddErrorPopup()
  return useCallback(
    async (refreshToken: string): Promise<AuthTokens> => {
      try {
        const response = await joebarn.get<AuthTokens>(
          `/v1/auth/refresh-token`,
          {
            headers: { Authorization: `Bearer ${refreshToken}` }
          }
        )
        return response.data
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup(e.message)
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [addErrorPopup]
  )
}

export const useEditProfile = (): ((
  address: string,
  authTokens: AuthTokens | undefined,
  args: EditProfileArgs
) => Promise<EditProfileResponse>) => {
  const addSuccessPopup = useAddSuccessPopup()
  const addErrorPopup = useAddErrorPopup()
  return useCallback(
    async (
      address: string,
      authTokens: AuthTokens | undefined,
      args: EditProfileArgs
    ): Promise<EditProfileResponse> => {
      if (!authTokens) {
        return Promise.reject('Unauthenticated')
      }
      try {
        const response = await joebarn.patch<EditProfileResponse>(
          `/v3/users/${address}`,
          args,
          { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
        )
        addSuccessPopup('Your profile was successfully updated!')
        return response.data
      } catch (e) {
        if (e instanceof AxiosError && e.response?.data.detail) {
          addErrorPopup(e.response?.data.detail)
        } else if (e instanceof Error) {
          addErrorPopup(e.message)
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [addSuccessPopup, addErrorPopup]
  )
}

export const useCollectionFloorPriceAnalytics = (
  address: string,
  chain: Chain,
  filter: AnalyticsFilterType
) => {
  const addErrorPopup = useAddErrorPopup()
  return useQuery(
    ['CollectionFloorPriceAnalytics', address, chain, filter],
    () =>
      joebarn.get(
        `/v3/analytics/graph/floor-price/${chain}/${address.toLowerCase()}`,
        {
          params: { filterBy: filter }
        }
      ),
    {
      keepPreviousData: true,
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      },
      select: (response) =>
        response.data.map((dataPoint: any) => ({
          ...dataPoint,
          floorPrice: +formattedNum({
            number: formatFromBalance(dataPoint.floorPrice),
            places: 2,
            usd: false
          }),
          volume: +formattedNum({
            number: formatFromBalance(dataPoint.volume),
            places: 2,
            usd: false
          })
        })) as AnalyticsDataPoint[]
    }
  )
}

export const useCollectionListingDepthAnalytics = (
  address: string,
  chain: Chain,
  priceIncrement: AnalyticsListingDepthsPriceIncrement
) => {
  const addErrorPopup = useAddErrorPopup()
  return useQuery(
    ['CollectionListingDepthAnalytics', address, chain, priceIncrement],
    () =>
      joebarn.get(
        `/v3/analytics/graph/list-price/${chain}/${address.toLowerCase()}`,
        {
          params: { priceIncrement }
        }
      ),
    {
      keepPreviousData: true,
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      },
      select: (response) =>
        response.data.map((dataPoint: any) => ({
          ...dataPoint,
          highPrice: +formatFromBalance(dataPoint.highPrice),
          lowPrice: +formatFromBalance(dataPoint.lowPrice),
          volume: +formatFromBalance(dataPoint.volume)
        })) as AnalyticsListingDepthDataPoint[]
    }
  )
}

interface UseGetMakerOrdersProps {
  chain?: Chain
  collection?: string
  enabled?: boolean
  isOrderAsk?: boolean
  orderBy?: MakerOrderSortType
  pageSize?: number
  signer?: string
  tokenId?: string
}

export const useGetMakerOrders = ({
  chain,
  collection,
  enabled = true,
  isOrderAsk,
  orderBy,
  pageSize,
  signer,
  tokenId
}: UseGetMakerOrdersProps) =>
  usePaginatedEndpoint<GetMakerOrdersParams, SignedMakerOrder>({
    convert: ResponseConverter.toSignedMakerOrder,
    enabled,
    pageSize,
    params: {
      chain,
      collection,
      isOrderAsk,
      orderBy,
      signer,
      tokenId
    },
    queryKey: 'GetMakerOrdersQuery',
    url: 'v3/maker-orders'
  })

export const useTrendingItemsOnAuction = () => {
  const addErrorPopup = useAddErrorPopup()
  return useQuery(
    ['TrendingItemsOnAuction'],
    () => joebarn.get('/v3/auctions/trending'),
    {
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      },
      select: (response) => response.data.map(ResponseConverter.toItemDetail)
    }
  )
}

export const useTrendingCollections = () => {
  const addErrorPopup = useAddErrorPopup()
  return useQuery(
    ['TrendingCollections'],
    () => joebarn.get<CollectionListEntry[]>('/v3/collections/trending'),
    {
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      },
      select: (response) => response.data
    }
  )
}

export const useFeaturedLaunch = (): Launch | undefined => {
  const addErrorPopup = useAddErrorPopup()
  const { data: launch } = useQuery(
    ['FeaturedLaunchQuery'],
    () =>
      joebarn.get('/v3/launches/featured', {
        params: { chain: ChainQueryParam.ALL }
      }),
    {
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      },
      select: (response) =>
        response.data ? ResponseConverter.toLaunch(response.data) : undefined
    }
  )
  return launch
}

export const useToggleHiddenBatch = (): ((
  authTokens: AuthTokens | undefined,
  args: ToggleHiddenBatchRequest
) => Promise<ToggleHiddenBatchResponse>) => {
  const addSuccessPopup = useAddSuccessPopup()
  const addErrorPopup = useAddErrorPopup()
  return useCallback(
    async (
      authTokens: AuthTokens | undefined,
      args: ToggleHiddenBatchRequest
    ): Promise<ToggleHiddenBatchResponse> => {
      if (!authTokens) {
        return Promise.reject('Unauthenticated')
      }
      try {
        const response = await joebarn.post<ToggleHiddenBatchResponse>(
          `/v3/items/hide-items`,
          args,
          {
            headers: { Authorization: `Bearer ${authTokens.accessToken}` }
          }
        )
        addSuccessPopup(
          response.data.isHidden
            ? 'Item hidden successfully'
            : 'Item unhidden successfully'
        )
        return response.data
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup(e.message)
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [addSuccessPopup, addErrorPopup]
  )
}

export const useCreateMintpegUploadUrl = (): ((
  contentType: string,
  draftImageType: MintpegDraftImageType
) => Promise<{
  destinationUrl: string
  fields: { [key: string]: string }
  presignedUploadUrl: string
}>) => {
  const { address: account } = useAccount()
  const { loginIfNeeded } = useLoginIfNeeded()
  const toggleWalletModal = useWalletModalToggle()
  const addErrorPopup = useAddErrorPopup()

  return useCallback(
    async (contentType: string, draftImageType: MintpegDraftImageType) => {
      if (!account) {
        toggleWalletModal()
        return { destinationUrl: '', fields: {}, presignedUploadUrl: '' }
      }

      const authTokens = await loginIfNeeded(account)
      if (!authTokens) {
        return Promise.reject('Unauthenticated')
      }
      try {
        const response = await joebarn.post<{
          destinationUrl: string
          fields: { [key: string]: string }
          presignedUploadUrl: string
        }>(
          '/v3/mints/drafts/upload/posturl',
          { contentType, draftImageType },
          { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
        )
        const { data } = response
        return {
          destinationUrl: `https://${data.destinationUrl}`,
          fields: data.fields,
          presignedUploadUrl: data.presignedUploadUrl
        }
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup(e.message)
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [account, toggleWalletModal, loginIfNeeded, addErrorPopup]
  )
}

export const useCreateDraftMintpegCollection = ({
  chainSlug
}: {
  chainSlug?: string
}) => {
  const { address: account } = useAccount()
  const { loginIfNeeded } = useLoginIfNeeded()
  const toggleWalletModal = useWalletModalToggle()
  const addErrorPopup = useAddErrorPopup()

  return useMutation({
    mutationFn: async ({ args }: { args: CreateDraftMintpegCollection }) => {
      if (!account) {
        toggleWalletModal()
        return Promise.reject('Unauthenticated')
      }

      const authTokens = await loginIfNeeded(account)
      if (!authTokens) return Promise.reject('Unauthenticated')

      const { data } = await joebarn.post<DraftMintpegCollection>(
        `/v3/mints/${chainSlug}/drafts`,
        args,
        { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
      )
      return data
    },
    onError: (error) => {
      if (error instanceof Error) {
        addErrorPopup(error.message)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DataKeyEntities.DraftMintpegsQuery]
      })
    }
  })
}

export const useUpdateDraftMintpegCollection = () => {
  const addSuccessPopup = useAddSuccessPopup()
  const { address: account } = useAccount()
  const { loginIfNeeded } = useLoginIfNeeded()
  const toggleWalletModal = useWalletModalToggle()
  const addErrorPopup = useAddErrorPopup()

  return useMutation({
    mutationFn: async ({
      args
    }: {
      args: {
        draftMintpegId: number
        updateState: UpdateDraftMintpegCollection
      }
    }) => {
      const { draftMintpegId, updateState } = args
      if (!account) {
        toggleWalletModal()
        return
      }

      const authTokens = await loginIfNeeded(account)
      if (!authTokens) return Promise.reject('Unauthenticated')

      const { data } = await joebarn.put<DraftMintpegCollection>(
        `/v3/mints/drafts/${draftMintpegId}`,
        updateState,
        { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
      )

      return data
    },
    onError: (error: unknown) => {
      if (error instanceof AxiosError && error.response?.data.detail) {
        addErrorPopup(error.response?.data.detail)
      } else if (error instanceof Error) {
        addErrorPopup(error.message)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DataKeyEntities.DraftMintpegsQuery]
      })
      addSuccessPopup('Draft successfully updated!')
    },
    retry: (_, error) => {
      if (error instanceof AxiosError && error.response?.data.detail) {
        if (error.response?.data.detail.includes('already exists')) {
          return false
        }
      }
      return true
    }
  })
}

export const useDeleteDraftMintpegCollection = () => {
  const addSuccessPopup = useAddSuccessPopup()
  const { address: account } = useAccount()
  const { loginIfNeeded } = useLoginIfNeeded()
  const toggleWalletModal = useWalletModalToggle()
  const addErrorPopup = useAddErrorPopup()

  return useMutation({
    mutationFn: async ({ draftMintpegId }: { draftMintpegId: number }) => {
      if (!account) {
        toggleWalletModal()
        return
      }

      const authTokens = await loginIfNeeded(account)
      if (!authTokens) return Promise.reject('Unauthenticated')

      const { data } = await joebarn.delete<{ status: true }>(
        `/v3/mints/drafts/${draftMintpegId}`,
        {
          headers: { Authorization: `Bearer ${authTokens.accessToken}` }
        }
      )
      addSuccessPopup('Draft successfully deleted!')
      return data
    },
    onError: (error) => {
      if (error instanceof Error) {
        addErrorPopup(error.message)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DataKeyEntities.DraftMintpegsQuery]
      })
    }
  })
}

export const useListDraftMintpegs = ({
  chainSlug,
  enabled = true,
  userRoleType
}: {
  userRoleType: UserRoleType
  chainSlug?: string
  enabled?: boolean
}) => {
  const tokens = useGetAuthTokens()
  return usePaginatedEndpoint<
    Record<string, UserRoleType>,
    PaginatedDraftMintpegCollection
  >({
    enabled,
    headers: tokens ? { Authorization: `Bearer ${tokens.accessToken}` } : {},
    pageSize: 20,
    params: {
      userRoleType
    },
    queryKey: DataKeyEntities.DraftMintpegsQuery,
    url: `/v3/mints/${chainSlug}/drafts`
  })
}

export const useListMintpegs = ({
  chainSlug,
  enabled = true,
  userRoleType
}: {
  userRoleType: UserRoleType
  chainSlug?: string
  enabled?: boolean
}) => {
  const tokens = useGetAuthTokens()
  return usePaginatedEndpoint<Record<string, UserRoleType>, MintpegCollection>({
    enabled,
    headers: tokens ? { Authorization: `Bearer ${tokens.accessToken}` } : {},
    pageSize: 20,
    params: {
      userRoleType
    },
    queryKey: DataKeyEntities.MintpegsQuery,
    url: `/v3/mints/${chainSlug}`
  })
}

export const useGetDraftMintpeg = ({
  draftMintpegId,
  onSuccess
}: {
  draftMintpegId: number | null
  onSuccess?: (data: DraftMintpegCollection) => void
}) => {
  const { address: account } = useAccount()
  const { loginIfNeeded } = useLoginIfNeeded()
  const toggleWalletModal = useWalletModalToggle()
  const addErrorPopup = useAddErrorPopup()

  return useQuery({
    enabled: !!account && !!draftMintpegId,
    onError: (error) => {
      if (error instanceof Error) {
        addErrorPopup(error.message)
      }
    },
    onSuccess,
    queryFn: async () => {
      if (!account) {
        toggleWalletModal()
        return Promise.reject('Need to be logged in.')
      }

      const authTokens = await loginIfNeeded(account)
      if (!authTokens) return Promise.reject('Unauthenticated')

      const { data } = await joebarn.get<DraftMintpegCollection>(
        `/v3/mints/drafts/${draftMintpegId}`,
        { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
      )

      return data
    },
    queryKey: [DataKeyEntities.DraftMintpegQuery, draftMintpegId]
  })
}

export const useMintpegIPFSUpload = (): ((
  uploads: MintpegNFTImageUpload[]
) => Promise<string[]>) => {
  const addErrorPopup = useAddErrorPopup()

  return async (uploads: MintpegNFTImageUpload[]) => {
    try {
      const response = await joebarn.post<string[]>('/v3/mints/upload', uploads)
      if (response.status !== 200) {
        throw Error(`Non-200 ${response.status} for draft mintpeg!`)
      }

      return response.data
    } catch (e) {
      if (e instanceof Error) {
        addErrorPopup(e.message)
      }
      console.error(e)
      return Promise.reject(e)
    }
  }
}

export const useGetItemRankings = (
  address: string,
  chain: Chain,
  pageNum: number,
  pageSize: number
) => {
  const addErrorPopup = useAddErrorPopup()

  return useQuery(
    ['ItemRankings', address, chain, pageNum, pageSize],
    () =>
      joebarn.get(
        `/v3/items/${chain}/${address.toLowerCase()}/longest-held-items`,
        {
          params: {
            pageNum,
            pageSize
          }
        }
      ),
    {
      keepPreviousData: true,
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      },
      select: (response) =>
        response.data.map((item: any) => ({
          collection: item.item.collection,
          collectionName: item.item.collectionName,
          id: item.item.id,
          metadata: item.item.metadata,
          tokenId: item.item.tokenId,
          transferredAt: new Date(item.transferredAt)
        })) as ItemInfoAndTransferTime[]
    }
  )
}

export const useGetRankings = (
  address: string,
  filterType: CategoryType,
  chain: Chain
) => {
  const addErrorPopup = useAddErrorPopup()
  return useQuery(
    ['Rankings', address, filterType, chain],
    () =>
      joebarn.get(
        `/v3/collections/${chain}/${address.toLowerCase()}/top-${filterType}`
      ),
    {
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      },
      select: (response) =>
        response.data.map((addressAndItem: any) => ({
          numItems: addressAndItem.numItems,
          user: addressAndItem.user
        })) as AddressAndNumItems[]
    }
  )
}

export const useCollectionAnalytics = (address: string, chain: Chain) => {
  const addErrorPopup = useAddErrorPopup()
  return useQuery(
    ['CollectionAnalytics', address, chain],
    () =>
      joebarn.get<CollectionAnalytics>(
        `/v3/collections/${chain}/${address.toLowerCase()}/collection-analytics`,
        { params: { chain } }
      ),
    {
      onError: (error) => {
        if (error instanceof Error) {
          addErrorPopup(error.message)
        }
      },
      select: (response) => response.data
    }
  )
}

export const useMintpegPublish = () => {
  const { address: account } = useAccount()
  const { loginIfNeeded } = useLoginIfNeeded()
  const toggleWalletModal = useWalletModalToggle()
  const addErrorPopup = useAddErrorPopup()

  return useMutation({
    mutationFn: async ({
      args
    }: {
      args: {
        draftMintpegId: number
        publishState: PublishDraftMintpegCollection
      }
    }) => {
      const { draftMintpegId, publishState } = args
      if (!account) {
        toggleWalletModal()
        return Promise.reject('Need to be logged in.')
      }

      const authTokens = await loginIfNeeded(account)
      if (!authTokens) return Promise.reject('Unauthenticated')

      const { data } = await joebarn.post<boolean>(
        `/v3/mints/drafts/publish/${draftMintpegId}`,
        publishState,
        { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
      )

      return data
    },
    onError: (error) => {
      if (error instanceof Error) {
        addErrorPopup(error.message)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DataKeyEntities.MintpegsQuery],
        refetchPage: (page, index) => index === 0
      })
      queryClient.invalidateQueries({
        queryKey: [DataKeyEntities.DraftMintpegsQuery],
        refetchPage: (page, index) => index === 0
      })
    }
  })
}

export const useMintpegUpdate = ({
  chainSlug,
  setNothingActive
}: {
  setNothingActive: () => void
  chainSlug?: string
}) => {
  const addSuccessPopup = useAddSuccessPopup()
  const { address: account } = useAccount()
  const { loginIfNeeded } = useLoginIfNeeded()
  const toggleWalletModal = useWalletModalToggle()
  const addErrorPopup = useAddErrorPopup()

  return useMutation({
    mutationFn: async ({
      args
    }: {
      args: { mintpegId: string; update: UpdateMintpegCollection }
    }) => {
      const { mintpegId, update } = args
      if (!account) {
        toggleWalletModal()
        return Promise.reject('Need to be logged in.')
      }

      const authTokens = await loginIfNeeded(account)
      if (!authTokens) return Promise.reject('Unauthenticated')

      const response = await joebarn.post<boolean>(
        `/v3/mints/${chainSlug}/update/${mintpegId}`,
        update,
        {
          headers: { Authorization: `Bearer ${authTokens.accessToken}` }
        }
      )

      return response.data
    },
    onError: (error: unknown) => {
      if (error instanceof AxiosError && error.response?.data.detail) {
        addErrorPopup(error.response?.data.detail)
      } else if (error instanceof Error) {
        addErrorPopup(error.message)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DataKeyEntities.MintpegsQuery]
      })
      addSuccessPopup('Successfully updated collection!')
      setNothingActive()
    },
    retry: (_, error) => {
      if (error instanceof AxiosError && error.response?.data.detail) {
        if (error.response?.data.detail.includes('already exists')) {
          return false
        }
      }
      return true
    }
  })
}

export const useCheckOfferIsValid = ({
  isOpen,
  makerOrderId
}: {
  isOpen: boolean
  makerOrderId?: number
}) => {
  const addErrorPopup = useAddErrorPopup()
  return useQuery({
    enabled: !!makerOrderId && isOpen,
    onError: (error) => {
      if (error instanceof Error) {
        addErrorPopup(error.message)
      }
    },
    queryFn: () => joebarn.get(`/v3/maker-orders/offer-valid/${makerOrderId}`),
    queryKey: ['checkOfferIsValid', makerOrderId],
    select: (response) => response.data
  })
}

export const useDeleteOffer = (makerOrderId?: number) => {
  const addErrorPopup = useAddErrorPopup()
  const deleteOffer = async () => {
    const { data } = await joebarn.put(
      `/v3/maker-orders/invalidate/${makerOrderId}`
    )
    return data
  }

  return useMutation({
    mutationFn: deleteOffer,
    onError: (error) => {
      if (error instanceof Error) {
        addErrorPopup(error.message)
      }
    }
  })
}

export const useValidateDraftCollectionId = (
  draftMintpegCollectionId: number | null | undefined
) => {
  const addErrorPopup = useAddErrorPopup()
  return useQuery({
    enabled: !!draftMintpegCollectionId,
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.data.detail) {
        addErrorPopup(error.response?.data.detail)
      } else if (error instanceof Error) {
        addErrorPopup(error.message)
      }
    },
    queryFn: () =>
      joebarn.get(
        `/v3/mints/draft-collection-valid/${draftMintpegCollectionId}`
      ),
    queryKey: ['draftMintpegCollectionId', draftMintpegCollectionId],
    retry: (_, error) => {
      if (error instanceof AxiosError && error.response?.data.detail) {
        if (error.response?.data.detail.includes('already exists')) {
          return false
        }
      }
      return true
    },
    select: (response) => response.data
  })
}

export const useGetTraitsAnalytics = ({
  chain,
  collectionId
}: {
  chain: Chain
  collectionId: string
}) => {
  const addErrorPopup = useAddErrorPopup()
  return useQuery({
    onError: (error) => {
      if (error instanceof Error) {
        addErrorPopup(error.message)
      }
    },
    queryFn: () => joebarn.get(`/v3/trait-analytics/${chain}/${collectionId}`),
    queryKey: ['ownerTraits', chain, collectionId],
    select: (response) => response.data
  })
}

export const useGetRecentActivities = ({
  activityTypes,
  chain
}: {
  activityTypes: ActivityType[]
  chain: ChainQueryParam
}) => {
  const addErrorPopup = useAddErrorPopup()

  let fromTimestamp: number
  if (activityTypes.length === 1 && activityTypes[0] === ActivityType.SALE) {
    fromTimestamp = twelveHourAgo()
  } else if (
    activityTypes.length === 1 &&
    activityTypes[0] === ActivityType.COLLECTION_BID
  ) {
    fromTimestamp = twelveHourAgo()
  } else {
    fromTimestamp = twoHourAgo()
  }

  const toTimestamp = currentTimestamp()
  const windowSize = toTimestamp - fromTimestamp

  const result = useInfiniteQuery({
    cacheTime: 0,
    getNextPageParam: (_, allPages) => {
      const lastPage: CollectionActivity[] = allPages[allPages.length - 1] || []

      if (lastPage.length === 0) return

      const lastTimestamp = Math.min(
        ...lastPage.map((activity) => activity.timestamp)
      )

      return {
        fromTimestamp: lastTimestamp - windowSize,
        toTimestamp: lastTimestamp
      }
    },
    onError: (error) => {
      if (error instanceof Error) {
        addErrorPopup(error.message)
      }
    },
    queryFn: async ({
      pageParam
    }: {
      pageParam?: { fromTimestamp: number; toTimestamp: number }
    }) => {
      const fromTs = pageParam?.fromTimestamp || fromTimestamp
      const toTs = pageParam?.toTimestamp || toTimestamp

      const response = await joebarn.get('/v3/activities/recent', {
        params: {
          activity: activityTypes,
          chain,
          fromTs,
          toTs
        },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
      })

      return response.data
    },
    queryKey: ['recentActivities', chain, activityTypes]
  })

  const transformedData =
    (result.data?.pages
      .flatMap((page) => page)
      .map((activity) => activity)
      .filter(Boolean) as CollectionActivity[]) ?? []

  return {
    ...result,
    data: transformedData
  }
}

export const useGetOrderNonces = ({
  chain,
  enabled,
  userAddress
}: {
  chain: Chain
  enabled: boolean
  userAddress?: string
}) => {
  const addErrorPopup = useAddErrorPopup()
  return useQuery({
    enabled: !!userAddress && enabled,
    onError: (error) => {
      if (error instanceof Error) {
        addErrorPopup(error.message)
      }
    },
    queryFn: () =>
      joebarn.get<{ askNonces: number[]; bidNonces: number[] }>(
        `/v3/maker-orders/${userAddress}/order-ids`,
        { params: { chain } }
      ),
    queryKey: ['orderNonces', userAddress, chain],
    select: (response) => response.data
  })
}

export const useUpdateCollection = ({
  chain,
  collectionAddress
}: {
  chain: Chain
  collectionAddress: string
}) => {
  const queryClient = useQueryClient()
  const addSuccessPopup = useAddSuccessPopup()

  return useMutation({
    mutationFn: async ({
      args,
      authTokens
    }: {
      args: UpdateCollection
      authTokens?: AuthTokens
    }) => {
      if (!authTokens) {
        return Promise.reject('Unauthenticated')
      }

      const { data } = await joebarn.patch<UpdateCollection>(
        `/v3/collections/${chain}/${collectionAddress?.toLowerCase()}`,
        args,
        { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['CollectionQuery', collectionAddress?.toLowerCase(), chain]
      })
      addSuccessPopup('Page successfully updated!')
    }
  })
}

export const useGetCollectionAdmins = ({
  chain,
  collectionAddress
}: {
  chain: Chain
  collectionAddress: string
}) => {
  const addErrorPopup = useAddErrorPopup()

  return useQuery<string[]>({
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.data.detail) {
        console.log('error: ', error)
        addErrorPopup(error.response?.data.detail?.[0]?.msg)
      } else if (error instanceof Error) {
        addErrorPopup(error.message)
      }
    },

    queryFn: async () => {
      const { data } = await joebarn.get(
        `/v3/collections/${chain}/${collectionAddress}/collection-admins`
      )

      return data?.map((admin: string) => admin?.toLowerCase())
    },
    queryKey: ['CollectionAdmins', collectionAddress, chain]
  })
}
