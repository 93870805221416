import { ChainId } from '@traderjoe-xyz/joepegs-sdk'
import { supportedChains } from 'constants/chains'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAddPopup } from 'state/application/hooks'
import { TransactionReceipt } from 'viem'
import { useAccount } from 'wagmi'

import { AppDispatch, AppState } from '../index'
import { addTransaction, finalizeTransaction } from './actions'
import { TransactionDetails } from './reducer'

// helper that can take a transaction response and add it to the list of transactions
export const useTransactionAdder = (): ((
  hash: string,
  chainId: ChainId,
  customData?: {
    approval?: { spender: string; tokenAddress: string }
    claim?: { recipient: string }
    summary?: string
  }
) => void) => {
  const { address: account } = useAccount()

  const dispatch = useDispatch<AppDispatch>()
  const addPopup = useAddPopup()

  return useCallback(
    (
      hash: string,
      chainId: ChainId,
      {
        approval,
        claim,
        summary
      }: {
        approval?: { spender: string; tokenAddress: string }
        claim?: { recipient: string }
        summary?: string
      } = {}
    ) => {
      if (!account) return

      if (!hash) {
        throw Error('No transaction hash found.')
      }
      dispatch(
        addTransaction({
          approval,
          chainId,
          claim,
          from: account,
          hash,
          summary
        })
      )
      addPopup(
        {
          chainId,
          hash,
          state: 'loading',
          summary
        },
        hash
      )
    },
    [dispatch, addPopup, account]
  )
}

// updates pending transaction with its receipt
export const useFinalizeTransaction = () => {
  const addPopup = useAddPopup()
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (summary: string, receipt: TransactionReceipt, chainId: ChainId) => {
      dispatch(
        finalizeTransaction({
          hash: receipt.transactionHash,
          receipt: {
            blockHash: receipt.blockHash,
            blockNumber: Number(receipt.blockNumber),
            contractAddress: receipt.contractAddress,
            from: receipt.from,
            status: receipt.status,
            to: receipt.to,
            transactionHash: receipt.transactionHash,
            transactionIndex: receipt.transactionIndex
          }
        })
      )
      const state = receipt
        ? receipt.status === 'success'
          ? 'success'
          : 'error'
        : 'loading'
      addPopup(
        {
          chainId,
          hash: receipt.transactionHash,
          state,
          summary
        },
        receipt.transactionHash
      )
    },
    [dispatch, addPopup]
  )
}

// returns all the transactions for the current chain
export const useAllTransactions = (): {
  [txHash: string]: TransactionDetails
} => {
  const state = useSelector<AppState, AppState['transactions']>(
    (state) => state.transactions
  )
  return supportedChains
    .map((chain) => state[chain.id])
    .reduce((all, current) => ({ ...all, ...current }), {})
}

/**
 * Returns whether a transaction happened in the last day (86400 seconds * 1000 milliseconds / second)
 * @param tx to check for recency
 */
export const isTransactionRecent = (tx: TransactionDetails): boolean =>
  new Date().getTime() - tx.addedTime < 86_400_000
